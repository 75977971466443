import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { fromError } from '~utils/errors';
import {
  UsePowerSchedules_DisplayFragment,
  UsePowerSchedules_PowerScheduleFragment,
  useSyncPowerScheduleMutation,
} from './__generated__/usePowerSchedules.graphql';

export type PowerScheduleUpdateFailedReason = 'unexpected_error';

/** Represents the state of a displays power schedule in a more convenient way for the UI.  */
export type PowerScheduleState =
  | {
      kind: 'empty';
    }
  | { kind: 'power_schedule_ready'; powerSchedule: UsePowerSchedules_PowerScheduleFragment }
  | {
      kind: 'power_schedule_sync_planned';
      powerSchedule?: UsePowerSchedules_PowerScheduleFragment;
      jobId: string;
    }
  | {
      kind: 'power_schedule_syncing';
      powerSchedule?: UsePowerSchedules_PowerScheduleFragment;
      jobId: string;
      progress?: number;
    }
  | {
      kind: 'power_schedule_sync_failed';
      powerSchedule?: UsePowerSchedules_PowerScheduleFragment;
      reason: PowerScheduleUpdateFailedReason;
    }
  | { kind: 'power_schedule_out_of_sync'; powerSchedule: UsePowerSchedules_PowerScheduleFragment };

export function usePowerSchedules() {
  const getPowerScheduleState = useCallback(
    (display: UsePowerSchedules_DisplayFragment): PowerScheduleState => {
      if (display.powerSchedule?.latestJob?.__typename === 'PowerScheduleUpdateInProgress') {
        return {
          kind: 'power_schedule_syncing',
          powerSchedule: display.powerSchedule.schedule ?? undefined,
          jobId: display.powerSchedule.latestJob.id,
        };
      }

      if (display.powerSchedule?.latestJob?.__typename === 'PowerScheduleUpdateRejected') {
        return {
          kind: 'power_schedule_sync_failed',
          powerSchedule: display.powerSchedule.schedule ?? undefined,
          reason: getPowerScheduleSyncFailedReason(display.powerSchedule.latestJob.rejectionCode),
        };
      }

      if (display.powerSchedule?.schedule && !display.powerSchedule.isSynced) {
        return {
          kind: 'power_schedule_out_of_sync',
          powerSchedule: display.powerSchedule.schedule,
        };
      }

      if (
        display.powerSchedule?.schedule &&
        display.powerSchedule.latestJob?.__typename === 'PowerScheduleUpdateCompleted'
      ) {
        return { kind: 'power_schedule_ready', powerSchedule: display.powerSchedule.schedule };
      }

      return { kind: 'empty' };
    },
    [],
  );

  const toast = useToast();
  const [syncPowerScheduleMutation] = useSyncPowerScheduleMutation();
  const syncPowerSchedule = useCallback(
    async (display: UsePowerSchedules_DisplayFragment) => {
      const powerScheduleId =
        display.powerSchedule?.latestJob?.scheduleId ?? display.powerSchedule?.schedule?.id;

      if (!powerScheduleId) {
        return;
      }

      try {
        await syncPowerScheduleMutation({
          variables: {
            input: {
              displayIds: [display.id],
              powerScheduleId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('syncPowerScheduleErr'),
          description: fromError(err, 'SyncPowerSchedule', {
            INSUFFICIENT_STORAGE: t('syncPowerScheduleErrDesc'),
          }),
        });
      }
    },
    [syncPowerScheduleMutation, toast],
  );

  return useMemo(
    () => ({
      getPowerScheduleState,
      syncPowerSchedule,
    }),
    [getPowerScheduleState, syncPowerSchedule],
  );
}

function getPowerScheduleSyncFailedReason(rejectionCode: string): PowerScheduleUpdateFailedReason {
  switch (rejectionCode) {
    default:
      return 'unexpected_error';
  }
}

usePowerSchedules.graphql = {
  fragments: {
    UsePowerSchedules_powerSchedule: gql`
      fragment UsePowerSchedules_powerSchedule on PowerSchedule {
        id
        title
      }
    `,
    UsePowerSchedules_display: gql`
      fragment UsePowerSchedules_display on Display {
        id
        powerSchedule {
          schedule {
            id
            ...UsePowerSchedules_powerSchedule
          }
          isSynced
          latestJob {
            __typename
            id
            scheduleId
            ... on PowerScheduleUpdateRejected {
              rejectionCode
            }
          }
        }
      }
    `,
  },
  mutations: {
    SyncPowerSchedule: gql`
      mutation SyncPowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
        displayBulkUpdatePowerSchedule(input: $input) {
          displays {
            id
            ...UsePowerSchedules_display
          }
        }
      }
    `,
  },
};
