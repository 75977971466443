import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { VolumeHighIcon, VolumeLowIcon, VolumeMuteIcon } from '~components/ui/icons';
import { PercentageInput } from '~components/ui/PercentageInput';
import { Slider } from '~components/ui/Slider';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useBulkUpdateVolumeMutation } from './__generated__/BulkUpdateVolume.graphql';
import { BulkActionComponent } from './BulkActionModal';

const schema = z.object({
  displayIds: z.array(z.string()),
  level: z.number(),
  min: z.number(),
  max: z.number(),
  mute: z.boolean(),
});

type FormValues = z.TypeOf<typeof schema>;

export const BulkUpdateVolume: BulkActionComponent = ({
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const analytics = useAnalyticsReporter();
  const isMuteUnsupported = useMemo(
    () =>
      displays.some(({ volume }) => _.isNil(volume?.isMuted?.desired ?? volume?.isMuted?.reported)),
    [displays],
  );
  const { t } = useTranslation();
  const isLevelUnsupported = useMemo(
    () => displays.some(({ volume }) => _.isNil(volume?.level?.desired ?? volume?.level?.reported)),
    [displays],
  );

  const isMinUnSupported = useMemo(
    () =>
      displays.some(({ volume }) =>
        _.isNil(volume?.limits?.min?.desired ?? volume?.limits?.min?.reported),
      ),
    [displays],
  );
  const isMaxUnSupported = useMemo(
    () =>
      displays.some(({ volume }) =>
        _.isNil(volume?.limits?.max?.desired ?? volume?.limits?.max?.reported),
      ),
    [displays],
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      level: 50,
      min: 0,
      max: 100,
      mute: false,
      displayIds: displays.map((d) => d.id),
    },
    resolver: zodResolver(schema),
  });

  const toast = useToast();
  const [bulkUpdateVolume] = useBulkUpdateVolumeMutation();

  const displayIds = useMemo(() => displays.map((d) => d.id), [displays]);

  const onSubmit = useCallback(
    async ({ level, max, min, mute }: FormValues) => {
      try {
        await bulkUpdateVolume({
          variables: {
            input: {
              min,
              max,
              level,
              mute,
              displayIds,
            },
          },
        });

        if (displayIds.length === 1) {
          analytics.track('displayVolumeUpdate');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'updateVolume',
            displayCount: displayIds.length,
          });
        }

        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: t('bulkUpdateVolumeErr'),
          description: fromError(err, 'bulkUpdateVolume'),
        });
      }
    },
    [analytics, bulkUpdateVolume, displayIds, onSuccess, toast, t],
  );

  const min = watch('min');
  const max = watch('max');

  return (
    <>
      <ModalContent>
        <ModalHeader>{t('audioSettings')}</ModalHeader>
        <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={Boolean(errors.max) || Boolean(errors.min)} marginBottom="8">
              <FormLabel>{t('setVolumeLimits')}</FormLabel>
              <Stack direction="column">
                <Stack direction="row">
                  {isMinUnSupported && isMaxUnSupported && (
                    <Box marginBottom="4">
                      <chakra.span whiteSpace="nowrap" color="gray.500" fontSize="sm">
                        {t('setVolumeLimitsAlert')}
                      </chakra.span>
                    </Box>
                  )}
                  {isMinUnSupported && !isMaxUnSupported && (
                    <Box marginBottom="4">
                      <chakra.span whiteSpace="nowrap" color="gray.500" fontSize="sm">
                        {t('volumeMinLimitAlert')}
                      </chakra.span>
                    </Box>
                  )}
                  {!isMinUnSupported && isMaxUnSupported && (
                    <Box marginBottom="4">
                      <chakra.span whiteSpace="nowrap" color="gray.500" fontSize="sm">
                        {t('volumeMaxLimitAlert')}
                      </chakra.span>
                    </Box>
                  )}
                </Stack>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  width={{ base: 'full', md: '70%' }}
                  spacing="8"
                >
                  <FormControl>
                    <FormLabel>{t('min')}</FormLabel>
                    <Controller
                      control={control}
                      name="min"
                      render={({ field }) => (
                        <PercentageInput
                          value={field.value}
                          onChange={(_, valueAsNumber) => {
                            field.onChange(Number.isNaN(valueAsNumber) ? 0 : valueAsNumber);
                          }}
                          onBlur={field.onBlur}
                          max={max}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('max')}</FormLabel>
                    <Controller
                      control={control}
                      name="max"
                      render={({ field }) => (
                        <PercentageInput
                          value={field.value}
                          onChange={(_, valueAsNumber) => {
                            field.onChange(Number.isNaN(valueAsNumber) ? 0 : valueAsNumber);
                          }}
                          onBlur={field.onBlur}
                          min={min}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
                <ErrorMessage
                  errors={errors}
                  name="limits"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </Stack>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.mute) || Boolean(errors.level)}>
              <FormLabel>{t('volume')}</FormLabel>
              {isMuteUnsupported && (
                <Box marginBottom="6">
                  <chakra.span whiteSpace="nowrap" color="gray.500" fontSize="sm">
                    {t('muteUnmuteAlert')}
                  </chakra.span>
                </Box>
              )}
              <Box display="flex">
                <Box flex="60%">
                  <Controller
                    control={control}
                    name="level"
                    render={({ field }) => (
                      <Slider
                        value={field.value}
                        onChange={field.onChange}
                        rightIcon={
                          field.value === 0
                            ? VolumeMuteIcon
                            : field.value <= 50
                            ? VolumeLowIcon
                            : VolumeHighIcon
                        }
                        isDisabled={isLevelUnsupported}
                        min={min}
                        max={max}
                      />
                    )}
                  />
                </Box>
                <Box flex="40%" display="flex" justifyContent="flex-end">
                  <Controller
                    name="mute"
                    control={control}
                    render={({ field }) => (
                      <Button
                        size="sm"
                        variant="link"
                        colorScheme="blue"
                        onClick={() => {
                          field.onChange(!field.value);
                        }}
                      >
                        {field.value ? 'unmute' : 'mute'}
                      </Button>
                    )}
                  />
                </Box>
              </Box>

              <ErrorMessage
                errors={errors}
                name="levelAndMute"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Stack flex="1" direction="row" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={onCancel}
                  isDisabled={isSubmitting}
                >
                  {t('cancel')}
                </Button>
                <Button
                  marginLeft="3"
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {t('apply')}
                </Button>
              </Box>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};
BulkUpdateVolume.graphql = {
  fragments: {
    BulkUpdateVolume_display: gql`
      fragment BulkUpdateVolume_display on Display {
        id
        volume {
          isMuted {
            reported
            desired
          }
          level {
            reported
            desired
          }
          limits {
            min {
              reported
              desired
            }
            max {
              reported
              desired
            }
          }
        }
      }
    `,
    mutations: {
      BulkUpdateVolume: gql`
        mutation BulkUpdateVolume($input: DisplayBulkUpdateVolumeInput!) {
          displayBulkUpdateVolume(input: $input) {
            displays {
              id
              ...BulkUpdateVolume_display
            }
          }
        }
      `,
    },
  },
};
