import { gql } from '@apollo/client';
import {
  AlertDescription,
  Box,
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormLabel,
  GridItem,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { cloneDeep, merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTimeoutWhen } from 'rooks';
import { useAuth } from '~auth/useAuth';
import { InfoAlert } from '~components/ui/Alert';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { Link } from '~components/ui/Link';
import { ReleaseChannel } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { FeatureFlag } from '~utils/features';
import { ensure, isDefined } from '~utils/types';
import {
  DangerZoneSection_DisplayFragment,
  UpdateAgentReleaseChannelMutation,
  useClearDataMutation,
  useUnclaimDisplayMutation,
  useUpdateAgentReleaseChannelMutation,
} from './__generated__/DangerZoneSection.graphql';

interface Props {
  display: DangerZoneSection_DisplayFragment;
}

const CLEAR_SENSITIVE_DATA_TIMEOUT_IN_MS = 60000;

export function DangerZoneSection({ display }: Props) {
  const { verifyUserPermissions } = useAuth();

  return (
    <GridItem colSpan={{ base: 8, md: 7 }}>
      <Stack direction="column" spacing="10">
        {verifyUserPermissions([Permission.DisplayFirmwareUpdate]) && (
          <FeatureFlag feature="agentReleaseChannel">
            <EnrollInAlphaChannel display={display} />
          </FeatureFlag>
        )}
        {verifyUserPermissions([Permission.DisplaySettingsUpdate]) && (
          <ClearDataAction display={display} />
        )}
        {verifyUserPermissions([Permission.DisplayUnclaim]) && <UnclaimAction display={display} />}
      </Stack>
    </GridItem>
  );
}

function EnrollInAlphaChannel({ display }: Props) {
  const toast = useToast();
  const analytics = useAnalyticsReporter();
  const [updateAgentReleaseChannel] = useUpdateAgentReleaseChannelMutation();
  const { t } = useTranslation();

  const updateAgentReleaseChannelAction = useDestructiveAction({
    title: t('updateAgentReleaseChannel'),
    confirmLabel: t('confirm'),
    message: (
      <Trans
        i18nKey="enrollDisplayConfirmation"
        values={{ display: display.alias ?? display.serialNumber }}
        components={{ strong: <strong /> }}
      />
    ),
    onConfirm: async () => {
      const alphaChannel = ReleaseChannel.Alpha;
      const optimisticResponse: UpdateAgentReleaseChannelMutation = {
        __typename: 'Mutation',
        displayBulkUpdateAgentReleaseChannel: {
          __typename: 'DisplayBulkUpdateAgentReleaseChannelPayload',
          displays: [
            merge(cloneDeep(display), {
              agentReleaseChannel: {
                desired: alphaChannel,
              },
            }),
          ],
        },
      };
      try {
        await updateAgentReleaseChannel({
          variables: {
            input: {
              displayIds: [display.id],
              releaseChannel: alphaChannel,
            },
          },
          optimisticResponse,
        });

        analytics.track('displayAgentReleaseChannelUpdateComplete');
      } catch (err) {
        toast({
          status: 'error',
          title: t('enrollDisplayErr'),
          description: fromError(err, 'EnrolDisplay'),
        });
      }
    },
  });

  const handleUpdateAgentReleaseChannel = useCallback(() => {
    analytics.track('displayAgentReleaseChannelUpdateStart');
    updateAgentReleaseChannelAction.askConfirmation(undefined);
  }, [analytics, updateAgentReleaseChannelAction]);

  if (!display.agentReleaseChannel) {
    return <></>;
  }

  const isEnrolled =
    display.agentReleaseChannel?.reported === ReleaseChannel.Alpha ||
    display.agentReleaseChannel?.desired === ReleaseChannel.Alpha;

  return (
    <>
      <FormControl>
        <FormLabel>
          {t('alphaChannelUpdates')}
          <FormLabelPendingIndicator
            variant="danger"
            isPending={isPropertyPending(display.agentReleaseChannel)}
            label={t('enrollingDisplay')}
          />
        </FormLabel>

        <Text color="gray.500" mb="2">
          {t('enrollingDisplayAlert')}
        </Text>

        <Checkbox
          checked={isEnrolled}
          onChange={handleUpdateAgentReleaseChannel}
          isDisabled={isEnrolled}
          defaultChecked={isEnrolled}
        >
          {isPropertyPending(display.agentReleaseChannel)
            ? 'Enrolling…'
            : isEnrolled
            ? t('alphaChannelUpdatesEnabled')
            : t('enableAlphaChannelUpdates')}
        </Checkbox>
        {updateAgentReleaseChannelAction.confirmationNode}
      </FormControl>
    </>
  );
}

function ClearDataAction({ display }: Props) {
  const toast = useToast();
  const [isPending, setPending] = useState<boolean>(false);
  const [clearData] = useClearDataMutation();
  const { t } = useTranslation();

  useTimeoutWhen(
    () => {
      setPending(false);

      if (!display.hasSensitiveData) {
        return;
      }

      toast({
        status: 'error',
        title: t('clearSensitiveDataErr'),
        description: t('tryAgainErr'),
      });
    },
    CLEAR_SENSITIVE_DATA_TIMEOUT_IN_MS,
    isPending,
  );
  const analytics = useAnalyticsReporter();

  useEffect(() => {
    if (isPending && !display.hasSensitiveData) {
      setPending(false);
    }
  }, [display, isPending, setPending]);

  const clearSensitiveDataAction = useDestructiveAction({
    title: t('clearSensitiveData'),
    confirmLabel: t('confirm'),
    message: (
      <>
        {t('clearSensitiveDataAlert')}
        <chakra.span fontWeight="semibold">{display.alias ?? display.serialNumber}</chakra.span>?
      </>
    ),
    onConfirm: async () => {
      try {
        setPending(true);

        await clearData({
          variables: {
            input: {
              displayIds: [display.id],
            },
          },
        });

        analytics.track('displayClearDataComplete');
      } catch (err) {
        setPending(false);
        toast({
          status: 'error',
          title: t('clearDisplayDataErr'),
          description: fromError(err, 'ClearData', {
            JOB_ONGOING: t('clearDisplayDataErrDesc'),
          }),
        });
      }
    },
  });

  const handleClearSensitiveData = useCallback(() => {
    analytics.track('displayClearDataStart');
    clearSensitiveDataAction.askConfirmation(undefined);
  }, [analytics, clearSensitiveDataAction]);

  return (
    <Box>
      <FormLabel>
        {t('clearDisplayContent')}
        <FormLabelPendingIndicator
          variant="danger"
          isPending={isPending}
          label={t('clearDisplayContentLabel')}
        />
      </FormLabel>

      <Text color="gray.500" mb="4">
        {t('clearAllDataAlert')}
      </Text>

      {display.hasSensitiveData ? (
        <Button
          size="sm"
          variant="solid"
          colorScheme="red"
          boxShadow="unset"
          onClick={handleClearSensitiveData}
        >
          {t('clearDisplayContent')}
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline"
          colorScheme="red"
          boxShadow="unset"
          onClick={handleClearSensitiveData}
          isDisabled={true}
        >
          {t('noDataFound')}
        </Button>
      )}

      {clearSensitiveDataAction.confirmationNode}
    </Box>
  );
}

function UnclaimAction({ display }: Props) {
  const auth = useAuth();
  const { customer, displayId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [unclaimDisplay] = useUnclaimDisplayMutation();
  const analytics = useAnalyticsReporter();

  // Displays with appInstallations cannot be unclaimed
  const hasAppInstallations = display.appSubscriptions.some(({ appInstallation }) =>
    isDefined(appInstallation),
  );
  const { t } = useTranslation();

  const unclaimDisplayAction = useDestructiveAction({
    title: t('unclaimDisplay'),
    confirmLabel: t('unclaim'),
    message: (
      <>
        {t('confirmUnclaimDisplay')}
        <chakra.span fontWeight="semibold">{display.alias ?? display.serialNumber}</chakra.span>?
      </>
    ),
    onConfirm: async () => {
      try {
        await unclaimDisplay({
          variables: {
            input: {
              displayId: ensure(displayId),
            },
          },
          update: (cache) => {
            cache.evict({
              id: cache.identify({
                __typename: 'Display',
                id: displayId,
              }),
            });
          },
        });

        toast({
          status: 'info',
          title: t('displayUnclaimed'),
          description: t('unclaimedDisplayMessage', {
            display: display.alias ?? display.serialNumber,
          }),
        });

        analytics.track('displayUnclaimComplete');
        navigate(`/${auth.organization?.handle}/customers/${customer}`, { replace: true });
      } catch (err) {
        toast({
          status: 'error',
          title: t('unclaimDisplayErr'),
          description: fromError(err, 'UnclaimDisplay'),
        });
      }
    },
  });

  const handleUnclaim = useCallback(() => {
    analytics.track('displayUnclaimStart');
    unclaimDisplayAction.askConfirmation(undefined);
  }, [analytics, unclaimDisplayAction]);

  return (
    <Box>
      <FormLabel>{t('unclaimDisplayLabel')}</FormLabel>

      <Text color="gray.500" mb="4">
        {t('unclaimDisplayAlert')}
      </Text>

      {hasAppInstallations && (
        <InfoAlert marginBottom={4}>
          <AlertDescription display="flex" flexDirection="column">
            {t('unclaimDisplayWarn')}
            <Link to="../summary" textDecoration="underline">
              {t('goToDisplaySummary')}
            </Link>
          </AlertDescription>
        </InfoAlert>
      )}

      <Button
        size="sm"
        variant="solid"
        colorScheme="red"
        onClick={handleUnclaim}
        boxShadow="unset"
        isDisabled={hasAppInstallations}
      >
        {t('unclaimDisplayLabel')}
      </Button>

      {unclaimDisplayAction.confirmationNode}
    </Box>
  );
}

DangerZoneSection.graphql = {
  fragments: {
    DangerZoneSection_display: gql`
      fragment DangerZoneSection_display on Display {
        id
        alias
        serialNumber
        hasSensitiveData
        agentReleaseChannel {
          desired
          reported
        }
        appSubscriptions {
          appInstallation {
            id
          }
        }
      }
    `,
  },
  mutations: {
    clearData: gql`
      mutation ClearData($input: DisplayBulkClearDataInput!) {
        displayBulkClearData(input: $input) {
          displays {
            id
            hasSensitiveData
          }
        }
      }
    `,
    UnclaimDisplay: gql`
      mutation UnclaimDisplay($input: DisplayUnclaimInput!) {
        displayUnclaim(input: $input) {
          customer {
            id
          }
        }
      }
    `,
    updateAgentReleaseChannel: gql`
      mutation UpdateAgentReleaseChannel($input: DisplayBulkUpdateAgentReleaseChannelInput!) {
        displayBulkUpdateAgentReleaseChannel(input: $input) {
          displays {
            id
          }
        }
      }
    `,
  },
};
