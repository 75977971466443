import { gql } from '@apollo/client';
import { Box, Button, Stack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield } from '~auth/Shield';
import { EmptyCustomers } from '~components/customers/EmptyCustomers';
import { CustomersTable } from '~components/organization/CustomersTable';
import { useCustomersTable } from '~components/organization/CustomersTable/useCustomersTable';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { useCustomersPageQuery } from './__generated__/Index.graphql';

const POLL_INTERVAL = 10000;

export function OrganizationCustomersIndexPage() {
  const { data, loading, error, startPolling, stopPolling } = useCustomersPageQuery({
    pollInterval: POLL_INTERVAL,
  });
  const navigate = useNavigate();

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const customers = useMemo(
    () => data?.organization.customers ?? [],
    [data?.organization.customers],
  );

  const table = useCustomersTable(customers);

  const handleCreate = () => {
    navigate('./create', { state: { canGoBack: true } });
  };

  return (
    <VerticalTabContent title={t('customers')} isLoading={loading} error={error}>
      {isNil(customers) || isEmpty(customers) ? (
        <EmptyCustomers handleCreate={handleCreate} />
      ) : (
        <>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing="8" mb="4">
            <CustomersTable.Filter table={table} />
            <Shield requiredPermissions={[Permission.CustomerCreate]}>
              <Box textAlign="right">
                <Button variant="solid" colorScheme="blue" onClick={handleCreate}>
                  {t('addNewCustomer')}
                </Button>
              </Box>
            </Shield>
          </Stack>
          <Box
            boxShadow="elevated"
            border="1px solid"
            borderRadius="md"
            borderColor="gray.100"
            overflowX="auto"
            bgColor="white"
          >
            <CustomersTable table={table} />
          </Box>
          <Box display="flex" marginTop="4">
            <Box flex="1">
              <CustomersTable.PageSizeSelector table={table} />
            </Box>
            <CustomersTable.Pagination table={table} />
          </Box>
        </>
      )}
    </VerticalTabContent>
  );
}

OrganizationCustomersIndexPage.graphql = {
  queries: {
    CustomersPage: gql`
      query CustomersPage {
        organization {
          id
          customers {
            ...CustomersTableCustomer
          }
        }
      }
    `,
  },
};
