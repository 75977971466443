import { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon } from '~components/ui/icons';

export default function BackToAllActionsButton({
  onBack,
  ...rest
}: ButtonProps & { onBack: (() => void | Promise<void>) | undefined }) {
  const { t } = useTranslation();
  return (
    <Button
      size="sm"
      variant="link"
      colorScheme="blue"
      color="blue.800"
      leftIcon={<ArrowLeftIcon />}
      onClick={onBack}
      {...rest}
    >
      {t('backToAllActions')}
    </Button>
  );
}
