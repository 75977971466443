import { t } from 'i18next';
import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function MasterServicesAgreementPage() {
  return (
    <PolicyPage title={t('masterServicesAgreement')} heading={t('masterServicesAgreementHeading')}>
      <div dangerouslySetInnerHTML={{ __html: t('MSA') }} />
    </PolicyPage>
  );
}
