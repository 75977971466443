/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type SelfProfileUpdateMutationVariables = Types.Exact<{
  input: Types.SelfProfileUpdateInput;
}>;


export type SelfProfileUpdateMutation = { __typename: 'Mutation', selfProfileUpdate: { __typename: 'SelfProfileUpdatePayload', user: { __typename: 'User', locale?: string | null } } };


export const SelfProfileUpdateDocument = gql`
    mutation SelfProfileUpdate($input: SelfProfileUpdateInput!) {
  selfProfileUpdate(input: $input) {
    user {
      locale
    }
  }
}
    `;
export type SelfProfileUpdateMutationFn = ApolloReactCommon.MutationFunction<SelfProfileUpdateMutation, SelfProfileUpdateMutationVariables>;

/**
 * __useSelfProfileUpdateMutation__
 *
 * To run a mutation, you first call `useSelfProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfProfileUpdateMutation, { data, loading, error }] = useSelfProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfProfileUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelfProfileUpdateMutation, SelfProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SelfProfileUpdateMutation, SelfProfileUpdateMutationVariables>(SelfProfileUpdateDocument, options);
      }
export type SelfProfileUpdateMutationHookResult = ReturnType<typeof useSelfProfileUpdateMutation>;
export type SelfProfileUpdateMutationResult = ApolloReactCommon.MutationResult<SelfProfileUpdateMutation>;
export type SelfProfileUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SelfProfileUpdateMutation, SelfProfileUpdateMutationVariables>;