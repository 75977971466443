import { gql } from '@apollo/client';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { CreateSiteModal } from '~components/customers/CreateSiteModal';
import SitesActionTable from '~components/customers/SitesActionTable';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { EmptySiteIllustration } from '~components/ui/illustrations/EmptySiteIllustration';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { HandleApiError } from '~graphql/HandleApiError';
import { useAnalyticsReporter } from '~utils/analytics';
import { ensure } from '~utils/types';
import { useCustomerSitesPageQuery } from './__generated__/Sites.graphql';

export function CustomerSitesPage() {
  const { customer } = useParams();
  const createSiteModal = useDisclosure();
  const { verifyUserPermissions } = useAuth();

  const { data, loading, error } = useCustomerSitesPageQuery({
    variables: {
      customerHandle: ensure(customer),
    },
  });
  const analytics = useAnalyticsReporter();

  const handleCreate = useCallback(() => {
    analytics.track('siteCreateStart');
    createSiteModal.onOpen();
  }, [analytics, createSiteModal]);

  const handleCreateSuccess = useCallback(() => {
    analytics.track('siteCreateComplete');
    createSiteModal.onClose();
  }, [analytics, createSiteModal]);

  const hasSiteCreationPermission = verifyUserPermissions([Permission.SiteCreate]);

  return (
    <Page title="Sites" pageName="sites">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data &&
          data.customer && (
            <>
              <PageHeading
                actions={
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={handleCreate}
                    isDisabled={!hasSiteCreationPermission}
                  >
                    {t('addSite')}
                  </Button>
                }
              >
                {t('sites')}
              </PageHeading>
              <Box
                boxShadow="elevated"
                border="1px solid"
                borderRadius="md"
                borderColor="gray.100"
                bgColor="white"
              >
                {data.customer.sites.length === 0 ? (
                  <EmptyView
                    icon={<EmptySiteIllustration />}
                    title={t('createFirstSite')}
                    description={`${t('siteUsageInfo')}${
                      !hasSiteCreationPermission ? ` ${t('permissionNote')}` : ''
                    }`}
                  >
                    <EmptyViewButton
                      onClick={handleCreate}
                      label={t('addSite')}
                      isDisabled={!hasSiteCreationPermission}
                    />
                  </EmptyView>
                ) : (
                  <SitesActionTable sites={data.customer.sites} />
                )}
              </Box>
              <CreateSiteModal
                customerId={data.customer.id}
                isOpen={createSiteModal.isOpen}
                onCancel={createSiteModal.onClose}
                onSuccess={handleCreateSuccess}
              />
            </>
          )
        )}
      </PageContent>
    </Page>
  );
}

CustomerSitesPage.graphql = {
  queries: {
    CustomerSitesPage: gql`
      query CustomerSitesPage($customerHandle: String!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          sites {
            id
            name
            address
          }
        }
      }
    `,
  },
};
