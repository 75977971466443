import { useTranslation } from 'react-i18next';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { EmptyCustomerIllustration } from '~components/ui/illustrations/EmptyCustomerIllustration';

export function EmptyCustomers({ handleCreate }: { handleCreate: () => void }) {
  const { t } = useTranslation();
  return (
    <EmptyView
      icon={<EmptyCustomerIllustration marginBottom={'-40px'} />}
      title={t('createFirstCustomer')}
      description={t('createFirstCustomerDesc')}
    >
      <EmptyViewButton onClick={handleCreate} label={t('addCustomer')} />
    </EmptyView>
  );
}
