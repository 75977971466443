import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { DateTime, Interval } from 'luxon';
import { useAuth } from '~auth/useAuth';
import { RelativeTime } from '~components/ui/RelativeTime';
import { AlertsMenu_AlertFragment } from './__generated__/AlertsMenu.graphql';

type Props = {
  alert: AlertsMenu_AlertFragment;
  onGoToDisplay: (alert: AlertsMenu_AlertFragment) => void;
  onMarkAlert: (alert: AlertsMenu_AlertFragment) => Promise<void>;
};

export function AlertsItem({ alert, onGoToDisplay, onMarkAlert }: Props) {
  const isNew = isNewAlert(alert);

  const { verifyUserPermissions } = useAuth();
  const hasAlertResolvePermission = verifyUserPermissions([Permission.AlertResolve]);

  return (
    <Box
      role="group"
      p="2.5"
      borderRadius="md"
      backgroundColor={isNew ? '#F1F8FE' : undefined}
      display="flex"
      flexDir="row"
      w="full"
      justifyContent="space-between"
    >
      <Box flex="1">
        <Text color="#0A3761">{alert.message}</Text>
        <RelativeTime
          ts={alert.createdAt}
          render={(t) => (
            <Text fontSize="sm" color="gray.300">
              {t}
            </Text>
          )}
        />
      </Box>

      <HStack minWidth="230px" justifyContent="flex-end" alignItems="flex-end">
        <Button variant="ghost" color="#4B9EEC" size="sm" onClick={() => onGoToDisplay(alert)}>
          {t('goToDisplay')}
        </Button>

        <Button
          variant="ghost"
          color="#4B9EEC"
          size="sm"
          onClick={() => onMarkAlert(alert)}
          isDisabled={!hasAlertResolvePermission}
        >
          {t('markAsDone')}
        </Button>
      </HStack>
    </Box>
  );
}

function isNewAlert(alert: AlertsMenu_AlertFragment) {
  const interval = Interval.fromDateTimes(DateTime.fromISO(alert.createdAt), DateTime.now());
  const minutesSince = Math.round(interval.length('minutes'));
  return minutesSince < 5;
}
