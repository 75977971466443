import { gql } from '@apollo/client';
import { FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { cloneDeep, isNil, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { toAvailableControlToggleOptions } from '~components/displays/utils';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { Orientation } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { DisplayControlsToggle, ToggleOptionsConfig } from '../DisplayControlsToggle';
import {
  DisplayOrientationToggle_DisplayFragment,
  useUpdateOrientationMutation,
} from './__generated__/DisplayOrientationToggle.graphql';

interface Props {
  display: DisplayOrientationToggle_DisplayFragment;
}

export function DisplayOrientationToggle({ display }: Props) {
  const toast = useToast();
  const [updateOrientation, { loading }] = useUpdateOrientationMutation();

  const { t } = useTranslation();
  const orientationToggleOptions: Array<ToggleOptionsConfig<Orientation>> = [
    { value: Orientation.Landscape, label: t('landscape') },
    { value: Orientation.Portrait, label: t('portrait') },
  ];
  const handleChange = async (orientation: Orientation) => {
    try {
      await updateOrientation({
        variables: {
          input: {
            id: display.id,
            orientation,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayUpdateOrientation: {
            __typename: 'DisplayUpdateOrientationPayload',
            display: {
              __typename: 'Display',
              id: display.id,
              orientation: merge(cloneDeep(display.orientation), { desired: orientation }),
            },
          },
        },
      });
    } catch (error) {
      toast({
        status: 'error',
        title: t('cannotUpdateOrientation'),
        description: fromError(error, 'UpdateOrientation'),
      });
    }
  };

  const currentControlState = display.orientation?.desired ?? display.orientation?.reported;

  const controlOptions = toAvailableControlToggleOptions(
    orientationToggleOptions,
    display.orientation?.supportedValues,
  );

  const isPending = isPropertyPending(display.orientation);

  const { verifyUserPermissions } = useAuth();

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  if (isNil(currentControlState)) {
    return null;
  }

  return (
    <FormControl>
      <FormLabel>
        {t('screenOrientation')}
        <FormLabelPendingIndicator isPending={isPending} />
      </FormLabel>
      <Text color="gray.500" marginBottom="3">
        {t('screenOrientationText')}
      </Text>
      <DisplayControlsToggle
        name="orientationControlState"
        value={currentControlState}
        handleChange={handleChange}
        isLoading={loading}
        options={controlOptions}
        isDisabled={isDisplaySettingsUpdateDisabled}
      />
    </FormControl>
  );
}

DisplayOrientationToggle.graphql = {
  fragments: {
    DisplayOrientationToggle_display: gql`
      fragment DisplayOrientationToggle_display on Display {
        id
        orientation {
          reported
          desired
          supportedValues
        }
      }
    `,
  },
  mutations: {
    UpdateOrientation: gql`
      mutation UpdateOrientation($input: DisplayUpdateOrientationInput!) {
        displayUpdateOrientation(input: $input) {
          display {
            id
            orientation {
              reported
              desired
              supportedValues
            }
          }
        }
      }
    `,
  },
};
