import { createIcon } from '@chakra-ui/react';

export const ShieldIcon = createIcon({
  displayName: 'ShieldIcon',
  viewBox: '0 0 22 22',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M15.75 8.25L10.5562 14.25L8.25 11.9906"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7078 5.26734C17.5162 4.51547 15.7832 3.95859 12 2.25C8.21668 3.95859 6.48371 4.51547 2.29215 5.26734C1.53277 17.303 11.2771 21.4589 12 21.75C12.7228 21.4589 22.4671 17.303 21.7078 5.26734Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
