import {
  AlertDescription,
  AlertTitle,
  Button,
  chakra,
  Link,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { WarningAlert } from '~components/ui/Alert';
import { ExternalLinkIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useFeatureFlag } from '~utils/features';

interface Props {
  onCancel: () => void;
}

export function StepSubscription({ onCancel }: Props) {
  const navigate = useNavigate();
  const { verifyUserPermissions, organization } = useAuth();
  const { isEnabled: isAppDirectMarketplaceLinkEnabled } = useFeatureFlag(
    'appDirectMarketplaceLink',
  );
  const { t } = useTranslation();
  const canManageSubscriptions = verifyUserPermissions([Permission.WaveSubscriptionUpdate]);

  const goToSubscriptionsSettings = () =>
    navigate(`/${organization?.handle}/settings/subscriptions`);

  return (
    <>
      <ModalHeader>{t('addMoreSeats')}</ModalHeader>
      <ModalCloseButton onClick={onCancel} />
      <ModalBody>
        <WarningAlert
          actionButton={
            canManageSubscriptions && isAppDirectMarketplaceLinkEnabled ? (
              <Link
                href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
                target="_blank"
                rel="noopener noreferrer"
                marginLeft="8"
                textDecoration="none"
              >
                <Button variant="outline" size="sm" colorScheme="orange" color="orange.700">
                  <Stack direction="row">
                    <chakra.span textDecoration="none">{t('visitProStore')}</chakra.span>
                    <ExternalLinkIcon />
                  </Stack>
                </Button>
              </Link>
            ) : undefined
          }
        >
          <AlertTitle>{t('seatsNeededToClaim')}</AlertTitle>
          <AlertDescription>
            {canManageSubscriptions ? t('buyMoreSeatAlert') : t('askAdminForMoreSeatAlert')}
          </AlertDescription>
        </WarningAlert>
      </ModalBody>
      <ModalFooter>
        <Stack flex="1" direction="row" spacing="3" justifyContent="flex-end">
          <Button variant="ghost" colorScheme="blue" onClick={onCancel}>
            {t('cancel')}
          </Button>
          {canManageSubscriptions ? (
            <Button variant="solid" colorScheme="blue" onClick={goToSubscriptionsSettings}>
              {t('manageSubscriptions')}
            </Button>
          ) : (
            <Button variant="solid" colorScheme="blue" isDisabled>
              {t('next')}
            </Button>
          )}
        </Stack>
      </ModalFooter>
    </>
  );
}
