import { gql, useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  Collapse,
  FormControl,
  IconButton,
  Link as ChakraLink,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { ArrowLeftIcon, HelpIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { isDefined, MaybePromise } from '~utils/types';
import {
  GetDisplayByCodeDocument,
  GetDisplayByCodeQuery,
  GetDisplayByCodeQueryVariables,
  StepClaim_Claimable_DisplayFragment,
  StepClaim_CustomerFragment,
  StepClaim_DisplayFragment,
  useClaimDisplayMutation,
} from './__generated__/StepClaim.graphql';
import { DisplayClaimCodeInput } from './DisplayClaimCodeInput';
import { DisplayPreview } from './DisplayPreview';

const DISPLAY_CODE_LENGTH = 6;

interface Props {
  customer: StepClaim_CustomerFragment;
  onCancel: () => void;
  onSuccess: (display: StepClaim_DisplayFragment) => MaybePromise<void>;
  onBack: () => void;
  hasLiteSubscription: boolean;
}

export function StepClaim({ customer, onCancel, onSuccess, onBack }: Props) {
  const schema = useMemo(
    () =>
      z.object({
        customerId: z.string(),
        displayCode: z
          .string()
          .refine((val) => val.length >= DISPLAY_CODE_LENGTH, 'Code is required'),
        // We have to add the key before hand.
        // zodResolver will only let keys defined in this schema ever reach submit.
        // we'll type it as any(), the type is typed from GraphQL.
        display: z.any(),
      }),
    [],
  );
  type StepOneFormValues = z.TypeOf<typeof schema> & {
    display?: StepClaim_Claimable_DisplayFragment;
  };

  const [claimDisplay] = useClaimDisplayMutation();
  const { getDisplayByCode } = useGetDisplayByCode();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isDirty },
    reset,
    watch,
  } = useForm<StepOneFormValues>({
    defaultValues: { customerId: customer.id },
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const displayCodeInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const displayCodeWatch = watch('displayCode');
  const displayWatch = watch('display');

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const handleApiError = useCallback(
    (err: unknown) => {
      setError('displayCode', {
        type: 'api',
        message: fromError(err, 'GetDisplayByCode', {
          DISPLAY_ALREADY_ADDED: t('displayAlreadyClaimed'),
          DISPLAY_NOT_FOUND: t('displayNotFound'),
        }),
      });
    },
    [setError, t],
  );

  const [isIdentifying, setIsIdentifying] = useState(false);
  const identifyDisplay = useCallback(
    async (displayCode: StepOneFormValues['displayCode']) => {
      clearErrors();
      try {
        setIsIdentifying(true);
        const display = await getDisplayByCode(displayCode);
        if (!display) return;
        setValue('display', display);
      } catch (err) {
        handleApiError(err);
      } finally {
        setIsIdentifying(false);
      }
    },
    [clearErrors, getDisplayByCode, setValue, handleApiError],
  );

  const performSubmit = useCallback(
    async ({ displayCode, customerId, display }: StepOneFormValues) => {
      if (!display) throw new Error('Unexpected: No display found');

      try {
        const { data } = await claimDisplay({
          variables: {
            input: {
              customerId,
              displayId: display.id,
              displayCode,
            },
          },
        });

        if (!data?.displayClaim.display) {
          throw new Error('Unexpected: no display claimed');
        }

        await onSuccess(data.displayClaim.display);
      } catch (err) {
        handleApiError(err);
      }
    },
    [claimDisplay, onSuccess, handleApiError],
  );

  useEffect(() => {
    if (displayCodeWatch?.length === DISPLAY_CODE_LENGTH) {
      identifyDisplay(displayCodeWatch);
    }
  }, [displayCodeWatch, identifyDisplay]);

  useEffect(() => {
    if (displayCodeWatch?.length < DISPLAY_CODE_LENGTH) {
      setValue('display', undefined);
    }
  }, [displayCodeWatch, setValue]);

  useEffect(() => {
    if (displayCodeInputRef.current) {
      displayCodeInputRef.current.focus();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <ModalHeader>
        <IconButton
          aria-label="Go Back"
          icon={<ArrowLeftIcon />}
          onClick={onBack}
          variant="ghost"
        />
        {t('claimDisplay')}
      </ModalHeader>
      <ModalCloseButton onClick={onCancel} />
      <ModalBody>
        <Stack spacing="6">
          <Box>
            <Text color="gray.600" fontSize="sm">
              {t('claimDisplayDesc')}
            </Text>
            <FormControl marginTop="10">
              <Controller
                name="displayCode"
                control={control}
                render={({ field }) => (
                  <DisplayClaimCodeInput
                    ref={displayCodeInputRef}
                    length={DISPLAY_CODE_LENGTH}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder=""
                    isLoading={isIdentifying}
                    isComplete={isDefined(displayWatch)}
                    error={errors.displayCode?.message}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Collapse in={isDefined(displayWatch)} animateOpacity>
            {displayWatch && (
              <Box>
                <Text marginTop="1" fontSize="lg" fontWeight="semibold" color="gray.800">
                  {t('displayIdentified')}
                </Text>
                <Text marginTop="1" fontSize="sm" fontWeight="normal" color="gray.600">
                  {t('reviewCurrentSettings')}
                </Text>
                <Box marginTop="4">
                  <DisplayPreview display={displayWatch} />
                </Box>
              </Box>
            )}
          </Collapse>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack flex="1" direction="row" spacing="3" justifyContent="flex-end">
          <Box flex="1" display="flex" alignItems="center">
            <ChakraLink
              href={'https://docs.wave.ppds.com/guide/getting-started'}
              isExternal
              target={'_blank'}
              rel={'noopener noreferrer'}
              color="blue.500"
              textDecorationColor="blue.500"
              display="flex"
              alignItems="center"
              borderRadius="base"
              cursor="pointer"
            >
              <Box>
                <HelpIcon color="blue.500" marginY="2" />
              </Box>
              <Box marginLeft="2">
                <chakra.span color="blue.500" fontWeight="semibold" fontSize="xs">
                  {t('whatDisplaysSupported')}
                </chakra.span>
              </Box>
            </ChakraLink>
          </Box>
          <Button
            variant="ghost"
            colorScheme="blue"
            onClick={handleClose}
            isDisabled={isSubmitting}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            isDisabled={!isDirty || isSubmitting || Object.keys(errors).length > 0}
            isLoading={isSubmitting}
          >
            {t('claim')}
          </Button>
        </Stack>
      </ModalFooter>
    </form>
  );
}

/**
 * Custom hook to retrieve a display by code.
 *
 * Not using useLazyQuery from Apollo react
 * because it behaves really buggy when the same query is fired multiple times.
 */
function useGetDisplayByCode() {
  const client = useApolloClient();
  const getDisplayByCode = useCallback(
    async (code: string) => {
      const response = await client.query<GetDisplayByCodeQuery, GetDisplayByCodeQueryVariables>({
        query: GetDisplayByCodeDocument,
        fetchPolicy: 'network-only',
        variables: {
          displayCode: code,
        },
      });

      return response.data.displayByCode;
    },
    [client],
  );

  return { getDisplayByCode };
}

StepClaim.graphql = {
  fragments: {
    StepClaim_customer: gql`
      fragment StepClaim_customer on Customer {
        id
      }
    `,
    StepClaim_display: gql`
      fragment StepClaim_display on Display {
        id
        serialNumber
        commercialTypeNumber
        ...UseRecommendedSettings_display
        ...StepMetadata_display
        platform {
          type
        }
      }
    `,
    StepClaim_claimable_display: gql`
      fragment StepClaim_claimable_display on ClaimableDisplay {
        id
        ...DisplayPreview_claimable_display
      }
    `,
  },
  queries: {
    getDisplayByCode: gql`
      query GetDisplayByCode($displayCode: String!) {
        displayByCode(displayCode: $displayCode) {
          id
          ...StepClaim_claimable_display
        }
      }
    `,
  },
  mutations: {
    ClaimDisplay: gql`
      mutation ClaimDisplay($input: DisplayClaimInput!) {
        displayClaim(input: $input) {
          customer {
            id
          }
          display {
            id
            ...StepClaim_display
          }
        }
      }
    `,
  },
};
