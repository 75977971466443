import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLang from './locales/en-GB.json';
import frLang from './locales/fr.json';

const resources = {
  'en-GB': {
    translation: enLang,
  },
  fr: {
    translation: frLang,
  },
};
i18n.use(initReactI18next).init({
  resources,
  supportedLngs: ['en-GB', 'fr'],
  lng: 'en-GB',
  fallbackLng: 'en-GB',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
