import { gql } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, SelectOption } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { useBulkEditSiteMutation } from './__generated__/BulkEditSite.graphql';
import { BulkActionComponent } from './BulkActionModal';

type FormData = {
  displayIds: string[];
  site?: SelectOption;
};

export const BulkEditSite: BulkActionComponent = ({
  customer,
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const availableSites = customer.sites.map<SelectOption>((s) => ({
    label: s.name,
    value: s.id,
  }));
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      site: undefined,
      displayIds: displays.map((d) => d.id),
    },
  });

  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const [bulkUpdateSite] = useBulkEditSiteMutation();
  const onSubmit = async ({ displayIds, site }: FormData) => {
    try {
      await bulkUpdateSite({
        variables: {
          input: {
            displayIds,
            siteId: site ? site.value : null,
          },
        },
      });

      if (displayIds.length === 1) {
        analytics.track('siteUpdateComplete');
      } else {
        analytics.track('displayBulkActionComplete', {
          action: 'editSite',
          displayCount: displayIds.length,
        });
      }

      await onSuccess();
    } catch (err) {
      toast({
        status: 'error',
        title: t('bulkSiteEditError'),
        description: fromError(err, 'bulkEditSite'),
      });
    }
  };

  return (
    <ModalContent>
      <ModalHeader>{t('editSite')}</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl marginBottom="6">
            <FormLabel>{t('setNewSite')}</FormLabel>
            <Controller
              name="site"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    value={field.value}
                    options={availableSites}
                    components={components}
                    onChange={(value) => field.onChange(value)}
                    placeholder={t('assignSiteOrLeaveToUnassign')}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    isMulti={false}
                    escapeClearsValue={true}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement="auto"
                  />
                );
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack flex="1" direction="row" alignItems="center">
            <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
            <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={onCancel}
                isDisabled={isSubmitting}
              >
                {t('cancel')}
              </Button>
              <Button
                marginLeft="3"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {t('apply')}
              </Button>
            </Box>
          </Stack>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

BulkEditSite.graphql = {
  fragments: {
    BulkEditSite_customer: gql`
      fragment BulkEditSite_customer on Customer {
        id
        sites {
          id
          name
          address
        }
      }
    `,
  },
  mutations: {
    BulkEditSite: gql`
      mutation BulkEditSite($input: DisplayBulkUpdateSiteInput!) {
        displayBulkUpdateSite(input: $input) {
          displays {
            id
          }
        }
      }
    `,
  },
};
