import { t } from 'i18next';
import { get, isNil } from 'lodash';
import { logger } from './logger';

export function fromError(err: unknown, operation: string, errorMap: Record<string, string> = {}) {
  const message = getErrorMessage(err, errorMap);

  if (isNil(message)) {
    logger.fatal(err, { operation });
    return t('defaultErrMsg');
  }

  return message;
}

function isGenericError(err: unknown): err is Error {
  return !isNil(get(err, 'message'));
}

function getErrorMessage(err: unknown, errorMap: Record<string, string>) {
  if (!isGenericError(err)) {
    return null;
  }

  return errorMap[err.message];
}
