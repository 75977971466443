import { Text } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';

export function ForbiddenPage() {
  return (
    <Page title="403" pageName="403_forbidden" analyticsDisableTracking={true}>
      <PageContent maxWidth="300px" textAlign="center">
        <Text fontWeight="semibold" color="gray.600">
          {t('unauthorised')}
        </Text>

        <Text marginTop="8" fontSize="sm">
          {t('contactSupportErr')}
        </Text>
      </PageContent>
    </Page>
  );
}
