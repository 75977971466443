import { gql } from '@apollo/client';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { MutableRefObject, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  BulkUpdateBookmarksAllMutation,
  useBulkUpdateBookmarksAllMutation,
} from '~components/displays/__generated__/useManageWebPagesForm.graphql';
import { Display } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';

// Taken from Yup since Zod doesn't do this regex based.
// https://github.com/jquense/yup/blob/master/src/string.ts#L23
const URL_REGEX =
  // eslint-disable-next-line
  /^((https?):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export type UpdateBookmarkFormValues = z.infer<typeof schema>;

const bookmarkSchema = z
  .string()
  .superRefine((val, ctx) => {
    if (!isEmpty(val) && !URL_REGEX.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid url.',
      });
    }
  })
  .or(z.string().refine((s) => s === ''));

const schema = z.object({
  bookmark1: bookmarkSchema,
  bookmark2: bookmarkSchema,
  bookmark3: bookmarkSchema,
  bookmark4: bookmarkSchema,
  bookmark5: bookmarkSchema,
  bookmark6: bookmarkSchema,
  bookmark7: bookmarkSchema,
});

type Bookmarks = {
  bookmark1: string;
  bookmark2: string;
  bookmark3: string;
  bookmark4: string;
  bookmark5: string;
  bookmark6: string;
  bookmark7: string;
};

export function useManageWebPagesForm({
  displayIds,
  onSuccess,
  onCancel,
  initialFocusRef,
  defaultBookmarks,
}: {
  displayIds: Array<Display['id']>;
  defaultBookmarks: Array<string | undefined | null>;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onSuccess: () => void;
  onCancel: () => void;
}) {
  const BOOKMARKS = [
    'bookmark1',
    'bookmark2',
    'bookmark3',
    'bookmark4',
    'bookmark5',
    'bookmark6',
    'bookmark7',
  ] as const;

  const initialState = {
    bookmark1: defaultBookmarks[0] ?? '',
    bookmark2: defaultBookmarks[1] ?? '',
    bookmark3: defaultBookmarks[2] ?? '',
    bookmark4: defaultBookmarks[3] ?? '',
    bookmark5: defaultBookmarks[4] ?? '',
    bookmark6: defaultBookmarks[5] ?? '',
    bookmark7: defaultBookmarks[6] ?? '',
  };

  const onSubmit = useSaveWebPages({ initialState, displayIds, onSuccess });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialState,
    resolver: zodResolver(schema),
  });

  const body = (
    <VStack spacing="3">
      {BOOKMARKS.map((bookmark, i) => {
        const { ref, ...bookmarkProps } = register(bookmark);

        return (
          <FormControl isInvalid={Boolean(errors[bookmark])} key={bookmark}>
            <FormLabel marginBottom="1">
              {t('webPage')} {i + 1}
            </FormLabel>
            <Input
              placeholder="https://webpage.com"
              {...bookmarkProps}
              ref={
                i !== 0
                  ? ref
                  : (r) => {
                      ref(r);
                      if (initialFocusRef) initialFocusRef.current = r;
                    }
              }
            />
            <FormErrorMessage>{errors[bookmark]?.message}</FormErrorMessage>
          </FormControl>
        );
      })}
    </VStack>
  );

  const footer = (
    <>
      <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
        {t('cancel')}
      </Button>
      <Button
        variant="solid"
        colorScheme="blue"
        marginLeft="3"
        type="submit"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      >
        {t('apply')}
      </Button>
    </>
  );

  return {
    onSubmit: handleSubmit(onSubmit),
    body,
    footer,
  };
}

export function useSaveWebPages({
  initialState,
  displayIds,
  onSuccess,
}: {
  initialState?: Bookmarks | undefined;
  displayIds: Array<Display['id']>;
  onSuccess?: () => void;
}) {
  const toast = useToast();
  const analytics = useAnalyticsReporter();
  const [bulkUpdateBookmarksAll] = useBulkUpdateBookmarksAllMutation();

  const canApplyChanges = (
    bookmark1: string,
    bookmark2: string,
    bookmark3: string,
    bookmark4: string,
    bookmark5: string,
    bookmark6: string,
    bookmark7: string,
    initialState: Bookmarks | undefined,
  ) => {
    return (
      bookmark1 !== initialState?.bookmark1 ||
      bookmark2 !== initialState?.bookmark2 ||
      bookmark3 !== initialState?.bookmark3 ||
      bookmark4 !== initialState?.bookmark4 ||
      bookmark5 !== initialState?.bookmark5 ||
      bookmark6 !== initialState?.bookmark6 ||
      bookmark7 !== initialState?.bookmark7
    );
  };

  const checkData = (data: BulkUpdateBookmarksAllMutation | null | undefined) => {
    if (!data || !data.displayBulkUpdateBookmarksAll.displays) {
      throw new Error('Update web pages failed');
    }
  };

  return useCallback(
    async ({
      bookmark1,
      bookmark2,
      bookmark3,
      bookmark4,
      bookmark5,
      bookmark6,
      bookmark7,
    }: UpdateBookmarkFormValues) => {
      if (
        !canApplyChanges(
          bookmark1,
          bookmark2,
          bookmark3,
          bookmark4,
          bookmark5,
          bookmark6,
          bookmark7,
          initialState,
        )
      ) {
        return onSuccess?.();
      }
      try {
        const { data } = await bulkUpdateBookmarksAll({
          variables: {
            input: {
              displayIds,
              bookmarks: [
                bookmark1,
                bookmark2,
                bookmark3,
                bookmark4,
                bookmark5,
                bookmark6,
                bookmark7,
              ],
            },
          },
        });

        checkData(data);

        if (displayIds.length === 1) {
          analytics.track('displaySettingUpdate', {
            group: 'playback',
            changeItem: 'bookmarks',
          });
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'updateWebPages',
            displayCount: displayIds.length,
          });
        }

        onSuccess?.();
      } catch (err) {
        toast({
          status: 'error',
          title: t('updateWebPagesErr'),
          description: fromError(err, 'UpdateBookmarks'),
        });
      }
    },
    [analytics, bulkUpdateBookmarksAll, displayIds, initialState, onSuccess, toast],
  );
}

useSaveWebPages.graphql = {
  mutations: {
    BulkUpdateBookmarksAll: gql`
      mutation BulkUpdateBookmarksAll($input: DisplayBulkUpdateBookmarksAllInput!) {
        displayBulkUpdateBookmarksAll(input: $input) {
          displays {
            id
            bookmarks {
              all {
                reported
                desired
              }
            }
          }
        }
      }
    `,
  },
};
