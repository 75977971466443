import {
  Box,
  Button,
  chakra,
  Collapse,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { t } from 'i18next';
import capitalize from 'lodash/capitalize';
import { SyntheticEvent, useState } from 'react';
import MultiSelectPills from '~components/MultiSelectPills';
import { HighlightDaysAndTimes } from '~components/powerSchedules/PowerScheduleDetail/HighlightDaysAndTimes';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { Day, TimeBlock } from '~graphql/__generated__/types';
import { DAYS_OF_WEEK_MON_FIRST, validateAndSortTimeBlocks } from '~utils/timeBlocks';

interface CopyTimeSlotsModalProps {
  timeBlocks: TimeBlock[];
  isOpen: boolean;
  onSubmit: (timeBlocks: TimeBlock[]) => void;
  onCancel: () => void;
  dayToCopy: Day;
}

export function CopyTimeSlotsModal({
  timeBlocks: existingTimeBlocks,
  isOpen,
  onSubmit,
  onCancel: _onCancel,
  dayToCopy,
}: CopyTimeSlotsModalProps) {
  const [days, setDays] = useState<Day[]>([]);
  const [error, setError] = useState<string | undefined>();

  const timeBlocksToCopy = existingTimeBlocks.filter(({ day }) => day === dayToCopy);

  const newTimeBlocks = (days: Day[]) => [
    ...existingTimeBlocks.map(({ start, end, day }) => ({ start, end, day })),
    ...days
      .map((day) => timeBlocksToCopy.map(({ start, end }) => ({ start, end, day: day as Day })))
      .flat(),
  ];

  const onCancel = () => {
    setDays([]);
    _onCancel();
  };

  const handleChange = (days: Day[]) => {
    try {
      setDays(days);
      validateAndSortTimeBlocks(newTimeBlocks(days));
      setError(undefined);
    } catch (err) {
      if (err instanceof Error) setError(err.message);
    }
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onSubmit(newTimeBlocks(days));
    setDays([]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{t('copyOtherDays')}</ModalHeader>
          <ModalCloseButton onClick={onCancel} />
          <ModalBody>
            <chakra.p marginBottom={5}>{t('copyOtherDaysDesc')}</chakra.p>
            <FormControl isInvalid={days.length === 0}>
              <FormLabel>{t('days')}</FormLabel>
              <MultiSelectPills
                options={DAYS_OF_WEEK_MON_FIRST.map((day) => ({
                  value: day,
                  label: capitalize(t(day).substring(0, 3)),
                  disabled: dayToCopy === day,
                }))}
                onChange={(values) => handleChange(values as Day[])}
                inputProps={{
                  name: 'days',
                }}
                value={days}
              />
            </FormControl>
            <Collapse in={!!error} animateOpacity>
              <Box marginTop={error ? 10 : 0} transition="margin 100ms">
                <InfoAlert>
                  <HighlightDaysAndTimes>{error || ''}</HighlightDaysAndTimes>
                </InfoAlert>
              </Box>
            </Collapse>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="blue" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              type="submit"
              isDisabled={!!error}
            >
              {t('copyTimeSlots')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
