import {
  Accordion,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { Row, TableInstance } from 'react-table';
import { Columns, FilterOption } from '~components/displays/DisplayTable/constants';
import { DisplayPowerState, DisplayPresence } from '~components/displays/useDisplayPresence';
import { Status } from '~components/displays/useStatus';
import { SelectOption } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { FilterButton } from './FilterButton';
import { FilterSection } from './FilterSection';
import { FilterTagList } from './FilterTags';
import { useFilterOptions } from './useFilterOptions';

type Props = {
  table: TableInstance<DisplayTable_DisplayFragment>;
};

export function Filter({ table }: Props) {
  const filters = useFilterOptions(table.state.filters, table.data);
  const allEnabledFilters = [
    ...filters.groups.enabled,
    ...filters.site.enabled,
    ...filters.firmware.enabled,
    ...filters.playlist.enabled,
    ...filters.powerSchedule.enabled,
    ...filters.warnings.enabled,
    ...filters.status.enabled,
  ];
  const analytics = useAnalyticsReporter();

  const toggleFilter = useCallback(
    (filter: FilterOption) => {
      const enabledFilters = filters[filter.column].enabled;
      const isEnabled = enabledFilters.some((f) => f.value === filter.value);

      if (isEnabled) {
        table.setFilter(
          filter.column,
          enabledFilters.filter((f) => f.value !== filter.value),
        );
      } else {
        analytics.track('displayFilter', { filter: filter.column });
        table.setFilter(filter.column, [...enabledFilters, filter]);
      }
    },
    [analytics, filters, table],
  );

  const disableFilter = useCallback(
    (filter: FilterOption) => {
      const enabledFilters = filters[filter.column].enabled;
      table.setFilter(
        filter.column,
        enabledFilters.filter((f) => f.value !== filter.value),
      );
    },
    [filters, table],
  );

  const resetAllFilters = useCallback(() => {
    table.setAllFilters([
      {
        id: Columns.Site,
        value: [],
      },
      {
        id: Columns.ClaimCode,
        value: [],
      },
      {
        id: Columns.Groups,
        value: [],
      },
      {
        id: Columns.Firmware,
        value: [],
      },
      {
        id: Columns.Playlist,
        value: [],
      },
      {
        id: Columns.PowerSchedule,
        value: [],
      },
      {
        id: Columns.Warnings,
        value: [],
      },
      {
        id: Columns.Status,
        value: [],
      },
    ]);
  }, [table]);

  return (
    <Box display="flex" alignItems="center">
      <Popover closeOnBlur closeOnEsc placement="bottom-start" isLazy>
        <PopoverTrigger>
          <FilterButton enabledFilterCount={allEnabledFilters.length} />
        </PopoverTrigger>

        <PopoverContent color="gray.900" width="430px">
          <PopoverBody>
            <Accordion allowToggle>
              <FilterSection name={t('groups')} filters={filters.groups} onToggle={toggleFilter} />
              <FilterSection name={t('sites')} filters={filters.site} onToggle={toggleFilter} />
              <FilterSection
                name={t('firmware')}
                filters={filters.firmware}
                onToggle={toggleFilter}
              />
              <FilterSection
                name={t('playlists')}
                filters={filters.playlist}
                onToggle={toggleFilter}
              />
              <FilterSection
                name={t('schedules')}
                filters={filters.powerSchedule}
                onToggle={toggleFilter}
              />
              <FilterSection
                name={t('warnings')}
                filters={filters.warnings}
                onToggle={toggleFilter}
              />
              <FilterSection name={t('status')} filters={filters.status} onToggle={toggleFilter} />
            </Accordion>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Box marginLeft="4">
        <FilterTagList
          filters={allEnabledFilters}
          onDisable={disableFilter}
          onReset={resetAllFilters}
        />
      </Box>
    </Box>
  );
}

export function filterBySite(
  rows: Array<Row<DisplayTable_DisplayFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return filterValues.length
    ? rows.filter((row) => {
        return filterValues.some((filter) => {
          return filter.value === row.original.site?.id;
        });
      })
    : rows;
}

export function filterByFirmware(
  rows: Array<Row<DisplayTable_DisplayFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return isEmpty(filterValues)
    ? rows
    : rows.filter((row) => {
        return filterValues.some((filter) => {
          const firmware = row.original.firmware?.android.version;
          return filter.value === firmware;
        });
      });
}

export function filterByPlaylist(
  rows: Array<Row<DisplayTable_DisplayFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return isEmpty(filterValues)
    ? rows
    : rows.filter((row) => {
        return filterValues.some((filter) => {
          return filter.value === row.original.playlist?.current?.title;
        });
      });
}

export function filterByPowerSchedule(
  rows: Array<Row<DisplayTable_DisplayFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return isEmpty(filterValues)
    ? rows
    : rows.filter((row) => {
        return filterValues.some((filter) => {
          return filter.value === row.original.powerSchedule?.schedule?.title;
        });
      });
}

/**
 * Handles a single row by status (as opposed to multiple ones like the other filter methods here).
 * The `status` that we need cannot be obtained here since it relies on hooks, so parsing the array of rows has been
 * lifted to the `DisplayTable` component instead.
 */
export function filterRowByWarningStatus(
  row: Row<DisplayTable_DisplayFragment>,
  status: Status,
  filterValues: SelectOption[],
) {
  if (isEmpty(filterValues)) {
    return row;
  }

  const shouldRenderRow = filterValues.some((filter) => {
    if (status.kind === 'ok' && filter.value === 'No warnings') {
      return true;
    } else if (status.kind === 'warnings') {
      return status.warnings.find(({ message }) => message === filter.value) !== undefined;
    } else if (status.kind === 'error') {
      return status.errorMessage === filter.value;
    }
  });

  return shouldRenderRow ? row : null;
}

export function filterRowByStatus(
  row: Row<DisplayTable_DisplayFragment>,
  presence: DisplayPresence,
  powerState: DisplayPowerState,
  filterValues: SelectOption[],
) {
  if (isEmpty(filterValues)) {
    return row;
  }

  const presenceFilters: SelectOption[] = [];
  const powerStateFilters: SelectOption[] = [];

  filterValues.forEach((filter) => {
    if (['Connected', 'Disconnected'].includes(filter.label)) {
      presenceFilters.push(filter);
    }
    if (['Powered on', 'Standby'].includes(filter.label)) {
      powerStateFilters.push(filter);
    }
  });

  const rowMatchesPresenceFilters =
    presenceFilters.length === 0 ||
    presenceFilters.some((filter) => presence.label === filter.value);
  const rowMatchesPowerStateFilters =
    powerStateFilters.length === 0 ||
    powerStateFilters.some((filter) => powerState.label === filter.value);

  const rowMatchesFilters = rowMatchesPresenceFilters && rowMatchesPowerStateFilters;

  return rowMatchesFilters ? row : null;
}

export function filterByGroups(
  rows: Array<Row<DisplayTable_DisplayFragment>>,
  columnIds: string[],
  filterValues: SelectOption[],
) {
  return filterValues.length
    ? rows.filter((row) => {
        return filterValues
          .map((filter) => filter.value)
          .some((id) => {
            return (row.original.groups?.map((group) => group.id) ?? []).indexOf(id) > -1;
          });
      })
    : rows;
}
