import {
  Checkbox,
  FormControl,
  FormLabel,
  GridItem,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import gql from 'graphql-tag';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSelect } from '~components/powerSchedules/TimeSelect';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { SwitchType } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { HOUR_REGEX } from '~utils/timeBlocks';
import {
  DisplayAutoRestart_DisplayFragment,
  useUpdateAutoRestartMutation,
} from './__generated__/DisplayAutoRestart.graphql';

interface Props {
  display: DisplayAutoRestart_DisplayFragment;
}

const getInitialState = (display: DisplayAutoRestart_DisplayFragment) => {
  let initialEnabled = false;
  let initialTime = '--:--';
  if (isPropertyPending(display.autoRestart)) {
    initialEnabled = display.autoRestart?.desired?.switch === 'ON' ? true : false;
    initialTime = display.autoRestart?.desired?.time ?? '--:--';
  } else if (!isPropertyPending(display.autoRestart)) {
    initialEnabled = display.autoRestart?.reported?.switch === 'ON' ? true : false;
    initialTime = display.autoRestart?.reported?.time ?? '--:--';
  }
  return { initialEnabled, initialTime };
};

export function DisplayAutoRestart({ display }: Props) {
  const { initialEnabled, initialTime } = getInitialState(display);
  const [startTime, setStartTime] = useState<string>(initialTime);
  const [isAutoRestartEnabled, setIsAutoRestartEnabled] = useState<boolean>(initialEnabled);

  const updateTimeValue = (property: 'start' | 'end', value: string) => {
    if (!HOUR_REGEX.test(value)) return;
    setStartTime(value);
    handleUpdateAutoRestart(value, true);
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (isPropertyPending(display.autoRestart)) {
      setIsAutoRestartEnabled(display.autoRestart?.desired?.switch === 'ON' ? true : false);
      setStartTime(display.autoRestart?.desired?.time ?? '--:--');
    } else if (!isPropertyPending(display.autoRestart)) {
      setIsAutoRestartEnabled(display.autoRestart?.reported?.switch === 'ON' ? true : false);
      setStartTime(display.autoRestart?.reported?.time ?? '--:--');
    }
  }, [display]);
  const toast = useToast();
  const [updateAutoRestart] = useUpdateAutoRestartMutation();

  const handleUpdateAutoRestart = useCallback(
    async (time: string, checkbox: boolean) => {
      try {
        if (time.length === 5) {
          await updateAutoRestart({
            variables: {
              input: {
                id: display.id,
                switch: checkbox ? SwitchType.On : SwitchType.Off,
                time: time,
              },
            },
          });
        }
      } catch (err) {
        toast({
          status: 'error',
          title: t('autoRestartErr'),
          description: fromError(err, 'UpdateAutoRestart'),
        });
      }
    },
    [display, toast, updateAutoRestart, t],
  );
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoRestartEnabled(e.target.checked);
    if (!e.target.checked) {
      handleUpdateAutoRestart(startTime, false);
    }
  };
  return (
    <GridItem colSpan={{ base: 8, md: 7 }}>
      <Stack direction="column" spacing="10">
        {!isNil(display.autoRestart?.reported) && (
          <FormControl>
            <FormLabel>
              {t('autoRestart')}
              <FormLabelPendingIndicator isPending={isPropertyPending(display.autoRestart)} />
            </FormLabel>

            <Text color="gray.500" mb="2">
              {t('autoRestartText')}
            </Text>

            <Checkbox isChecked={isAutoRestartEnabled} onChange={handleCheckboxChange}>
              {t('EnableAutoRestart')}
            </Checkbox>
            <FormControl maxWidth="80px" marginTop={5}>
              <TimeSelect
                value={startTime}
                onChange={updateTimeValue}
                property="start"
                disabled={!isAutoRestartEnabled}
              />
            </FormControl>
          </FormControl>
        )}
      </Stack>
    </GridItem>
  );
}

DisplayAutoRestart.graphql = {
  fragments: {
    DisplayAutoRestart_display: gql`
      fragment DisplayAutoRestart_display on Display {
        id
        autoRestart {
          reported {
            switch
            time
          }
          desired {
            switch
            time
          }
        }
      }
    `,
  },
  mutations: {
    UpdatePower: gql`
      mutation updateAutoRestart($input: DisplayUpdateAutoRestartInput!) {
        displayUpdateAutoRestart(input: $input) {
          display {
            id
            ...DisplayAutoRestart_display
          }
        }
      }
    `,
  },
};
