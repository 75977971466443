import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback } from 'react';
import { fromError } from '~utils/errors';
import { useRemovePowerScheduleFromDisplaysMutation } from './__generated__/useRemovePowerScheduleFromDisplays.graphql';

export function useRemovePowerScheduleFromDisplays() {
  const toast = useToast();
  const [removePowerScheduleFromDisplaysMutation, { loading }] =
    useRemovePowerScheduleFromDisplaysMutation();

  const removePowerScheduleFromDisplays = useCallback(
    async (powerScheduleId: string) => {
      try {
        await removePowerScheduleFromDisplaysMutation({
          variables: {
            input: {
              powerScheduleId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('removePowerScheduleErr'),
          description: fromError(err, 'UpdatePowerSchedule'),
        });
      }
    },
    [removePowerScheduleFromDisplaysMutation, toast],
  );

  return { removePowerScheduleFromDisplays, loading };
}

useRemovePowerScheduleFromDisplays.graphql = {
  fragments: {
    useRemovePowerScheduleFromDisplays_powerSchedule: gql`
      fragment useRemovePowerScheduleFromDisplays_powerSchedule on PowerSchedule {
        id
        removingDisplays: displays(filter: { state: REMOVING }) {
          id
        }
      }
    `,
  },
  mutations: {
    RemovePowerScheduleFromDisplays: gql`
      mutation RemovePowerScheduleFromDisplays($input: PowerScheduleRemoveDisplaysInput!) {
        powerScheduleRemoveDisplays(input: $input) {
          displays {
            id
            powerSchedule {
              schedule {
                id
                title
                ...useRemovePowerScheduleFromDisplays_powerSchedule
              }
            }
          }
        }
      }
    `,
  },
};
