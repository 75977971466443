import { ApolloError, gql } from '@apollo/client';
import {
  AlertDescription,
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningAlert } from '~components/ui/Alert';
import { BulkDisplayIllustration } from '~components/ui/illustrations/BulkDisplayIllustration';
import { SingleDisplayIllustration } from '~components/ui/illustrations/SingleDisplayIllustration';
import { CustomerDisplaysIndexPageQuery } from '~pages/[organization]/customers/[handle]/displays/__generated__/Index.graphql';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure } from '~utils/types';
import {
  useMultiClaimSlotAvailableQuery,
  useMultiClaimUpdateMutation,
} from './__generated__/selectClaimModal.graphql';

interface Props {
  customer: CustomerDisplaysIndexPageQuery['customer'];
  isOpen: boolean;
  onClose: () => void;
  onSelectSingle: () => void;
  onSuccess: (data: { code: string; id: string }) => Promise<void> | void;
}

export function SelectClaimModal({ customer, isOpen, onSelectSingle, onClose, onSuccess }: Props) {
  const { data } = useMultiClaimSlotAvailableQuery({
    variables: {
      input: {
        customerId: ensure(customer?.id),
      },
    },
    skip: !isOpen,
  });
  const [multiClaimUpdateMutation] = useMultiClaimUpdateMutation();
  const [islimitReached, limitReached] = useState(false);
  const hasWaveSubscription = isWaveSubscription(customer?.waveSubscription);
  const navigate = useNavigate();
  const handleGoToClaimCode = () => {
    navigate('../displays/claimCode');
  };
  const onBulkClaimSelect = useCallback(async () => {
    try {
      const { data } = await multiClaimUpdateMutation({
        variables: {
          input: {
            customerId: customer?.id || '',
          },
        },
      });
      limitReached(false);
      if (!data) {
        throw new Error('Create claim code failed');
      }
      const { id, code } = data.generateMultiClaimCode;
      await onSuccess?.({ id, code });
    } catch (err) {
      const error: ApolloError = err as ApolloError;
      if (error.message === 'MULTI_CLAIM_CODE_MAX_REACHED') {
        limitReached(true);
        return;
      }
    }
  }, [customer, multiClaimUpdateMutation, onSuccess]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{t('selectClaimingMethod')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Single Display Claim */}
            <Box
              as="button"
              onClick={onSelectSingle}
              w="100%"
              p={4}
              borderRadius="xl"
              color={'black'}
              bg="#F1F8FE"
              _hover={{ bg: 'blue.100' }}
              textAlign="left"
            >
              <Flex align="center">
                <Box mr={8}>
                  <Icon as={SingleDisplayIllustration} boxSize={36} color="blue.500" />
                </Box>
                <Box>
                  <Text mb={4} fontWeight="bold" fontSize="lg">
                    {t('singleDisplayClaim')}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {t('singleDisplayClaimText')}
                  </Text>
                </Box>
              </Flex>
            </Box>

            {/* Bulk Display Claim */}
            <Box
              as="button"
              onClick={onBulkClaimSelect}
              w="100%"
              p={4}
              borderRadius="xl"
              bg={
                data?.multiClaimSlotAvailable === false || !hasWaveSubscription
                  ? 'gray.200'
                  : '#F1F8FE'
              }
              color={
                data?.multiClaimSlotAvailable === false || !hasWaveSubscription
                  ? 'gray.300'
                  : 'black'
              }
              disabled={data?.multiClaimSlotAvailable === false || !hasWaveSubscription}
              _disabled={{
                bg: 'gray.50',
                cursor: 'not-allowed',
              }}
              _hover={
                data?.multiClaimSlotAvailable === false || !hasWaveSubscription
                  ? undefined
                  : { bg: 'blue.100' }
              }
              textAlign="left"
            >
              <Flex align="center">
                <Box mr={8}>
                  <Icon as={BulkDisplayIllustration} boxSize={36} color="green.500" />
                </Box>
                <Box>
                  <Text mb={4} fontWeight="bold" fontSize="lg">
                    {t('bulkDisplayClaim')}
                  </Text>
                  <Text
                    fontSize="sm"
                    color={
                      data?.multiClaimSlotAvailable === false || !hasWaveSubscription
                        ? 'gray.300'
                        : 'gray.600'
                    }
                  >
                    {t('bulkDisplayClaimText')}
                  </Text>
                </Box>
              </Flex>
            </Box>
            {(islimitReached || data?.multiClaimSlotAvailable == false) && hasWaveSubscription && (
              <Flex justifyContent="center" alignItems="center">
                <WarningAlert textAlign="center" mx="auto" maxW="lg" w="fit-content">
                  <AlertDescription>
                    {t('bulkDisplayClaimAlert')}{' '}
                    <span
                      onClick={handleGoToClaimCode}
                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('here')}
                    </span>
                  </AlertDescription>
                </WarningAlert>
              </Flex>
            )}
            {!hasWaveSubscription && (
              <Flex justifyContent="center" alignItems="center">
                <WarningAlert textAlign="center" mx="auto" maxW="lg" w="fit-content">
                  <AlertDescription>{t('bulkDisplayClaimDesc')}</AlertDescription>
                </WarningAlert>
              </Flex>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

SelectClaimModal.graphql = {
  queries: {
    MultiClaimSlotAvailable: gql`
      query MultiClaimSlotAvailable($input: MultiClaimSlotAvailableInput!) {
        multiClaimSlotAvailable(input: $input)
      }
    `,
  },
  mutations: {
    SelectClaimModal: gql`
      mutation multiClaimUpdate($input: GenerateMultiClaimCodeInput!) {
        generateMultiClaimCode(input: $input) {
          id
          code
          createdAt
        }
      }
    `,
  },
};
