import { Permission } from '@tp-vision/roles-permissions';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import { LogoSettings_OrganizationFragment } from '~components/settings/__generated__/LogoSettings.graphql';
import {
  BuildingIcon,
  ImageIcon,
  KeyIcon,
  ProfileIcon,
  SubscriptionIcon,
  UsersIcon,
} from '~components/ui/icons';
import { AppsIcon } from '~components/ui/icons/AppsIcon';
import { ShieldIcon } from '~components/ui/icons/ShieldIcon';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { useTabs } from '~components/ui/Tabs';
import {
  ActiveTabBackground,
  VerticalTab,
  VerticalTabDivider,
  VerticalTabList,
  VerticalTabPanel,
  VerticalTabs,
} from '~components/ui/VerticalTabs';
import { isDefined } from '~utils/types';

export function useLogoSettingsTab() {
  return useOutletContext<{
    organization: LogoSettings_OrganizationFragment;
  }>();
}

export function OrganizationSettingsIndexPage() {
  const { t } = useTranslation();
  const { organization, verifyUserPermissions } = useAuth();

  const { tabs, activeTab } = useTabs(
    [
      verifyUserPermissions([Permission.OrganizationUpdate])
        ? {
            header: t('organization'),
            path: 'logo',
            label: t('logo'),
            icon: ImageIcon,
          }
        : null,
      verifyUserPermissions([Permission.UserUpdate])
        ? {
            header: t('organization'),
            path: 'users',
            label: t('users'),
            icon: UsersIcon,
          }
        : null,
      verifyUserPermissions([Permission.CustomerUpdate])
        ? {
            header: t('organization'),
            path: 'customers',
            label: t('customers'),
            icon: BuildingIcon,
          }
        : null,
      verifyUserPermissions([Permission.WaveSubscriptionUpdate])
        ? {
            header: t('organization'),
            path: 'subscriptions',
            label: t('subscriptions'),
            icon: SubscriptionIcon,
          }
        : null,
      {
        header: t('organization'),
        path: 'apps',
        label: t('apps'),
        icon: AppsIcon,
      },
      {
        header: t('personal'),
        path: 'profile',
        label: t('Profile'),
        icon: ProfileIcon,
      },
      verifyUserPermissions([Permission.ApiKeyCreate, Permission.ApiKeyDelete])
        ? {
            header: t('personal'),
            path: 'api-keys',
            label: t('APIKeys'),
            icon: KeyIcon,
          }
        : null,
      {
        header: 'Personal',
        path: 'privacy-and-security',
        label: 'Privacy & Security',
        icon: ShieldIcon,
      },
    ].filter(isDefined),
  );

  return (
    <Page
      title={activeTab?.label ? `Settings | ${activeTab.label}` : 'Settings'}
      pageName="settings"
    >
      <PageContent paddingY="14">
        <VerticalTabs>
          <VerticalTabList>
            {tabs.map((tab, i) => (
              <VerticalTab key={tab.path} ref={tab.ref} tab={tab} prevTab={tabs[i - 1]} />
            ))}
            <ActiveTabBackground tabs={tabs} />
          </VerticalTabList>
          <VerticalTabDivider />
          <VerticalTabPanel>
            <Outlet context={{ organization }} />
          </VerticalTabPanel>
        </VerticalTabs>
      </PageContent>
    </Page>
  );
}
