import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Popover,
  PopoverContent,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '~auth/useAuth';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  AlertsMenu_AlertFragment,
  useAlertsMenuQuery,
  useResolveAlertsMutation,
} from './__generated__/AlertsMenu.graphql';
import { AlertsItem } from './AlertsItem';
import { AlertsMenuTrigger } from './AlertsTrigger';

const POLL_INTERVAL = 15000;

export function AlertsMenu() {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useAuth();
  const analytics = useAnalyticsReporter();

  const contentRef = useRef<HTMLElement>(null);
  const [resolveAlerts] = useResolveAlertsMutation();
  const { loading, error, data, startPolling, stopPolling } = useAlertsMenuQuery({
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
  });
  const empty = data && data.organization.displayAlerts.length === 0;
  const alertCount = data?.organization.displayAlerts.length ?? 0;

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  useEffect(() => {
    if (alertCount > 0) {
      analytics.track('notificationReceived');
    }
  }, [alertCount, analytics]);

  const handleGoToDisplay = useCallback(
    (alert: AlertsMenu_AlertFragment) => {
      analytics.track('notificationAction');
      const customerHandle = alert.customerHandle;
      const displayId = alert.displayId;
      navigate(
        `/${auth.organization?.handle}/customers/${customerHandle}/displays/${displayId}/summary`,
      );
    },
    [analytics, navigate, auth.organization?.handle],
  );

  const handleMarkAlert = useCallback(
    async (alert: AlertsMenu_AlertFragment) => {
      try {
        await resolveAlerts({
          variables: {
            input: {
              displayAlertIds: [alert.id],
            },
          },
        });

        analytics.track('notificationDone', { count: 1 });

        // Popover sometimes loses focus after mutations
        // which prevents closeOnBlur from working.
        // Manual refocus is an easy fix.
        contentRef?.current?.focus();
      } catch (err) {
        toast({
          status: 'error',
          title: t('markAlertDoneErr'),
          description: fromError(err, 'ResolveAlerts'),
        });
      }
    },
    [analytics, resolveAlerts, toast],
  );

  const handleMarkAllAlerts = useCallback(async () => {
    try {
      if (!data) return;
      const displayAlertIds = data.organization.displayAlerts.map((a) => a.id);

      await resolveAlerts({
        variables: {
          input: {
            displayAlertIds,
          },
        },
      });

      analytics.track('notificationDone', { count: displayAlertIds.length });

      // Popover sometimes loses focus after mutations
      // which prevents closeOnBlur from working.
      // Manual refocus is an easy fix.
      contentRef?.current?.focus();
    } catch (err) {
      toast({
        status: 'error',
        title: t('markAlertsDoneErr'),
        description: fromError(err, 'ResolveAllAlerts'),
      });
    }
  }, [analytics, data, resolveAlerts, toast]);

  return (
    <Popover offset={[0, 30]} returnFocusOnClose={false} closeOnEsc={true} closeOnBlur={true}>
      <AlertsMenuTrigger alertCount={alertCount} />

      <PopoverContent
        ref={contentRef}
        width={{
          base: 'xs',
          md: 'xl',
        }}
        minHeight="281px"
        maxHeight="md"
      >
        {loading ? (
          <Center>
            <Spinner />
          </Center>
        ) : error ? (
          <Center>
            <Text>{t('somethingWentWrong')}</Text>
          </Center>
        ) : empty ? (
          <>
            <AlertsHeader alertCount={0} />

            <Center flex="1" backgroundColor="#F9F9FB">
              <Text color="#5A779A">{t('noAlertsMsg')}</Text>
            </Center>
          </>
        ) : data ? (
          <>
            <AlertsHeader alertCount={alertCount} />

            <VStack flex="1" px="2" my="4" overflow="auto">
              {data.organization.displayAlerts.map((alert) => (
                <AlertsItem
                  key={alert.id}
                  alert={alert}
                  onGoToDisplay={handleGoToDisplay}
                  onMarkAlert={handleMarkAlert}
                />
              ))}
            </VStack>

            <AlertsFooter onMarkAllAlerts={handleMarkAllAlerts} />
          </>
        ) : (
          <Center>
            <Text>{t('somethingWentWrong')}</Text>
          </Center>
        )}
      </PopoverContent>
    </Popover>
  );
}

function AlertsHeader({ alertCount }: { alertCount: number }) {
  return (
    <Box display="flex" px="4" pt="2.5">
      <Box p="2" borderBottomWidth="thin" borderBottomColor="#4B9EEC">
        <Text color="#4B9EEC">
          {t('allAlerts')} ({alertCount})
        </Text>
      </Box>

      <Box height="41.48px" flex="1" borderBottomWidth="thin" borderBottomColor="gray.100" />
    </Box>
  );
}

function AlertsFooter({ onMarkAllAlerts }: { onMarkAllAlerts: () => Promise<void> }) {
  const { verifyUserPermissions } = useAuth();

  const hasAlertResolvePermission = verifyUserPermissions([Permission.AlertResolve]);

  return (
    <Box
      roundedBottom="md"
      p="2"
      display="flex"
      justifyContent="flex-end"
      width="full"
      backgroundColor="#F9F9FB"
    >
      <Button
        variant="ghost"
        color="#4B9EEC"
        onClick={onMarkAllAlerts}
        isDisabled={!hasAlertResolvePermission}
      >
        {t('markAllDone')}
      </Button>
    </Box>
  );
}

AlertsMenu.graphql = {
  mutations: {
    resolveAlerts: gql`
      mutation ResolveAlerts($input: DisplayAlertBulkResolveInput!) {
        displayAlertBulkResolve(input: $input) {
          displayAlertIds
        }
      }
    `,
  },
  queries: {
    AlertsMenu: gql`
      query AlertsMenu {
        organization {
          id
          displayAlerts {
            id
            ...AlertsMenu_alert
          }
        }
      }
    `,
  },
  fragments: {
    AlertsMenu_alert: gql`
      fragment AlertsMenu_alert on DisplayAlert {
        id
        createdAt
        message
        displayId
        customerHandle
      }
    `,
  },
};
