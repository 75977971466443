import { gql } from '@apollo/client';
import { Box, Center, Divider, HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { CustomerSwitcher } from '~components/customers/CustomerSwitcher';
import { AlertsMenu } from '~components/organization/AlertsMenu/AlertsMenu';
import { ApplicationMenu } from '~components/organization/ApplicationMenu';
import { UserMenu } from '~components/organization/UserMenu';
import { HeaderNavLinks } from '~components/ui/HeaderNavLinks';
import { LandscapeIcon, PlaylistIcon, PowerScheduleIcon, SiteIcon } from '~components/ui/icons';
import { Logo } from '~components/ui/illustrations';
import { CONTAINER_MAX_WIDTH, CONTAINER_PADDING } from '~components/ui/PageContent';
import { useNavbarQuery } from './__generated__/Navbar.graphql';

export const NAVBAR_HEIGHT = 68;

export function Navbar({ hideNavigationLinks = false }) {
  const { customer } = useParams();
  const { data } = useNavbarQuery();
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      height={NAVBAR_HEIGHT}
      paddingX={CONTAINER_PADDING}
      display="flex"
      maxW={CONTAINER_MAX_WIDTH}
    >
      {data?.organization && (
        <>
          <HStack spacing="6">
            <Link to={`/${data.organization.handle}`}>
              <Logo width="60px" />
            </Link>
            <Center height="23px">
              <Divider orientation="vertical" />
            </Center>
            <CustomerSwitcher organization={data.organization} activeCustomerHandle={customer} />
          </HStack>
          {!hideNavigationLinks && (
            <HeaderNavLinks
              links={[
                {
                  to: 'displays',
                  label: t('Displays'),
                  icon: LandscapeIcon,
                },
                {
                  to: 'sites',
                  label: t('sites'),
                  icon: SiteIcon,
                },
                {
                  to: 'playlists',
                  label: t('playlists'),
                  icon: PlaylistIcon,
                },

                {
                  to: 'schedules',
                  label: t('schedules'),
                  icon: PowerScheduleIcon,
                },
              ]}
            />
          )}
          <Box flex="1" display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing="4">
              <Box display="flex" alignItems="center">
                <AlertsMenu />
              </Box>
              <ApplicationMenu />
              <UserMenu />
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
}

Navbar.graphql = {
  queries: {
    Navbar: gql`
      query Navbar {
        organization {
          id
          ...CustomerSwitcher_organization
        }
      }
    `,
  },
};
