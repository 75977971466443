import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useAuth } from '~auth/useAuth';
import {
  EditIcon,
  LoadingIcon,
  MoreIcon,
  NoDisplayIcon,
  SyncIcon,
  TrashIcon,
} from '~components/ui/icons';
import { PlaylistDetail_PlaylistFragment } from './__generated__/PlaylistDetail.graphql';

type Props = {
  playlist: PlaylistDetail_PlaylistFragment;
  onRename: () => void;
  onSync: () => void;
  onRemoveDisplays: () => void;
  onDelete: () => void;
};

export function PlaylistDetailMoreActions({
  playlist,
  onRename,
  onSync,
  onRemoveDisplays,
  onDelete,
}: Props) {
  const { verifyUserPermissions } = useAuth();

  const hasDisplayContentDeletePermission = verifyUserPermissions([
    Permission.DisplayContentDelete,
  ]);
  const hasDisplayContentUpdatePermission = verifyUserPermissions([
    Permission.DisplayContentUpdate,
  ]);
  const hasPlaylistUpdatePermission = verifyUserPermissions([Permission.PlaylistUpdate]);
  const hasPlaylistDeletePermission = verifyUserPermissions([Permission.PlaylistDelete]);

  const hasDisplays = playlist.syncedDisplays.length > 0;
  const hasOutOfSyncDisplays = playlist.outOfSyncDisplays.length > 0;
  const isSyncing = playlist.syncingDisplays.length > 0;
  const isRemoving = playlist.removingDisplays.length > 0;
  const isUpdating = isSyncing || isRemoving;

  return (
    <Menu>
      <MenuButton as={Button} aria-label="Options" variant="outline">
        <MoreIcon />
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<EditIcon />}
          onClick={onRename}
          isDisabled={!hasPlaylistUpdatePermission || isUpdating}
        >
          {t('rename')}
        </MenuItem>
        <MenuItem
          icon={isSyncing ? <LoadingIcon /> : <SyncIcon />}
          isDisabled={!hasDisplayContentUpdatePermission || isUpdating || !hasOutOfSyncDisplays}
          onClick={onSync}
        >
          {t('sync')}
        </MenuItem>
        <MenuItem
          icon={isRemoving ? <LoadingIcon /> : <NoDisplayIcon />}
          isDisabled={!hasDisplayContentDeletePermission || isUpdating || !hasDisplays}
          onClick={onRemoveDisplays}
        >
          {t('removeFromDisplays')}
        </MenuItem>
        <MenuItem
          icon={<TrashIcon color="red.500" />}
          color={'red.500'}
          isDisabled={!hasPlaylistDeletePermission || isUpdating}
          onClick={onDelete}
        >
          {t('delete')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
