import { gql } from '@apollo/client';
import { Box, chakra, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  DisplayPreview_Claimable_DisplayFragment,
  DisplayPreview_DisplayFragment,
} from './__generated__/DisplayPreview.graphql';

interface Props {
  display: DisplayPreview_DisplayFragment | DisplayPreview_Claimable_DisplayFragment;
}

export function DisplayPreview({ display }: Props) {
  let platformType = 'unknown';
  if (display.__typename === 'ClaimableDisplay') {
    platformType = display.platformType;
  }
  if (display.__typename === 'Display') {
    platformType = display.platform.type;
  }
  const { t } = useTranslation();
  return (
    <Box
      background="gray.75"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="base"
      paddingX="4"
      paddingY="4"
    >
      <SimpleGrid columns={3} spacing={1}>
        <Box>
          <chakra.span fontSize="md" color="gray.400">
            {t('modelNumber')}
          </chakra.span>
        </Box>
        <Box>
          <chakra.span fontSize="md" color="gray.400">
            {t('displayType')}
          </chakra.span>
        </Box>
        <Box>
          <chakra.span fontSize="md" color="gray.400">
            {t('serialNumber')}
          </chakra.span>
        </Box>
        <Box>
          <chakra.span fontSize="md" color="blue.800">
            {display.commercialTypeNumber}
          </chakra.span>
        </Box>
        <Box>
          <chakra.span fontSize="md" color="blue.800">
            {platformType}
          </chakra.span>
        </Box>
        <Box>
          <chakra.span fontSize="md" color="blue.800">
            {display.serialNumber}
          </chakra.span>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

DisplayPreview.graphql = {
  fragments: {
    DisplayPreview_display: gql`
      fragment DisplayPreview_display on Display {
        id
        commercialTypeNumber
        serialNumber
        platform {
          type
        }
      }
    `,
    DisplayPreview_claimable_display: gql`
      fragment DisplayPreview_claimable_display on ClaimableDisplay {
        id
        commercialTypeNumber
        serialNumber
        platformType
      }
    `,
  },
};
