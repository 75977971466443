import { gql } from '@apollo/client';
import { chakra, HStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { LandscapeIcon, WarningIcon } from '~components/ui/icons';
import { PowerScheduleTable_PowerScheduleFragment } from '../__generated__/PowerScheduleTable.graphql';
import { UsedByCell_PowerScheduleFragment } from './__generated__/UsedBy.graphql';

export function UsedByCell({
  value,
}: PropsWithChildren<
  CellProps<PowerScheduleTable_PowerScheduleFragment, UsedByCell_PowerScheduleFragment>
>) {
  const syncedCount = value.syncedDisplays.length;
  const outOfSyncCount = value.outOfSyncDisplays.length;
  const syncingCount = value.syncingDisplays.length;

  return (
    <HStack spacing="4">
      <HStack spacing="2">
        <LandscapeIcon height="6" width="6" />
        <chakra.span display="block">{syncedCount}</chakra.span>
      </HStack>
      {outOfSyncCount > 0 && (
        <HStack spacing="2">
          <WarningIcon height="6" width="6" color="orange.400" />
          <chakra.span display="block">
            {outOfSyncCount} {t('outOfSync')}
          </chakra.span>
        </HStack>
      )}
      {syncingCount > 0 && (
        <HStack spacing="2">
          <WarningIcon height="6" width="6" color="orange.400" />
          <chakra.span display="block">
            {syncingCount} {t('syncing')}
          </chakra.span>
        </HStack>
      )}
    </HStack>
  );
}

UsedByCell.graphql = {
  fragments: {
    UsedByCell_powerSchedule: gql`
      fragment UsedByCell_powerSchedule on PowerSchedule {
        id
        syncedDisplays: displays(filter: { state: SYNCED }) {
          id
        }
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
        syncingDisplays: displays(filter: { state: SYNCING }) {
          id
        }
      }
    `,
  },
};
