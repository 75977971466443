import { gql } from '@apollo/client';
import {
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { ErrorAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { Slider } from '~components/ui/Slider';
import {
  convertSecondsToTime,
  convertTimeToSeconds,
  formatSecondsToTimeString,
  TimeType,
} from '~utils/durationConversion';
import { useSetMediaDurationMutation } from './__generated__/DurationModals.graphql';
import {
  PlaylistDetail_PlaylistFragment,
  usePlaylistUpdateMutation,
} from './PlaylistDetail/__generated__/PlaylistDetail.graphql';
import { PlaylistTableMedia } from './PlaylistMediaTable/PlaylistMediaTable';

export function DurationSelector({
  onChange,
  duration = 0,
  disableSelectors = false,
}: {
  onChange: (duration: number) => void;
  duration?: number;
  disableSelectors: boolean;
}) {
  const convertedDuration = convertSecondsToTime(duration);
  const handleInputValueChange = ({
    e,
    min = 0,
    max,
    defaultValue = 0,
    setter,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    min?: number;
    max: number;
    defaultValue?: number;
    setter: (value: number) => void;
  }) => {
    let value = parseInt(e.target.value || `${defaultValue}`);
    if (value < min) value = min;
    if (value > max) value = max;
    setter(value);
  };

  const handleChange = (time: TimeType) => onChange(convertTimeToSeconds(time));

  const MAX_SECONDS = 59;
  const MAX_MINS = 59;
  const MAX_HOURS = 23;
  const MAX_DAYS = 2;

  const sliders = [
    {
      key: 'secs',
      label: t('sec'),
      max: MAX_SECONDS,
      value: convertedDuration.secs,
      onChange: (value: number) => handleChange({ ...convertedDuration, secs: value }),
      onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleInputValueChange({
          e,
          max: MAX_SECONDS,
          setter: (value: number) => handleChange({ ...convertedDuration, secs: value }),
        }),
    },
    {
      key: 'mins',
      label: t('minSlider'),
      max: MAX_MINS,
      value: convertedDuration.mins,
      onChange: (value: number) => handleChange({ ...convertedDuration, mins: value }),
      onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleInputValueChange({
          e,
          max: MAX_MINS,
          setter: (value: number) => handleChange({ ...convertedDuration, mins: value }),
        }),
    },
    {
      key: 'hours',
      label: t('hour'),
      max: MAX_HOURS,
      value: convertedDuration.hours,
      onChange: (value: number) => handleChange({ ...convertedDuration, hours: value }),
      onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleInputValueChange({
          e,
          max: MAX_HOURS,
          setter: (value: number) => handleChange({ ...convertedDuration, hours: value }),
        }),
    },
    {
      key: 'days',
      label: t('day'),
      max: MAX_DAYS,
      value: convertedDuration.days,
      onChange: (value: number) => handleChange({ ...convertedDuration, days: value }),
      onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleInputValueChange({
          e,
          max: MAX_DAYS,
          setter: (value: number) => handleChange({ ...convertedDuration, days: value }),
        }),
    },
  ];

  return (
    <VStack w={'100%'} spacing={5} mt="6">
      {sliders.map((slider) => {
        return (
          <HStack w={'100%'} key={slider.key}>
            <Box w={12}>{slider.label}</Box>
            <Box width={'80%'} px={4}>
              <Slider
                min={0}
                max={slider.max}
                minScale={0}
                maxScale={slider.max}
                value={slider.value}
                labelSuffix={''}
                onChange={slider.onChange}
                isDisabled={disableSelectors}
              />
            </Box>

            <Input
              justifyItems={'center'}
              w={16}
              value={slider.value}
              isDisabled={disableSelectors}
              onChange={slider.onInputChange}
            />
          </HStack>
        );
      })}
    </VStack>
  );
}

export function RenderSetDurationError({
  errorTitle,
  errorDescription,
}: {
  errorTitle: string;
  errorDescription?: string;
}) {
  return (
    <ErrorAlert mt={8}>
      <AlertTitle>{errorTitle}</AlertTitle>
      {errorDescription && <AlertDescription>{errorDescription}</AlertDescription>}
    </ErrorAlert>
  );
}

export enum DurationModalType {
  PlaylistDefaultDuration = 'defaultDuration',
  MediaDuration = 'duration',
}

type HandleDurationModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  playlist: PlaylistDetail_PlaylistFragment;
  selectedMedia: PlaylistTableMedia[];
  variant: DurationModalType;
};

export function HandleDurationModal({
  isOpen,
  onCancel,
  playlist,
  selectedMedia,
  variant,
}: HandleDurationModalProps) {
  const [updateMediaDuration] = useSetMediaDurationMutation();
  const [updatePlaylist] = usePlaylistUpdateMutation();
  const [duration, setDuration] = useState(
    variant === DurationModalType.PlaylistDefaultDuration
      ? playlist.defaultDuration
      : selectedMedia.length === 1
      ? selectedMedia[0].duration
      : playlist.defaultDuration,
  );
  const [isDefaultDurationChecked, setIsDefaultDurationChecked] = useState(false);
  const [errorDetails, setErrorDetails] = useState<{
    errorTitle: string;
    errorDescription?: string;
  } | null>(null);

  useEffect(() => {
    if (duration < 90)
      setErrorDetails({
        errorTitle: t('durationErr'),
      });
    else {
      setErrorDetails(null);
    }
  }, [duration]);

  useEffect(() => {
    setDuration(
      variant === DurationModalType.PlaylistDefaultDuration
        ? playlist.defaultDuration
        : selectedMedia.length === 1
        ? selectedMedia[0].duration
        : playlist.defaultDuration,
    );
    setIsDefaultDurationChecked(false);
  }, [selectedMedia, setDuration, isOpen, playlist.defaultDuration, variant]);

  const handleSetDuration = async () => {
    if (variant === DurationModalType.MediaDuration) {
      await updateMediaDuration({
        variables: {
          input: {
            mediaIds: selectedMedia.map((media) => media.id),
            playlistId: playlist.id,
            duration: duration,
            isDefaultDuration: isDefaultDurationChecked,
          },
        },
        refetchQueries: ['PlaylistDetailsPage'],
      });
    } else {
      await updatePlaylist({
        variables: {
          input: {
            playlistId: playlist.id,
            defaultDuration: duration,
          },
        },
        refetchQueries: ['PlaylistDetailsPage'],
      });
    }

    onCancel();
  };

  const ModalDetails = {
    [DurationModalType.MediaDuration]: {
      title: t('setDuration'),
      description: t('setDurationDesc'),
      submitButtonLabel: t('setDuration'),
    },
    [DurationModalType.PlaylistDefaultDuration]: {
      title: t('setDefaultDuration'),
      description: t('setDefaultDurationDesc'),
      submitButtonLabel: t('setDefault'),
    },
  };

  const modalDetail = ModalDetails[variant];

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalDetail.title}</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody>
          <VStack>
            <Box justifyContent={'initial'} width={'100%'} my={4}>
              {modalDetail.description}
            </Box>
            <Box justifyContent={'initial'} width={'100%'} mt={6} mb={8}>
              <Text fontSize={'2xl'} mb={7}>
                {t('time')}: {formatSecondsToTimeString(duration)}
              </Text>
            </Box>

            <DurationSelector
              onChange={(duration: number) => setDuration(duration)}
              duration={duration}
              disableSelectors={isDefaultDurationChecked}
            />
          </VStack>
          {errorDetails && <RenderSetDurationError {...errorDetails} />}
        </ModalBody>

        <ModalFooter>
          <HStack
            w={'100%'}
            justifyContent={variant === DurationModalType.MediaDuration ? 'space-between' : 'end'}
          >
            {variant === DurationModalType.MediaDuration && (
              <Box>
                <Checkbox
                  checked={isDefaultDurationChecked}
                  onChange={(e) => {
                    if (e.target.checked) setDuration(playlist.defaultDuration);
                    setIsDefaultDurationChecked(e.target.checked);
                  }}
                >
                  {t('defaultDuration')}
                </Checkbox>
              </Box>
            )}
            <Button colorScheme="blue" onClick={handleSetDuration} isDisabled={!!errorDetails}>
              {modalDetail.submitButtonLabel}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

HandleDurationModal.graphql = {
  mutations: {
    SetMediaDuration: gql`
      mutation SetMediaDuration($input: MediaUpdateInput!) {
        mediaUpdate(input: $input) {
          id
        }
      }
    `,
  },
};
