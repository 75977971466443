import { t } from 'i18next';
import { TableInstance } from 'react-table';
import { PageSizeSelector } from '~components/ui/PageSizeSelector';
import { PaginationServerSide } from '~components/ui/PaginationServerSide';
import { DisplayTable_DisplayFragment } from './__generated__/DisplayTable.graphql';

export function DisplayTablePaginationServerSide({
  pageIndex,
  pageSize,
  totalRowCount,
  handleSetPageIndex,
}: {
  pageIndex: number;
  pageSize: number;
  totalRowCount: number;
  handleSetPageIndex: (pageIndex: number) => void;
}) {
  const pageCount = Math.ceil(totalRowCount / pageSize);
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;

  return (
    <PaginationServerSide
      pageCount={pageCount}
      currentPageIndex={pageIndex}
      hasPreviousPage={canPreviousPage}
      hasNextPage={canNextPage}
      goToPage={handleSetPageIndex}
      goToPreviousPage={() => handleSetPageIndex(pageIndex - 1)}
      goToNextPage={() => handleSetPageIndex(pageIndex + 1)}
    />
  );
}
export function DisplayTablePageSizeSelectorServerSide({
  sizes,
  handleSetPageSize,
  pageSize,
}: {
  table: TableInstance<DisplayTable_DisplayFragment>;
  sizes?: number[];
  pageSize: number;
  handleSetPageSize: (pageSize: number) => void;
}) {
  return (
    <PageSizeSelector
      label={t('displaysPerPage')}
      onChange={(pageSize) => handleSetPageSize(pageSize)}
      options={sizes}
      value={pageSize}
    />
  );
}
