import { gql } from '@apollo/client';
import { chakra, GridItem, HStack, Text } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Shield } from '~auth/Shield';
import { Avatar } from '~components/Avatar';
import { DisplaysIcon, StandbyIcon } from '~components/ui/icons';
import { AlertsIcon } from '~components/ui/icons/AlertsIcon';
import { NoSignalIcon } from '~components/ui/icons/NoSignal';
import { UsageIconBadge } from '~components/ui/icons/UsageIconBadge';
import { SubscriptionTag } from '~components/ui/SubscriptionTag';
import { useFeatureFlag } from '~utils/features';
import { getSubscriptionType, isWaveSubscription } from '~utils/subscriptions';
import { MaybePromise } from '~utils/types';
import { CustomersGridItem_CustomerFragment } from './__generated__/CustomersGridItem.graphql';
import { CustomersGridItemActions } from './CustomersGridItemActions';

interface Props {
  customer: CustomersGridItem_CustomerFragment;
  onSelect: (customer: CustomersGridItem_CustomerFragment) => MaybePromise<void>;
  onEdit: (customer: CustomersGridItem_CustomerFragment) => MaybePromise<void>;
  onDelete: (customer: CustomersGridItem_CustomerFragment) => MaybePromise<void>;
  onExport: (customer: CustomersGridItem_CustomerFragment) => MaybePromise<void>;
}

function CustomerUsageStatistics({
  disconnectedDisplays,
  unplannedStandbyDisplays,
  warnings,
}: {
  disconnectedDisplays: number;
  unplannedStandbyDisplays: number;
  warnings: number;
}) {
  return (
    <HStack flex="1" position="relative" spacing="5">
      <HStack position="relative">
        <UsageIconBadge
          bg="red.50"
          color="red.500"
          icon={NoSignalIcon}
          tooltip="Disconnected"
          paddingEnabled={false}
        />
        <chakra.span fontSize="sm" color="blue.900" fontWeight="bold">
          {disconnectedDisplays}
        </chakra.span>
      </HStack>
      <HStack position="relative">
        <UsageIconBadge
          bg="red.50"
          color="red.500"
          icon={StandbyIcon}
          tooltip="Unplanned standby"
        />
        <chakra.span fontSize="sm" color="blue.900" fontWeight="bold">
          {unplannedStandbyDisplays}
        </chakra.span>
      </HStack>
      <HStack position="relative">
        <UsageIconBadge
          bg="orange.50"
          color="orange.500"
          icon={AlertsIcon}
          tooltip="Warnings"
          paddingEnabled={false}
        />
        <chakra.span fontSize="sm" color="blue.900" fontWeight="bold">
          {warnings}
        </chakra.span>
      </HStack>
    </HStack>
  );
}

export function CustomersGridItem({ customer, onSelect, onEdit, onDelete, onExport }: Props) {
  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');
  const { t } = useTranslation();

  const [showActions, setShowActions] = useState(false);

  const handleSelect = async () => await onSelect(customer);
  const handleEdit = async () => await onEdit(customer);
  const handleDelete = async () => await onDelete(customer);
  const handleExport = async () => await onExport(customer);

  const handleMouseEnter = useCallback(() => setShowActions(true), []);
  const handleMouseLeave = useCallback(() => setShowActions(false), []);
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
      handleSelect();
    }
  };

  const subscriptionType = getSubscriptionType(customer.waveSubscription);
  const hasWaveSubscription = isWaveSubscription(customer.waveSubscription);
  const shouldShowStatistics = hasWaveSubscription;

  const usageStatistics = customer.displayUsage;
  const disconnectedDisplays = usageStatistics?.presence.disconnected ?? 0;
  const unplannedStandbyDisplays = usageStatistics?.powerState.unplannedStandBy ?? 0;
  const warnings = usageStatistics?.warnings;
  const warningsCount = warnings
    ? warnings.emptyShadowWarnings +
      warnings.recommendedSettingsWarnings +
      warnings.undefinedRecommendedSettingsWarnings +
      warnings.outOfSyncPowerScheduleWarnings +
      warnings.outOfSyncPlaylistWarnings +
      warnings.playlistSyncFailedWarnings +
      warnings.powerScheduleSyncFailedWarnings
    : 0;

  return (
    <GridItem
      display="flex"
      flexDirection="column"
      role="button"
      tabIndex={1}
      border="1px solid"
      borderRadius="base"
      borderColor="gray.50"
      background="white"
      padding="6"
      alignItems="stretch"
      gap="4"
      _hover={{
        cursor: 'pointer',
        borderColor: 'lightBlue.500',
      }}
      _focus={{
        borderColor: 'lightBlue.500',
      }}
      onClick={handleSelect}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <HStack flex="1" alignItems="flex-start" justifyContent="space-between" position="relative">
        <Avatar
          border="1px solid"
          borderRadius="sm"
          color="gray.50"
          __css={{ '> div': { color: 'white' } }}
          width="72px"
          height="72px"
          src={customer.avatarUrl ?? undefined}
          name={customer.name}
        />
        {showActions && (
          <Shield requiredPermissions={[Permission.CustomerDelete, Permission.CustomerUpdate]}>
            <CustomersGridItemActions
              onEdit={handleEdit}
              onDelete={handleDelete}
              onExport={handleExport}
            />
          </Shield>
        )}
      </HStack>
      <Text fontSize="lg" fontWeight="semibold" color="blue.900" marginTop="0" flex="1">
        {customer.name}
      </Text>
      {shouldShowStatistics && (
        <CustomerUsageStatistics
          disconnectedDisplays={disconnectedDisplays}
          unplannedStandbyDisplays={unplannedStandbyDisplays}
          warnings={warningsCount}
        />
      )}
      <HStack justifyContent="space-between">
        <HStack spacing="2" color="gray.500">
          <DisplaysIcon width="4" height="4" />
          <Text fontSize="sm">
            {customer.displayCount} {customer.displayCount === 1 ? t('display') : t('displays')}
          </Text>
        </HStack>
        {
          // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
        }
        {isSubscriptionsEnabled && <SubscriptionTag variant={subscriptionType} />}
      </HStack>
    </GridItem>
  );
}

CustomersGridItem.graphql = {
  fragments: {
    CustomersGridItem_customer: gql`
      fragment CustomersGridItem_customer on Customer {
        id
        handle
        name
        avatarUrl
        displayCount
        displayUsage {
          presence {
            disconnected
          }
          powerState {
            unplannedStandBy
          }
          warnings {
            emptyShadowWarnings
            recommendedSettingsWarnings
            undefinedRecommendedSettingsWarnings
            outOfSyncPowerScheduleWarnings
            outOfSyncPlaylistWarnings
            playlistSyncFailedWarnings
            powerScheduleSyncFailedWarnings
          }
        }
        waveSubscription {
          id
        }
      }
    `,
  },
};
