import { gql } from '@apollo/client';
import { HStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { PlaylistImageIcon } from '~components/ui/icons/PlaylistImageIcon';
import { PlaylistVideoIcon } from '~components/ui/icons/PlaylistVideoIcon';
import { PlaylistType } from '~graphql/__generated__/types';
import { PlaylistTable_PlaylistFragment } from '../__generated__/PlaylistTable.graphql';

export function PlaylistTypeHeader() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <span>{t('SUPPORTEDCONTENT')}</span>
    </div>
  );
}

export function PlaylistTypeCell({
  value,
}: PropsWithChildren<CellProps<PlaylistTable_PlaylistFragment, string>>) {
  const boxSize = '7';
  const icons =
    value === PlaylistType.Mixed
      ? [
          <PlaylistImageIcon boxSize={boxSize} key={'playlist image'} />,
          <PlaylistVideoIcon boxSize={boxSize} key={'playlist video'} />,
          // <PlaylistSoundIcon boxSize={boxSize} key={'playlist sound'} />,
        ]
      : [<PlaylistImageIcon boxSize={boxSize} key={'playlist image'} />];
  return (
    <HStack spacing="3" width={'100%'} justifyContent={'center'}>
      <HStack spacing="2" color={'gray.300'} mr={'16px'}>
        {icons}
      </HStack>
    </HStack>
  );
}

PlaylistTypeCell.graphql = {
  fragments: {
    PlaylistTypeCell_playlist: gql`
      fragment PlaylistTypeCell_playlist on Playlist {
        id
        playlistType
      }
    `,
  },
};
