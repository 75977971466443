import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { t } from 'i18next';
import { ChangeEventHandler } from 'react';
import { TableInstance } from 'react-table';
import { SearchIcon } from '~components/ui/icons';
import { UsersTableUserFragment } from './__generated__/UsersTable.graphql';
import { Columns } from './types';

type Props = {
  table: TableInstance<UsersTableUserFragment>;
};

export function UserTableFilter({ table }: Props) {
  const handleUserSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    table.setFilter(Columns.Name, event.target.value ?? '');
  };

  return (
    <InputGroup width="full">
      <InputLeftElement pointerEvents="none" height="full">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input type="search" placeholder={t('searchUsers')} onChange={handleUserSearch} height="12" />
    </InputGroup>
  );
}
