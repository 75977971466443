import { AlertTitle, Button, useToast } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoAlert } from '~components/ui/Alert';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import {
  AlertRebootScheduled_DisplayFragment,
  useAbortRebootJobMutation,
} from './__generated__/AlertRebootScheduled.graphql';

interface Props {
  display: AlertRebootScheduled_DisplayFragment;
}

export function AlertRebootScheduled({ display }: Props) {
  const rebootJob = useMemo(() => display.reboot?.latestJob, [display]);
  const { t } = useTranslation();
  const toast = useToast();
  const [abortJob] = useAbortRebootJobMutation();
  const abortReboot = useDestructiveAction<{ jobId: string }>({
    title: t('cancelReboot'),
    message: t('cancelRebootMsg'),
    confirmLabel: t('yes'),
    onConfirm: async ({ jobId }) => {
      try {
        await abortJob({
          variables: {
            input: {
              jobId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('abortRebootErr'),
          description: fromError(err, 'AbortReboot', {
            JOB_ALREADY_COMPLETED: t('rebootCompleted'),
            JOB_ALREADY_REJECTED: t('rebootFailed'),
            JOB_ALREADY_ABORTED: t('rebootAborted'),
            JOB_NOT_ABORTABLE: t('rebootNotAbortable'),
          }),
        });
      }
    },
  });

  if (
    rebootJob?.__typename !== 'RebootJobPlanned' &&
    rebootJob?.__typename !== 'RebootJobInProgress'
  )
    return null;

  const plannedAt = DateTime.fromISO(rebootJob?.plannedAt);
  return (
    <>
      <InfoAlert
        marginTop="10"
        actionButton={
          <Button
            variant="outline"
            size="sm"
            colorScheme="blue"
            color="blue.700"
            marginLeft="6"
            onClick={() => rebootJob && abortReboot.askConfirmation({ jobId: rebootJob.id })}
          >
            {t('cancelReboot')}
          </Button>
        }
      >
        <AlertTitle>
          {rebootJob?.__typename === 'RebootJobPlanned'
            ? t('rebootScheduled') +
              (isToday(plannedAt) ? t('today') : t('rebootFor') + plannedAt.toFormat('d MMM')) +
              t('at') +
              plannedAt.toFormat('HH:mm')
            : rebootJob?.__typename === 'RebootJobInProgress'
            ? t('rebooting')
            : undefined}
        </AlertTitle>
      </InfoAlert>
      {abortReboot.confirmationNode}
    </>
  );
}

function isToday(date: DateTime): boolean {
  return date.toISODate() === DateTime.local().toISODate();
}

AlertRebootScheduled.graphql = {
  fragments: {
    AlertRebootScheduled_display: gql`
      fragment AlertRebootScheduled_display on Display {
        id
        reboot {
          latestJob {
            __typename
            id
            plannedAt
          }
        }
      }
    `,
  },
  mutations: {
    AbortRebootJob: gql`
      mutation AbortRebootJob($input: DisplayAbortJobInput!) {
        displayAbortJob(input: $input) {
          display {
            id
            ...AlertRebootScheduled_display
          }
        }
      }
    `,
  },
};
