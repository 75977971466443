import { gql } from '@apollo/client';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { isEmpty, isNil } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { CustomerSubscriptions } from '~components/organization/CustomerSubscriptions';
import { CustomerSubscriptionsTableSubscriptionFragment } from '~components/organization/CustomerSubscriptionsTable/__generated__/CustomerSubscriptionsTable.graphql';
import { CustomerUsersTable } from '~components/organization/CustomerUsersTable';
import { CustomerUsersTableUserFragment } from '~components/organization/CustomerUsersTable/__generated__/CustomerUsersTable.graphql';
import { useCustomerUsersTable } from '~components/organization/CustomerUsersTable/useCustomerUsersTable';
import {
  customerBaseValidationSchema,
  customerErrorMap,
  toHandle,
} from '~components/organization/utils';
import { AvatarInput, FileUploadProps } from '~components/ui/AvatarInput';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import useGoBack from '~components/useGoBack';
import { useAnalyticsReporter } from '~utils/analytics';
import { getId } from '~utils/data';
import { fromError } from '~utils/errors';
import { useFeatureFlag } from '~utils/features';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure, isDefined } from '~utils/types';
import { useS3Upload } from '~utils/useS3Upload';
import {
  CustomerPageCustomerFragment,
  useCustomerPageQuery,
  useEditCustomerMutation,
} from './__generated__/[id].graphql';

const schema = z.object({
  id: z.string().uuid(),
  file: z.instanceof(File).optional(),
  uploadUrl: z.string().optional(),
  userIds: z.array(z.string()),
  subscriptionId: z.string().optional(),
  ...customerBaseValidationSchema,
});

type FormValues = z.infer<typeof schema>;

export function OrganizationCustomerEditPage() {
  const { customerId } = useParams();
  const toast = useToast();
  const [editCustomer] = useEditCustomerMutation();
  const { upload } = useS3Upload();
  const analytics = useAnalyticsReporter();
  const handleGoBack = useGoBack();
  const { verifyUserPermissions } = useAuth();

  const { data, loading, error, refetch } = useCustomerPageQuery({
    variables: {
      customerId: ensure(customerId),
    },
  });

  const updateForm = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      userIds: data?.customer?.users.map(getId) ?? [],
    },
  });

  const { isSubmitting, isDirty, isValid } = updateForm.formState;

  const canSubmit = !isSubmitting && isValid && isDirty;

  const handleUpdateCustomer = async ({
    id,
    name,
    handle,
    avatarUrl,
    file,
    uploadUrl,
    userIds,
    subscriptionId,
  }: FormValues) => {
    if (!isNil(uploadUrl) && !isNil(file)) {
      try {
        await upload(uploadUrl, file);
      } catch (error) {
        toast({
          status: 'error',
          title: t('uploadImageErr'),
          description: fromError(error, 'UploadFile'),
        });
        return;
      }
    }

    try {
      const { data } = await editCustomer({
        variables: {
          input: {
            customerId: id,
            handle,
            name,
            subscriptionId,
            avatarUrl: avatarUrl ?? null,
            ...(verifyUserPermissions([Permission.FeatureCustomerScopedAccess]) && { userIds }),
          },
        },
      });

      if (!isDefined(data)) {
        throw new Error('Update customer failed');
      }

      analytics.track('customerUpdateComplete');

      toast({
        status: 'success',
        title: t('customerSettingsUpdated'),
      });
      refetch();
    } catch (error) {
      toast({
        status: 'error',
        title: t('editCustomerErr'),
        description: fromError(error, 'EditCustomer', customerErrorMap),
      });
    }
  };

  const confirmSubscriptionUnlink = useDestructiveAction<FormValues>({
    title: t('removeCustomerSubscription'),
    message: (
      <>
        <Trans
          i18nKey="removeCustomerConfirmation"
          values={{ customerName: data?.customer?.name }}
          components={{ strong: <strong /> }}
        />{' '}
        <strong>{data?.customer?.waveSubscription?.name}</strong>?
      </>
    ),
    confirmLabel: t('confirmApplyChanges'),
    onConfirm: () => updateForm.handleSubmit(handleUpdateCustomer)(),
  });

  const confirmSubscriptionLink = useDestructiveAction<FormValues>({
    title: t('connectCustomerSubscription'),
    message: ({ subscriptionId }) => (
      <>
        <Trans
          i18nKey="connectCustomerConfirmation"
          values={{ customerName: data?.customer?.name }}
          components={{ strong: <strong /> }}
        />{' '}
        <strong>
          {data?.organization.waveSubscriptions?.find(({ id }) => id === subscriptionId)?.name}
        </strong>
        ?
      </>
    ),
    confirmLabel: t('confirmApplyChanges'),
    variant: 'warning',
    onConfirm: () => updateForm.handleSubmit(handleUpdateCustomer)(),
  });

  /**
   * Ask the user for confirmation (either destructive or warning) when changing the Customers' Subscription
   */
  const confirmOrUpdateCustomer = async (values: FormValues) => {
    if (isDefined(data?.customer?.waveSubscription?.id) && !isDefined(values.subscriptionId)) {
      await confirmSubscriptionUnlink.askConfirmation(values);
      return;
    }

    if (
      (!isDefined(data?.customer?.waveSubscription?.id) && isDefined(values.subscriptionId)) ||
      (isDefined(data?.customer?.waveSubscription?.id) &&
        data?.customer?.waveSubscription?.id !== values.subscriptionId)
    ) {
      await confirmSubscriptionLink.askConfirmation(values);
      return;
    }

    await handleUpdateCustomer(values);
  };

  // This page loads data asynchronously, so we need to "reset" the form when the data is available
  // to fill out the default values
  useEffect(() => {
    if (loading === false) {
      updateForm.reset({
        id: data?.customer?.id,
        name: data?.customer?.name,
        handle: data?.customer?.handle,
        avatarUrl: data?.customer?.avatarUrl ?? undefined,
        userIds: data?.customer?.users.map(getId) ?? [],
        subscriptionId: data?.customer?.waveSubscription?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once, when the page is loaded
  }, [loading]);

  useEffect(() => {
    analytics.track('customerUpdateStart');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run on initial mount
  }, []);

  return (
    <VerticalTabContent
      title={t('editCustomer')}
      isLoading={loading}
      error={error}
      onGoBack={handleGoBack}
      data={data?.customer}
      hasStickyHeader={true}
      action={{
        label: t('applyChanges'),
        disabled: !canSubmit,
        isLoading: isSubmitting,
        onClick: () => confirmOrUpdateCustomer(updateForm.getValues()),
      }}
    >
      {(customer) => (
        <>
          <EditCustomerForm
            customer={customer}
            users={data?.organization.users ?? []}
            subscriptions={data?.organization.waveSubscriptions ?? []}
            form={updateForm}
          />
          {confirmSubscriptionUnlink.confirmationNode}
          {confirmSubscriptionLink.confirmationNode}
        </>
      )}
    </VerticalTabContent>
  );
}

type EditCustomerFormProps = {
  customer: CustomerPageCustomerFragment;
  users: CustomerUsersTableUserFragment[];
  subscriptions: CustomerSubscriptionsTableSubscriptionFragment[];
  form: UseFormReturn<FormValues>;
};

function EditCustomerForm({ customer, users, subscriptions, form }: EditCustomerFormProps) {
  const [currentImage, setCurrentImage] = useState(customer.avatarUrl);

  const hasEssentialSubscription = isWaveSubscription(customer.waveSubscription);

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const customerUserIds = customer.users.map(getId);
  const table = useCustomerUsersTable(
    users,
    customerUserIds,
    isSubscriptionsEnabled ? hasEssentialSubscription : true,
  );

  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors, touchedFields, isSubmitSuccessful },
  } = form;

  const { ref: nameInputRef, onChange: onNameChange, ...nameInputProps } = register('name');
  const currentSubscriptionId = watch('subscriptionId');
  const currentHandle = watch('handle');
  const currentName = watch('name');

  const handleAvatarInputChange = (uploadData: FileUploadProps) => {
    if (isNil(uploadData.file)) {
      setCurrentImage(undefined);
      setValue('file', undefined, { shouldDirty: true });
    } else {
      setValue('file', uploadData.file, { shouldDirty: true });
    }

    setValue('avatarUrl', uploadData.uploadPayload?.assetUrl);
    setValue('uploadUrl', uploadData.uploadPayload?.uploadUrl);

    clearErrors('avatarUrl');
  };

  const handleAvatarInputError = (error: Error) => {
    setError('avatarUrl', { message: error.message });
  };

  const handleCustomerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onNameChange(event);

    if (!touchedFields.handle) {
      setValue('handle', toHandle(event.target.value));
    }
  };

  const handleSubscriptionIdChange = (subscriptionId: string | undefined) => {
    setValue('subscriptionId', subscriptionId, {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  // Create the handle from the name if the field hasn't been touched
  useEffect(() => {
    const handle = getValues('handle');
    const generatedHandle = toHandle(getValues('name'));

    if (handle !== generatedHandle) {
      setValue('handle', handle, { shouldTouch: true });
    }
  }, [getValues, setValue]);

  // When user selection changes, update the form data
  useEffect(() => {
    const checkedUserIds = Object.keys(table.state.selectedRowIds).sort();
    const sortedCustomerUserIds = customerUserIds.sort();

    const hasNumberOfUsersChanged = checkedUserIds.length !== sortedCustomerUserIds.length;
    const isEveryCustomerUserStillChecked = sortedCustomerUserIds.every((id) =>
      checkedUserIds.includes(id),
    );

    const selectionChangeUpdatedData = hasNumberOfUsersChanged || !isEveryCustomerUserStillChecked;

    setValue('userIds', checkedUserIds, {
      shouldTouch: true,
      shouldDirty: selectionChangeUpdatedData,
    });
  }, [setValue, customerUserIds, table.state.selectedRowIds]);

  useEffect(() => {
    if (
      currentSubscriptionId === customer.waveSubscription?.id &&
      currentHandle === customer.handle &&
      currentName === customer.name
    ) {
      // Un-dirtying needed to disable while reset to default subscription
      reset(undefined, { keepValues: true, keepDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `table` changes are of no interest
  }, [currentSubscriptionId, currentHandle, currentName]);

  // Reset the form after as successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      // Un-dirtying needed to disable the submit button again
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `table` changes are of no interest
  }, [isSubmitSuccessful, reset]);

  return (
    <form>
      <VStack alignItems="flex-start" spacing={10}>
        <FormControl isInvalid={Boolean(errors.avatarUrl)}>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('customerLogo')}
          </Heading>

          <AvatarInput
            onChange={handleAvatarInputChange}
            onError={handleAvatarInputError}
            avatar={currentImage ?? undefined}
          />
          <FormErrorMessage>{errors.avatarUrl?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('customerDetails')}
          </Heading>

          <FormControl isInvalid={Boolean(errors.name)} maxWidth="420" mb="8">
            <FormLabel marginRight="0">{t('customerName')}</FormLabel>
            <Input ref={nameInputRef} onChange={handleCustomerNameChange} {...nameInputProps} />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.handle)} maxWidth="420">
            <FormLabel marginRight="0">{t('customerHandle')}</FormLabel>
            <Input {...register('handle')} />
            <FormErrorMessage>{errors.handle?.message}</FormErrorMessage>
          </FormControl>
        </FormControl>

        <Shield requiredPermissions={[Permission.FeatureCustomerScopedAccess]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              {t('users')}
            </Heading>
            {isNil(users) || isEmpty(users) ? (
              <>{t('noUsers')}</>
            ) : (
              <CustomerUsersTable
                table={table}
                isDisabled={isSubscriptionsEnabled ? !hasEssentialSubscription : false}
              />
            )}
          </FormControl>
        </Shield>

        <Shield requiredPermissions={[Permission.WaveSubscriptionUpdate]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              {t('subscriptions')}
            </Heading>
            <CustomerSubscriptions
              subscriptions={subscriptions}
              customerSubscriptionId={customer.waveSubscription?.id}
              currentSubscriptionId={currentSubscriptionId}
              displayCount={customer.displayCount}
              onChange={handleSubscriptionIdChange}
            />
          </FormControl>
        </Shield>
      </VStack>
    </form>
  );
}

OrganizationCustomerEditPage.graphql = {
  fragments: {
    CustomerPageCustomer: gql`
      fragment CustomerPageCustomer on Customer {
        id
        handle
        name
        avatarUrl
        displayCount
        waveSubscription {
          id
          name
        }
        users {
          id
        }
      }
    `,
  },
  queries: {
    CustomerPage: gql`
      query CustomerPage($customerId: ID!) {
        customer(id: $customerId) {
          ...CustomerPageCustomer
        }
        organization {
          users {
            ...CustomerUsersTableUser
          }
          waveSubscriptions(isAssignable: true) {
            ...CustomerSubscriptionsTableSubscription
          }
        }
      }
    `,
  },
  mutations: {
    EditCustomer: gql`
      mutation EditCustomer($input: CustomerUpdateInput!) {
        customerUpdate(input: $input) {
          id
          handle
          name
          avatarUrl
          waveSubscription {
            id
            name
          }
        }
      }
    `,
  },
};
