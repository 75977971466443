import { gql } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { z } from 'zod';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, selectOptionBaseSchema } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { ManagePowerScheduleSelectOption } from '../ManagePowerScheduleModal';
import { useBulkManageUpdatePowerScheduleMutation } from './__generated__/BulkManagePowerSchedule.graphql';
import { BulkActionComponent } from './BulkActionModal';

const powerScheduleOption = selectOptionBaseSchema;
type PowerScheduleOption = z.TypeOf<typeof powerScheduleOption>;

const schema = z.object({
  displayIds: z.array(z.string()),
  powerSchedule: powerScheduleOption.nullish(),
});
type FormValues = z.TypeOf<typeof schema>;

export const BulkManagePowerSchedule: BulkActionComponent = ({
  displays,
  customer,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const [bulkUpdatePowerSchedule] = useBulkManageUpdatePowerScheduleMutation();
  const options = useMemo<PowerScheduleOption[]>(
    () =>
      customer.powerSchedules.map<PowerScheduleOption>((p) => ({
        label: p.title,
        value: p.id,
      })),
    [customer],
  );
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      powerSchedule: null,
      displayIds: displays.map((d) => d.id),
    },
    resolver: zodResolver(schema),
  });

  const toast = useToast();
  const analytics = useAnalyticsReporter();

  const handleBulkManagePowerSchedule = useCallback(
    async ({ displayIds, powerSchedule }: FormValues) => {
      try {
        await bulkUpdatePowerSchedule({
          variables: {
            input: {
              displayIds,
              powerScheduleId: powerSchedule?.value,
            },
          },
        });

        if (displayIds.length === 1) {
          analytics.track('scheduleUpdate');
        } else {
          analytics.track('displayBulkActionComplete', {
            action: 'updatePowerSchedule',
            displayCount: displayIds.length,
          });
        }

        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: t('bulkPowerScheduleUpdateError'),
          description: fromError(err, 'bulkUpdatePowerSchedule'),
        });
      }
    },
    [analytics, bulkUpdatePowerSchedule, onSuccess, toast, t],
  );

  return (
    <>
      <ModalContent>
        <ModalHeader>{t('powerScheduleManage')}</ModalHeader>
        <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
        <form onSubmit={handleSubmit(handleBulkManagePowerSchedule)}>
          <ModalBody>
            <Stack spacing="4">
              <InfoAlert>
                <Trans i18nKey="syncPowerScheduleWarn" components={{ strong: <strong /> }} />
              </InfoAlert>
              <FormControl isInvalid={Boolean(errors.powerSchedule)}>
                <FormLabel>{t('powerSchedule')}</FormLabel>
                <Controller
                  name="powerSchedule"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={options}
                      components={{
                        ...components,
                        Option: ManagePowerScheduleSelectOption,
                      }}
                      onChange={field.onChange}
                      isLoading={isSubmitting}
                      placeholder={t('clearPowerSchedule')}
                      isMulti={false}
                      escapeClearsValue={false}
                      isClearable={true}
                      isSearchable={true}
                      menuPlacement="auto"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="powerSchedule"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={onCancel}
                  isDisabled={isSubmitting}
                >
                  {t('cancel')}
                </Button>
                <Button
                  marginLeft="3"
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {t('apply')}
                </Button>
              </Box>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};

BulkManagePowerSchedule.graphql = {
  fragments: {
    BulkManagePowerSchedule_customer: gql`
      fragment BulkManagePowerSchedule_customer on Customer {
        id
        powerSchedules {
          id
          title
        }
      }
    `,
  },
  mutations: {
    BulkManagePowerSchedule: gql`
      mutation BulkManageUpdatePowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
        displayBulkUpdatePowerSchedule(input: $input) {
          displays {
            id
            powerSchedule {
              schedule {
                id
                title
              }
            }
          }
        }
      }
    `,
  },
};
