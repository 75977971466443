import { gql } from '@apollo/client';
import { Box, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { RevokeIcon } from '~components/ui/icons';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';
import { useDeleteMultiClaimCodeMutation } from './__generated__/RevokeCell.graphql';

export function RevokeCell({
  value,
}: PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment>>) {
  const [deleteMultiClaimCode] = useDeleteMultiClaimCodeMutation();
  const toast = useToast();

  const removeAction = useDestructiveAction<ClaimCodeTable_ClaimCodeFragment>({
    title: 'Remove claim code',
    message: (claimCode) => (
      <>
        {t('areYouSureToDelete')} <strong>{claimCode.name}</strong>?
      </>
    ),
    confirmLabel: 'Delete',
    onConfirm: async (claimCode) => {
      if (!claimCode) return;

      try {
        await deleteMultiClaimCode({
          variables: {
            input: {
              id: claimCode.id,
            },
          },
          update: (cache) => {
            // Evict the deleted claim code from the cache
            cache.evict({
              id: cache.identify({
                __typename: 'MultiClaimCodeDocument',
                id: claimCode.id,
              }),
            });
            // Optionally clean up any dangling references
            cache.gc();
          },
        });

        toast({
          status: 'success',
          title: t('claimCodeRemoved'),
          description: t('claimCodeRemovedMsg', { claimCode: claimCode.name }),
        });
      } catch (err) {
        console.error(err);
        toast({
          status: 'error',
          title: t('unexpectedError'),
          description: t('claimCodeRemoveErr'),
        });
      }
    },
  });

  const handleRemove = (claimCode: ClaimCodeTable_ClaimCodeFragment) => async () => {
    await removeAction.askConfirmation(claimCode);
  };

  return (
    <Box ml={3} opacity={value.state === 'EXPIRED' ? 0.4 : 1}>
      <RevokeIcon
        width="actionIconSize"
        height="actionIconSize"
        onClick={(e) => {
          e.stopPropagation();
          if (value.state !== 'EXPIRED') {
            handleRemove(value)();
          }
        }}
      />
      {removeAction.confirmationNode}
    </Box>
  );
}

RevokeCell.graphql = {
  mutations: {
    deleteMultiClaimCode: gql`
      mutation DeleteMultiClaimCode($input: DeleteMultiClaimCodeInput!) {
        deleteMultiClaimCode(input: $input)
      }
    `,
  },
  fragments: {
    RevokeCell_claimCode: gql`
      fragment RevokeCell_claimCode on MultiClaimCodeDocument {
        id
        name
      }
    `,
  },
};
