import { GridItem, Stack } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { DisplayProperty } from '~components/displays/DisplayDetail/connectivity/ConnectivitySection';
import { getPlatformLabel } from '~components/displays/utils';
import { DisplayInfoSection_DisplayFragment } from './__generated__/DisplayInfoSection.graphql';
import { DisplayFirmwareUpdate } from './DisplayFirmwareUpdate';

interface Props {
  display: DisplayInfoSection_DisplayFragment;
}

export function DisplayInfoSection({ display }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <GridItem colSpan={{ base: 8, md: 7 }} mb="10">
        <DisplayFirmwareUpdate display={display} />
      </GridItem>
      <GridItem colSpan={{ base: 4, md: 3 }}>
        <Stack direction="column" spacing="5">
          <DisplayProperty label={t('firmware')} value={display.firmware?.android.version} />
          <DisplayProperty label={t('platform')} value={getPlatformLabel(display.platform)} />
          <DisplayProperty label={t('serialNumber')} value={display.serialNumber} />
        </Stack>
      </GridItem>
      <GridItem colSpan={{ base: 4, md: 3 }}>
        <Stack direction="column" spacing="5">
          <DisplayProperty label={t('waveApp')} value={display.agentVersion || 'Unknown'} />
          <DisplayProperty label={t('modelNumber')} value={display.commercialTypeNumber} />
        </Stack>
      </GridItem>
    </>
  );
}

DisplayInfoSection.graphql = {
  fragments: {
    DisplayInfoSection_display: gql`
      fragment DisplayInfoSection_display on Display {
        id
        commercialTypeNumber
        serialNumber
        platform {
          name
          version
          type
        }
        firmware {
          android {
            version
          }
        }
        agentVersion
        ...DisplayFirmwareUpdate_display
      }
    `,
  },
};
