import { t } from 'i18next';
import { TableInstance } from 'react-table';
import { PageSizeSelector } from '~components/ui/PageSizeSelector';
import { Pagination } from '~components/ui/Pagination';
import { UsersTableUserFragment } from './__generated__/UsersTable.graphql';

export function UsersTablePagination({ table }: { table: TableInstance<UsersTableUserFragment> }) {
  const {
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    state: { pageIndex },
    pageCount,
  } = table;

  return (
    <Pagination
      pageCount={pageCount}
      currentPageIndex={pageIndex}
      hasPreviousPage={canPreviousPage}
      hasNextPage={canNextPage}
      goToPage={gotoPage}
      goToPreviousPage={previousPage}
      goToNextPage={nextPage}
    />
  );
}

export function UsersTablePageSizeSelector({
  table,
  sizes,
}: {
  table: TableInstance<UsersTableUserFragment>;
  sizes?: number[];
}) {
  const {
    state: { pageSize },
    setPageSize,
  } = table;

  return (
    <PageSizeSelector
      label={t('usersPerPage')}
      onChange={setPageSize}
      options={sizes}
      value={pageSize}
    />
  );
}
