import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as RawApolloProvider,
  HttpLink,
  InMemoryCache,
  Reference,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { t } from 'i18next';
import { chain, groupBy, isNil } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '~auth/useAuthentication';
import { TypedTypePolicies } from '~graphql/__generated__/apollo-helpers';
import {
  ApiKeyRevokePayload,
  Customer,
  CustomerDeletePayload,
  Display,
  DisplayAlertBulkResolvePayload,
  Organization,
  Playlist,
  PlaylistsDeletePayload,
  PowerSchedule,
  SiteDeletePayload,
  UserDeletePayload,
} from '~graphql/__generated__/types';

interface Props {
  uri: string;
  children: ReactNode;
}

function ApolloProvider({ uri, children }: Props) {
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();
  const toast = useToast();
  const navigate = useNavigate();

  const authLink = useMemo(() => {
    return setContext(async () => {
      if (!isAuthenticated) {
        return {};
      }

      const token = await getAccessTokenSilently();

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  const errorLink = onError(({ graphQLErrors }) => {
    const hasUnauthenticatedError = graphQLErrors?.some((err) => err.message === 'UNAUTHORIZED');

    if (hasUnauthenticatedError) {
      if (window.location.pathname === '/') {
        navigate('/403', { replace: true });
      }

      toast({
        status: 'error',
        title: t('notAuthorized'),
      });
    }
  });

  const httpLink = useMemo(() => {
    return new HttpLink({
      uri,
    });
  }, [uri]);

  const retryLink = useMemo(() => {
    return new RetryLink();
  }, []);

  const sentryLink = useMemo(() => {
    return new SentryLink({
      uri: process.env.REACT_APP_API_URI,
      setTransaction: true,
      setFingerprint: true,
      attachBreadcrumbs: {
        includeQuery: true,
        includeFetchResult: false,
        includeError: true,
        includeCache: false,
        transform: undefined,
      },
    });
  }, []);

  const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const headers = context.response.headers;

      const AmznTraceId: string = headers.get('X-Amzn-Trace-Id');

      Sentry.setContext('AWS', {
        traceId: AmznTraceId,
      });

      return response;
    });
  });

  const policies = useMemo<TypedTypePolicies>(
    () => ({
      Query: {
        fields: {
          customer: {
            read(_, { args, toReference }) {
              return toReference({ __typename: 'Customer', id: args?.id });
            },
          },
        },
      },
      Organization: {
        fields: {
          users: {
            merge(__: unknown, incoming: Organization['users']) {
              return incoming;
            },
          },
          displayAlerts: {
            merge(__: unknown, incoming: Organization['displayAlerts']) {
              return incoming;
            },
          },
        },
      },
      Customer: {
        fields: {
          displays: {
            merge(_existing: Customer['displays'], incomming: Customer['displays']) {
              return incomming;
            },
          },
          groups: {
            read(existing: Reference[], { readField }) {
              const existingByRef = groupBy(existing, (e) => e.__ref);
              return chain(existing)
                .defaultTo([])
                .map((ref) => ({
                  __ref: ref.__ref,
                  name: readField<string>('name', ref),
                }))
                .sortBy((i) => i.name?.toLowerCase())
                .map((i) => existingByRef[i.__ref][0])
                .value();
            },
            merge(__: unknown, incoming: Customer['groups']) {
              return incoming;
            },
          },
          sites: {
            read(existing: Reference[], { readField }) {
              const existingByRef = groupBy(existing, (e) => e.__ref);
              return chain(existing)
                .defaultTo([])
                .map((ref) => ({
                  __ref: ref.__ref,
                  name: readField<string>('name', ref),
                }))
                .sortBy((i) => i.name?.toLowerCase())
                .map((i) => existingByRef[i.__ref][0])
                .value();
            },
          },
          playlists: {
            read(existing: Reference[], { readField }) {
              const existingByRef = groupBy(existing, (e) => e.__ref);
              return chain(existing)
                .defaultTo([])
                .map((ref) => ({
                  __ref: ref.__ref,
                  name: readField<string>('name', ref),
                }))
                .sortBy((i) => i.name?.toLowerCase())
                .map((i) => existingByRef[i.__ref][0])
                .value();
            },
          },
        },
      },
      Display: {
        fields: {
          presence: {
            merge: simpleCacheObjectTypeMerge,
          },
          groups: {
            read(existing: Reference[], { readField }) {
              const existingByRef = groupBy(existing, (e) => e.__ref);
              return chain(existing)
                .defaultTo([])
                .map((ref) => ({
                  __ref: ref.__ref,
                  name: readField<string>('name', ref),
                }))
                .sortBy((i) => i.name?.toLowerCase())
                .map((i) => existingByRef[i.__ref][0])
                .value();
            },
            merge(__: unknown, incoming: Display['groups']) {
              return incoming;
            },
          },
          platform: {
            merge: simpleCacheObjectTypeMerge,
          },
          power: {
            merge: simpleCacheObjectTypeMerge,
          },
          recommendedSettings: {
            merge: simpleCacheObjectTypeMerge,
          },
          signalDetection: {
            merge: simpleCacheObjectTypeMerge,
          },
          volume: {
            merge: simpleCacheObjectTypeMerge,
          },
          screenshot: {
            merge: simpleCacheObjectTypeMerge,
          },
          playlist: {
            merge: simpleCacheObjectTypeMerge,
          },
          contentSource: {
            merge(__: unknown, incoming: Display['contentSource']) {
              return incoming;
            },
          },
          appSubscriptions: {
            merge() {
              return undefined;
            },
            read() {
              return undefined;
            },
          },
        },
      },
      PowerSchedule: {
        fields: {
          timeBlocks: {
            merge(__: unknown, incoming: PowerSchedule['timeBlocks']) {
              return incoming;
            },
          },
        },
      },
      Playlist: {
        fields: {
          media: {
            merge(__: unknown, incoming: Playlist['media']) {
              return incoming;
            },
          },
          displays: {
            merge(__: unknown, incoming: Playlist['displays']) {
              return incoming;
            },
          },
        },
      },
      Mutation: {
        fields: {
          siteDelete: {
            merge(__: unknown, incoming: SiteDeletePayload, { cache }) {
              cache.evict({
                id: cache.identify({
                  __typename: 'Site',
                  id: incoming.siteId,
                }),
              });
            },
          },

          userDelete: {
            merge(__: unknown, incoming: UserDeletePayload, { cache }) {
              cache.evict({
                id: cache.identify({
                  __typename: 'User',
                  id: incoming.userId,
                }),
              });
            },
          },
          apiKeyRevoke: {
            merge(__: unknown, incoming: ApiKeyRevokePayload, { cache }) {
              cache.evict({
                id: cache.identify({
                  __typename: 'ApiKey',
                  id: incoming.apiKeyId,
                }),
              });
            },
          },
          customerDelete: {
            merge(__: unknown, incoming: CustomerDeletePayload, { cache }) {
              cache.evict({
                id: cache.identify({
                  __typename: 'Customer',
                  id: incoming.customerId,
                }),
              });
            },
          },
          playlistsDelete: {
            merge(__: unknown, incoming: PlaylistsDeletePayload, { cache }) {
              for (const id of incoming.playlistIds) {
                cache.evict({
                  id: cache.identify({
                    __typename: 'Playlist',
                    id,
                  }),
                });
              }
            },
          },
          displayAlertBulkResolve: {
            merge(__: unknown, incoming: DisplayAlertBulkResolvePayload, { cache }) {
              for (const id of incoming.displayAlertIds) {
                cache.evict({
                  id: cache.identify({
                    __typename: 'DisplayAlert',
                    id,
                  }),
                });
              }
            },
          },
        },
      },
    }),
    [],
  );

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: policies,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-first',
        },
      },
      link: authLink
        .concat(sentryLink)
        .concat(errorLink)
        .concat(retryLink)
        .concat(responseLink)
        .concat(httpLink),
      connectToDevTools: process.env.REACT_APP_DEPLOY_ENVIRONMENT !== 'production',
    });
    // ErrorLink is excluded from the dependencies to prevent re-rendering.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLink, retryLink, httpLink, policies, sentryLink]);

  return <RawApolloProvider client={client}>{children}</RawApolloProvider>;
}

/**
 * A simple object type merge for Apollo Cache.
 * Mainly used for object types that don't have an id.
 */
function simpleCacheObjectTypeMerge(
  existing?: Record<string, unknown>,
  incoming?: Record<string, unknown>,
) {
  if (isNil(incoming)) {
    return null;
  }
  if (isNil(existing)) {
    return incoming;
  }
  return chain(existing).cloneDeep().merge(incoming).value();
}

export default ApolloProvider;
