import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useAuth } from '~auth/useAuth';
import { EmptyView, EmptyViewButton } from '~components/EmptyView';
import { EmptyPlaylistIllustration } from '~components/ui/illustrations';

export function EmptyPlaylists({ onCreate }: { onCreate: () => void }) {
  const { verifyUserPermissions } = useAuth();

  const hasPlaylistCreatePermission = verifyUserPermissions([Permission.PlaylistCreate]);

  return (
    <EmptyView icon={<EmptyPlaylistIllustration />} title={t('createPlayList')}>
      <EmptyViewButton
        onClick={onCreate}
        label={t('createPlayListLabel')}
        isDisabled={!hasPlaylistCreatePermission}
      />
    </EmptyView>
  );
}
