import { ComponentWithAs } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import gql from 'graphql-tag';
import { t } from 'i18next';
import { cloneDeep, merge } from 'lodash';
import { useCallback, useMemo } from 'react';
import { IconProps, SuccessIcon, WarningIcon } from '~components/ui/icons';
import { ThemeTypings } from '~components/ui/styles/types';
import { SettingWarningSeverity } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { SingleOrArray } from '~utils/types';
import {
  ApplyRecommendedMutation,
  useApplyRecommendedMutation,
  UseRecommendedSettings_DisplayFragment,
  UseRecommendedSettings_WarningFragment,
} from './__generated__/useRecommendedSettings.graphql';

export interface RecommendedSettingsWarning {
  code: UseRecommendedSettings_WarningFragment['code'];
  severity: UseRecommendedSettings_WarningFragment['severity'];
  message: string;
}

export interface RecommendedSettingsStyles {
  colorScheme: ThemeTypings['colorSchemes'];
  Icon: ComponentWithAs<'svg', IconProps>;
}

export type RecommendedSettingsState =
  | {
      kind: 'not_reported';
      label: string;
      styles: RecommendedSettingsStyles;
    }
  | {
      kind: 'not_recommended';
      isApplyingRecommended: boolean;
      label: string;
      warnings: RecommendedSettingsWarning[];
      highestSeverity: RecommendedSettingsWarning['severity'];
      styles: RecommendedSettingsStyles;
    }
  | {
      kind: 'recommended';
      label: string;
      styles: RecommendedSettingsStyles;
    };

/**
 * Hook to help transform recommended settings from remote to UI building blocks.
 * If you wish to use this hook ensure the 'UseRecommendedSettings_display' fragment is included in the query.
 */
export function useRecommendedSettings() {
  const toast = useToast();
  const [applyRecommendedMutation] = useApplyRecommendedMutation();

  const getSettingsState = useCallback(
    (display: UseRecommendedSettings_DisplayFragment): RecommendedSettingsState => {
      if (!display.recommendedSettings) {
        return {
          kind: 'not_reported',
          label: getLabel({ kind: 'not_reported' }),
          styles: getStyles({ kind: 'not_reported' }),
        };
      }

      if (display.recommendedSettings.reported.recommended) {
        return {
          kind: 'recommended',
          label: getLabel({ kind: 'recommended' }),
          styles: getStyles({ kind: 'recommended' }),
        };
      }

      return {
        kind: 'not_recommended',
        label: getLabel({ kind: 'not_recommended' }),
        styles: getStyles({ kind: 'not_recommended' }),
        warnings: display.recommendedSettings.reported.warnings
          .slice()
          .sort(sortByWarningSeverityDesc)
          .map((w) => toWarning(w)),
        highestSeverity: getHighestWarningSeverity(display.recommendedSettings.reported.warnings),
        isApplyingRecommended: Boolean(display.recommendedSettings.desired?.recommended),
      };
    },
    [],
  );

  const sortBySettings = useCallback(
    (a: UseRecommendedSettings_DisplayFragment, b: UseRecommendedSettings_DisplayFragment) => {
      const settingsA = getSettingsState(a);
      const settingsB = getSettingsState(b);

      const settingsKindSort =
        SETTINGS_KIND_ORDER_DESC.indexOf(settingsA.kind) -
        SETTINGS_KIND_ORDER_DESC.indexOf(settingsB.kind);

      if (settingsKindSort !== 0) return settingsKindSort;

      const highestSeverityA =
        'warnings' in settingsA ? settingsA.highestSeverity : SettingWarningSeverity.Low;
      const highestSeverityB =
        'warnings' in settingsB ? settingsB.highestSeverity : SettingWarningSeverity.Low;

      return (
        WARNING_SEVERITY_ORDER_DESC.indexOf(highestSeverityA) -
        WARNING_SEVERITY_ORDER_DESC.indexOf(highestSeverityB)
      );
    },
    [getSettingsState],
  );

  const applyRecommended = useCallback(
    async (displays: SingleOrArray<UseRecommendedSettings_DisplayFragment>) => {
      const displaysArray = Array.isArray(displays) ? displays : [displays];
      const displayIds = displaysArray.map((display) => display.id);

      try {
        await applyRecommendedMutation({
          variables: {
            input: {
              displayIds,
            },
          },
          optimisticResponse: buildOptimisticResponse(displaysArray),
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('applyRecommendedSettingsErr'),
          description: fromError(err, 'ApplyRecommended'),
        });
      }
    },
    [applyRecommendedMutation, toast],
  );

  return useMemo(
    () => ({
      getSettingsState,
      sortBySettings,
      applyRecommended,
    }),
    [getSettingsState, sortBySettings, applyRecommended],
  );
}

const SETTINGS_KIND_ORDER_DESC: Array<RecommendedSettingsState['kind']> = [
  'not_recommended',
  'not_reported',
  'recommended',
];

function getHighestWarningSeverity(warnings: UseRecommendedSettings_WarningFragment[]) {
  if (!warnings.length) {
    return SettingWarningSeverity.Low;
  }

  const sorted = warnings.slice().sort(sortByWarningSeverityDesc);
  return sorted[0].severity;
}

const WARNING_SEVERITY_ORDER_DESC = [
  SettingWarningSeverity.High,
  SettingWarningSeverity.Moderate,
  SettingWarningSeverity.Low,
] as const;

export function sortByWarningSeverityDesc(
  a: UseRecommendedSettings_WarningFragment,
  b: UseRecommendedSettings_WarningFragment,
) {
  return (
    WARNING_SEVERITY_ORDER_DESC.indexOf(a.severity) -
    WARNING_SEVERITY_ORDER_DESC.indexOf(b.severity)
  );
}

function toWarning({
  code,
  severity,
}: UseRecommendedSettings_WarningFragment): RecommendedSettingsWarning {
  return {
    code,
    severity,
    message: getWarningMessage(code),
  };
}

function getWarningMessage(code: UseRecommendedSettings_WarningFragment['code']): string {
  switch (code) {
    case 'POWER_MODE_1_NOT_RECOMMENDED':
    case 'POWER_MODE_2_NOT_RECOMMENDED':
      return 'The display should be using Power Mode 3 or 4 to guarantee connectivity when the display goes to stand-by.';
    case 'APM_OFF_NOT_RECOMMENDED':
    case 'APM_MODE_1_NOT_RECOMMENDED':
      return 'The display should be using Advanced Power Management (APM) Mode 2 to guarantee connectivity when the display goes to stand-by.';
    case 'STAND_BY_MODE_GREEN_NOT_RECOMMENDED':
      return 'The display should be using stand-by-mode Fast to guarantee connectivity when the display goes to stand-by.';
    case 'LOGO_ON_BOOT_NOT_RECOMMENDED':
      return 'The display is set up to show the logo when it starts up. This will result in a significant increase of startup time. We recommend disabling this feature.';
    default:
      return 'The display should be using the correct power settings to guarantee connectivity when the display goes to stand-by.';
  }
}

function getStyles({
  kind,
}: {
  kind: RecommendedSettingsState['kind'];
}): RecommendedSettingsStyles {
  switch (kind) {
    case 'not_reported':
      return {
        colorScheme: 'yellow',
        Icon: WarningIcon,
      };
    case 'not_recommended':
      return {
        colorScheme: 'orange',
        Icon: WarningIcon,
      };
    case 'recommended':
      return {
        colorScheme: 'green',
        Icon: SuccessIcon,
      };
  }
}

function getLabel({ kind }: { kind: RecommendedSettingsState['kind'] }): string {
  switch (kind) {
    case 'not_reported':
      return 'Settings have not been reported.';
    case 'not_recommended':
      return 'Settings are not recommended.';
    case 'recommended':
      return 'Settings are recommended.';
  }
}

function buildOptimisticResponse(
  displays: UseRecommendedSettings_DisplayFragment[],
): ApplyRecommendedMutation {
  return {
    __typename: 'Mutation',
    displayBulkApplyRecommendedSettings: {
      __typename: 'DisplayBulkApplyRecommendedSettingsPayload',
      displays: displays.map((display) => ({
        __typename: 'Display',
        id: display.id,
        signalDetection: merge(cloneDeep(display.signalDetection)),
        recommendedSettings: merge(cloneDeep(display.recommendedSettings), {
          desired: { recommended: true },
        }),
      })),
    },
  };
}

useRecommendedSettings.graphql = {
  fragments: {
    UseRecommendedSettings_warning: gql`
      fragment UseRecommendedSettings_warning on SettingWarning {
        code
        severity
      }
    `,
    UseRecommendedSettings_recommendedSettings: gql`
      fragment UseRecommendedSettings_recommendedSettings on RecommendedSettings {
        recommended
        warnings {
          ...UseRecommendedSettings_warning
        }
      }
    `,
    UseRecommendedSettings_signalDetection: gql`
      fragment UseRecommendedSettings_signalDetection on Display {
        signalDetection {
          reported
          desired
        }
      }
    `,
    UseRecommendedSettings_display: gql`
      fragment UseRecommendedSettings_display on Display {
        id
        ...UseRecommendedSettings_signalDetection
        recommendedSettings {
          reported {
            ...UseRecommendedSettings_recommendedSettings
          }
          desired {
            ...UseRecommendedSettings_recommendedSettings
          }
        }
      }
    `,
  },
  mutations: {
    ApplyRecommended: gql`
      mutation ApplyRecommended($input: DisplayBulkApplyRecommendedSettingsInput!) {
        displayBulkApplyRecommendedSettings(input: $input) {
          displays {
            id
            ...UseRecommendedSettings_display
          }
        }
      }
    `,
  },
};
