import { gql } from '@apollo/client';
import { Box, Button, chakra, Link, Stack, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { useMemo } from 'react';
import { SubscriptionsTable } from '~components/organization/SubscriptionsTable/SubscriptionsTable';
import { useSubscriptionsTable } from '~components/organization/SubscriptionsTable/useSubscriptionsTable';
import UpgradeToWaveEssentialEmptyState from '~components/organization/UpgradeToWaveEssentialModal/EmptyState';
import { ExternalLinkIcon } from '~components/ui/icons';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { useFeatureFlag } from '~utils/features';
import { useOrganizationSubscriptionsIndexPageQuery } from './__generated__/Index.graphql';

const POLL_INTERVAL = 10000;

export function OrganizationSubscriptionsIndexPage() {
  const { isEnabled: isAppDirectMarketplaceLinkEnabled } = useFeatureFlag(
    'appDirectMarketplaceLink',
  );

  const { data, loading, error, startPolling, stopPolling } =
    useOrganizationSubscriptionsIndexPageQuery({
      pollInterval: POLL_INTERVAL,
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  const subscriptions = useMemo(
    () => data?.organization.waveSubscriptions ?? [],
    [data?.organization.waveSubscriptions],
  );

  const table = useSubscriptionsTable(subscriptions);

  return (
    <VerticalTabContent title={t('subscriptions')} isLoading={loading} error={error}>
      <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ sm: 6, md: 36 }} mb="10">
        <VStack alignItems="stretch" spacing={6}>
          <chakra.p fontSize="md" color="gray.500">
            {t('contactSupportSubscription')}{' '}
            <chakra.a
              href={process.env.REACT_APP_WAVE_DOCS_LINK ?? ''}
              target="_blank"
              rel="noopener noreferrer"
              textDecoration="underline"
            >
              <strong>{t('documentationPortal')}</strong>
            </chakra.a>{' '}
            {t('learnSubscriptions')}
          </chakra.p>
          <chakra.p fontSize="md" color="gray.500">
            {t('purchaseSubscriptions')}
          </chakra.p>
        </VStack>
        {isAppDirectMarketplaceLinkEnabled && (
          <Link
            href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="solid" colorScheme="blue">
              <Stack direction="row">
                <chakra.span textDecoration="none">{t('purchaseProStore')}</chakra.span>
                <ExternalLinkIcon />
              </Stack>
            </Button>
          </Link>
        )}
      </Stack>
      {subscriptions.length === 0 ? (
        <Box
          boxShadow="elevated"
          border="1px solid"
          borderRadius="md"
          borderColor="gray.100"
          overflowX="auto"
          background="white"
          paddingX="10"
        >
          <UpgradeToWaveEssentialEmptyState externalLink noSubscriptions />
        </Box>
      ) : (
        <>
          <Box
            boxShadow="elevated"
            border="1px solid"
            borderRadius="md"
            borderColor="gray.100"
            overflowX="auto"
            bgColor="white"
          >
            <SubscriptionsTable table={table} />
          </Box>
          <Box display="flex" marginTop="4">
            <Box flex="1">
              <SubscriptionsTable.PageSizeSelector table={table} />
            </Box>
            <SubscriptionsTable.Pagination table={table} />
          </Box>
        </>
      )}
    </VerticalTabContent>
  );
}

OrganizationSubscriptionsIndexPage.graphql = {
  queries: {
    OrganizationSubscriptionsIndexPage: gql`
      query OrganizationSubscriptionsIndexPage {
        organization {
          id
          waveSubscriptions {
            ...SubscriptionsTableSubscription
          }
        }
      }
    `,
  },
};
