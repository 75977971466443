import {
  Button,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tabs,
  useRadioGroup,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useInstallAppMutation } from '~components/displays/__generated__/useAppSubscriptions.graphql';
import { ContentItem } from '~components/displays/DisplayDetail/summary/ContentItem';
import { InstalledAppSubscription } from '~components/displays/useAppSubscriptions';
import { getSubscriptionVersion } from '~components/displays/utils';
import { BoxedRadio } from '~components/ui/BoxedRadio';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { AddContentModal_DisplayFragment } from './__generated__/AddContentModal.graphql';

const schema = z.object({
  displayId: z.string(),
  subscriptionId: z.string(),
  appVersion: z.string(),
});

type FormValues = z.TypeOf<typeof schema>;

export function UpdateAppModal({
  display,
  appSubscription,
  onClose,
}: {
  appSubscription: InstalledAppSubscription;
  display: AddContentModal_DisplayFragment;
  onClose: () => void;
}) {
  const toast = useToast();
  const [installAppMutation] = useInstallAppMutation();
  const { t } = useTranslation();
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  const currentVersion = getSubscriptionVersion(appSubscription);
  const options =
    appSubscription.appVersions
      ?.filter((version) => version !== currentVersion)
      .map((version) => ({
        label: version,
        value: version,
      })) ?? [];

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      subscriptionId: appSubscription.id,
      appVersion: appSubscription?.appVersions?.[0],
    },
    resolver: zodResolver(schema),
  });

  const { getRadioProps } = useRadioGroup({
    name: 'appVersion',
    defaultValue: appSubscription?.appVersions?.[0],
    onChange: (selectedVersion) => {
      setValue('appVersion', selectedVersion);
    },
  });

  const handleUpdateApp = async ({ displayId, subscriptionId, appVersion }: FormValues) => {
    try {
      await installAppMutation({
        variables: {
          input: {
            displayId,
            subscriptionId,
            appVersion,
          },
        },
      });

      await onClose?.();
    } catch (error) {
      const appSubscription = display.appSubscriptions.find(({ id }) => id === subscriptionId);
      const formattedName = appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

      toast({
        status: 'error',
        title: t('updateFailed', { formattedName: formattedName }),
        description: fromError(error, 'installAppMutation', {
          displayId,
          subscriptionId: subscriptionId,
        }),
      });
    }
  };

  return (
    <Modal size="2xl" initialFocusRef={initialFocusRef} isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('updateApp', { appName: appSubscription.name })}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <Tabs isLazy>
          <ModalBody paddingX="9" paddingY="6">
            <Flex flexDirection="column" gap="2">
              <chakra.span color="blue.800" fontWeight="bold">
                {t('availableVersions')}
              </chakra.span>
              {options.map(({ label, value }) => (
                <BoxedRadio key={value} {...getRadioProps({ value })} display="block">
                  <ContentItem title={label} mb="0" />
                </BoxedRadio>
              ))}
              <chakra.span color="blue.800" fontWeight="bold">
                {t('currentVersion')}
              </chakra.span>
              <chakra.div pointerEvents="none" cursor="not-allowed" opacity={0.5}>
                <BoxedRadio isDisabled={true} isChecked={true}>
                  <ContentItem title={currentVersion ?? ''} />
                </BoxedRadio>
              </chakra.div>
            </Flex>
          </ModalBody>
          <ModalFooter paddingTop="9">
            <Button variant="ghost" colorScheme="blue" onClick={onClose} isDisabled={isSubmitting}>
              {t('cancel')}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              onClick={handleSubmit(handleUpdateApp)}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t('update')}
            </Button>
          </ModalFooter>
        </Tabs>
      </ModalContent>
    </Modal>
  );
}
