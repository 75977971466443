import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { cloneDeep, isNil, merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrightnessIcon } from '~components/ui/icons/BrightnessIcon';
import { Slider } from '~components/ui/Slider';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplayBrightnessSlider_DisplayFragment,
  useBulkUpdateBrightnessMutation,
} from './__generated__/DisplayBrightnessSlider.graphql';

interface Props {
  display: DisplayBrightnessSlider_DisplayFragment;
  isDisabled?: boolean;
}

export function DisplayBrightnessSlider({ display, isDisabled = false }: Props) {
  const toast = useToast();
  const [brightnessLevel, setBrightnessLevel] = useState(0);
  const analytics = useAnalyticsReporter();
  const { t } = useTranslation();
  useEffect(() => {
    const newBrightnessLevel = display.brightness?.desired ?? display.brightness?.reported;

    if (!isNil(newBrightnessLevel)) {
      setBrightnessLevel(newBrightnessLevel);
    }
  }, [display.brightness?.desired, display.brightness?.reported]);

  const [bulkUpdateBrightness] = useBulkUpdateBrightnessMutation();

  const handleSliderChangeEnd = useCallback(
    async (value: number) => {
      try {
        await bulkUpdateBrightness({
          variables: {
            input: {
              displayIds: [display.id],
              brightness: value,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            displayBulkUpdateBrightness: {
              __typename: 'DisplayBulkUpdateBrightnessPayload',
              displays: [
                {
                  __typename: 'Display',
                  id: display.id,
                  brightness: merge(cloneDeep(display.brightness), { desired: value }),
                },
              ],
            },
          },
        });
        analytics.track('displaySettingUpdate', { group: 'playback', changeItem: 'brightness' });
      } catch (err) {
        toast({
          status: 'error',
          title: t('brightnessUpdateErr'),
          description: fromError(err, 'BulkUpdateBrightness', {
            BRIGHTNESS_OUT_OF_RANGE: t('brightnessUpdateErrDesc'),
          }),
        });
      }
    },
    [analytics, bulkUpdateBrightness, display.brightness, display.id, toast, t],
  );

  return (
    <Slider
      value={brightnessLevel}
      onChange={setBrightnessLevel}
      onChangeEnd={handleSliderChangeEnd}
      leftIcon={BrightnessIcon}
      min={0}
      max={100}
      isDisabled={isDisabled}
    />
  );
}

DisplayBrightnessSlider.graphql = {
  fragment: {
    DisplayBrightnessSlider_display: gql`
      fragment DisplayBrightnessSlider_display on Display {
        id
        brightness {
          reported
          desired
        }
      }
    `,
  },
  mutations: {
    BulkUpdateBrightness: gql`
      mutation BulkUpdateBrightness($input: DisplayBulkUpdateBrightnessInput!) {
        displayBulkUpdateBrightness(input: $input) {
          displays {
            id
            ...DisplayBrightnessSlider_display
          }
        }
      }
    `,
  },
};
