import { t } from 'i18next';
import { z } from 'zod';

export function toHandle(name: string): string {
  return name
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/[^a-zA-Z0-9-]/g, '');
}

const userBaseValidationSchema = {
  givenName: z.string().nonempty(t('requiredField')).min(2, t('firstNameValidate')),
  familyName: z.string().nonempty(t('requiredField')).min(2, t('lastNameValidate')),
};

export const userCreateValidationSchema = {
  ...userBaseValidationSchema,
  email: z
    .string()
    .nonempty(t('requiredField'))
    .email({ message: t('invalidEmail') }),
};

export const userEditValidationSchema = {
  ...userBaseValidationSchema,
};

export const customerBaseValidationSchema = {
  name: z
    .string()
    .nonempty(t('requiredField'))
    .min(1, t('nameLengthWarn'))
    .regex(/.*\S.*/, t('whiteSpaceWarn')),
  handle: z
    .string()
    .nonempty(t('requiredField'))
    .min(1, t('handleLengthWarn'))
    .regex(/^[a-z0-9_-]+$/, t('inputWarn')),
  avatarUrl: z.string().optional(),
};

export const customerErrorMap: Record<string, string> = {
  CUSTOMER_LIMIT_REACHED: t('customerLimitWarn'),
  CUSTOMER_NAME_EMPTY: t('customerNameWarn'),
  CUSTOMER_NAME_DUPLICATE: t('customerDuplicateWarn'),
  CUSTOMER_HANDLE_DUPLICATE: t('handleDuplicateWarn'),
  CUSTOMER_HANDLE_INVALID_CHARACTER: t('handleInputWarn'),
};
