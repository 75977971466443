import { gql } from '@apollo/client';
import { Avatar, Box, Text, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { AvatarInput, FileUploadProps } from '~components/ui/AvatarInput';
import { getInitials } from '~components/ui/styles/Avatar';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useLogoSettingsTab } from '~pages/[organization]/settings/Index';
import { fromError } from '~utils/errors';
import { useS3Upload } from '~utils/useS3Upload';
import { useEditOrganizationLogoMutation } from './__generated__/LogoSettings.graphql';

export function OrganizationLogoSettingsPage() {
  const { organization } = useLogoSettingsTab();
  const toast = useToast();
  const [editOrganization] = useEditOrganizationLogoMutation();
  const { upload } = useS3Upload();

  const onChange = async ({ file, uploadPayload }: FileUploadProps) => {
    const { uploadUrl, assetUrl } = uploadPayload ?? {};

    if (!isNil(uploadUrl) && !isNil(file)) {
      try {
        await upload(uploadUrl, file);
      } catch (error) {
        toast({
          status: 'error',
          title: t('uploadImageErr'),
          description: fromError(error, 'UploadFile'),
        });
        return;
      }
    }

    try {
      const { data } = await editOrganization({
        variables: {
          input: {
            avatarUrl: assetUrl ?? null,
          },
        },
      });

      if (isNil(data)) {
        throw new Error('no data received');
      }
    } catch (error) {
      toast({
        status: 'error',
        title: t('updateAvatarErr'),
        description: fromError(error, 'EditOrganization'),
      });
    }
  };

  const onError = useCallback(
    async (err: Error) => {
      toast({
        status: 'error',
        title: t('editOrganizationErr'),
        description: fromError(err, 'EditOrganization'),
      });
    },
    [toast],
  );

  return (
    <VerticalTabContent title={t('logo')}>
      <Text fontSize="md" color="gray.500" mb="10" maxWidth="700">
        {t('editOrganizationDesc')}
      </Text>
      <Box display="flex" flex="1">
        <AvatarInput
          avatar={organization.avatarUrl ?? undefined}
          clearLabel={t('clearOrganizationLogo')}
          previewComponent={(previewUrl) => (
            <Avatar
              variant="square"
              size="xl"
              name={organization.name}
              src={previewUrl}
              getInitials={getInitials}
              mr="4"
              alignSelf="flex-start"
            />
          )}
          onChange={onChange}
          onError={onError}
          title={t('organizationLogo')}
          borderRadius="md"
          confirmToDelete
        />
      </Box>
    </VerticalTabContent>
  );
}

OrganizationLogoSettingsPage.graphql = {
  fragments: {
    EditOrganizationLogo_organization: gql`
      fragment LogoSettings_organization on Organization {
        id
        name
        avatarUrl
      }
    `,
  },
  mutations: {
    EditOrganizationLogo: gql`
      mutation EditOrganizationLogo($input: OrganizationUpdateInput!) {
        organizationUpdate(input: $input) {
          id
          ...LogoSettings_organization
        }
      }
    `,
  },
};
