import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import FlagFR from '~components/ui/icons/FlagFR.png';
import FlagUK from '~components/ui/icons/FlagUK.png';
import { components } from '~components/ui/Select';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { fromError } from '~utils/errors';
import { useAuthentication } from './../../auth/useAuthentication';
import { useSelfProfileUpdateMutation } from './__generated__/profileSettings.graphql';

export function ProfileSettings() {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const languageOptions = [
    {
      value: 'en-GB',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={FlagUK} alt="UK Flag" width="20" />
          English (UK)
        </div>
      ),
    },
    {
      value: 'fr',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={FlagFR} alt="French Flag" width="20" />
          French
        </div>
      ),
    },
  ];
  const { user } = useAuthentication();
  const [locale, setLocale] = useState<string>(
    localStorage.getItem('locale') || user.locale || 'en-GB',
  );

  const [SelfProfileUpdateMutation] = useSelfProfileUpdateMutation();

  const handleChange = useCallback(
    async (selectedOption: SingleValue<{ value: string; label: JSX.Element }>) => {
      if (!selectedOption) return;
      const selectedLanguage = selectedOption.value;

      try {
        await SelfProfileUpdateMutation({
          variables: {
            input: {
              locale: selectedLanguage,
            },
          },
        });
        await i18n.changeLanguage(selectedLanguage);
        setLocale(selectedLanguage);
        localStorage.setItem('locale', selectedLanguage);
      } catch (err) {
        toast({
          status: 'error',
          title: t('languageUpdateErr'),
          description: fromError(err, 'UpdateLanguage'),
        });
      }
    },
    [SelfProfileUpdateMutation, toast, i18n, t],
  );

  return (
    <VerticalTabContent title={t('profile')}>
      <label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>
        {t('Change Language')}
      </label>
      <Select
        value={languageOptions.find((option) => option.value === locale)}
        options={languageOptions}
        components={{
          ...components,
        }}
        styles={{
          control: (base) => ({
            ...base,
            width: '300px',
            minWidth: '200px',
            maxWidth: '100%',
          }),
          menu: (base) => ({
            ...base,
            width: '300px',
            minWidth: '200px',
          }),
        }}
        onChange={handleChange}
        isMulti={false}
        escapeClearsValue={true}
        isSearchable={false}
        isClearable={false}
        menuPlacement="auto"
      />
    </VerticalTabContent>
  );
}

ProfileSettings.graphql = {
  mutations: {
    SelfProfileUpdate: gql`
      mutation SelfProfileUpdate($input: SelfProfileUpdateInput!) {
        selfProfileUpdate(input: $input) {
          user {
            locale
          }
        }
      }
    `,
  },
};
