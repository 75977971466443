import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import { MaybePromise } from '~utils/types';
import {
  DeleteCustomer_CustomerFragment,
  useDeleteCustomerMutation,
} from './__generated__/DeleteCustomer.graphql';

gql`
  mutation DeleteCustomer($input: CustomerDeleteInput!) {
    customerDelete(input: $input) {
      customerId
    }
  }
`;

gql`
  fragment DeleteCustomer_customer on Customer {
    id
    name
  }
`;

interface UseDeleteCustomerProps {
  onCompleted?: () => MaybePromise<void>;
}

export function useDeleteCustomer({ onCompleted }: UseDeleteCustomerProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const [deleteCustomer] = useDeleteCustomerMutation();
  const deleteCustomerAction = useDestructiveAction<DeleteCustomer_CustomerFragment>({
    title: t('deleteCustomer'),
    message: (customer) => (
      <Trans
        i18nKey="deleteCustomerConfirm"
        values={{ name: customer?.name }}
        components={{ strong: <strong /> }}
      />
    ),
    onConfirm: async (customer) => {
      try {
        await deleteCustomer({
          variables: {
            input: {
              customerId: customer.id,
            },
          },
        });
        await onCompleted?.();
      } catch (err) {
        toast({
          status: 'error',
          title: t('deleteCustomerError'),
          description: fromError(err, 'DeleteCustomer', {
            HAS_CLAIMED_DISPLAYS: t('deleteCustomerErrorMsg'),
          }),
        });
      }
    },
  });

  return {
    mutation: deleteCustomer,
    action: deleteCustomerAction,
  };
}
