import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { json2csv } from 'json-2-csv';
import { useState } from 'react';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useCustomerDisplaysIndexPageQuery } from '~pages/[organization]/customers/[handle]/displays/__generated__/Index.graphql';
import { CustomersGridItem_CustomerFragment } from './__generated__/CustomersGridItem.graphql';

interface Props {
  customer: CustomersGridItem_CustomerFragment;
  isOpen: boolean;
  onCancel: () => void;
}

export function ExportCSVModal({ customer, isOpen, onCancel }: Props) {
  const { data, loading: customerDataLoading } = useCustomerDisplaysIndexPageQuery({
    variables: {
      customerHandle: customer.handle,
    },
  });

  const handleCancel = () => onCancel();

  const [downloadError, setDownloadError] = useState(false);

  const getCSVData = () => {
    return (data?.customer?.displays || []).map((display) => {
      const displayData: Record<string, unknown> = {
        Alias: display.alias,
        Site: display.site?.name,
        'Platform Name': display.platform.name,
        'Platform Type': display.platform.type,
        'Serial Number': display.serialNumber,
        'Wave Agent Version': display.agentVersion,
        'Firmware Version': display.firmware?.android.version,
        'Ethernet MAC': display.networkInformation.ethernetMacAddress,
        'Wi-Fi MAC': display.networkInformation.wifiMacAddress,
        'Local IP': display.networkInformation.localIPAddress,
        'Model Number': display.commercialTypeNumber,
      };

      display.groups.forEach((group, index) => {
        displayData[`Group ${index + 1}`] = group.name;
      });

      return displayData;
    });
  };

  const handleDownload = async () => {
    try {
      const csvData = await json2csv(getCSVData(), { emptyFieldValue: '' });
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `${customer.name}-data.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      setDownloadError(true);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onCancel} closeOnOverlayClick={false}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{t('downloadDisplayData')}</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody>
          {downloadError
            ? t('downloadErr')
            : customerDataLoading
            ? t('preparingDisplayData')
            : t('displayDataReady')}
        </ModalBody>
        <ModalFooter>
          <Button variant={'ghost'} colorScheme="blue" onClick={handleCancel}>
            {t('cancel')}
          </Button>
          <Button
            variant={'solid'}
            colorScheme="blue"
            marginLeft="3"
            disabled={customerDataLoading || downloadError}
            isLoading={customerDataLoading}
            onClick={handleDownload}
          >
            {t('download')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
