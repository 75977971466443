import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import Select, { OptionProps } from 'react-select';
import { z } from 'zod';
import { useAuth } from '~auth/useAuth';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, selectOptionBaseSchema } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  ManagePowerScheduleModal_CustomerFragment,
  ManagePowerScheduleModal_DisplayFragment,
  useBulkUpdatePowerScheduleMutation,
} from './__generated__/ManagePowerScheduleModal.graphql';

interface Props {
  customer: ManagePowerScheduleModal_CustomerFragment;
  display: ManagePowerScheduleModal_DisplayFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

export function ManagePowerScheduleModal({
  display,
  customer,
  isOpen,
  onCancel,
  onSuccess,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ManagePowerScheduleModalContent
          customer={customer}
          display={display}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      </ModalContent>
    </Modal>
  );
}

const powerScheduleOption = selectOptionBaseSchema;

type PowerScheduleOption = z.TypeOf<typeof powerScheduleOption>;

const schema = z.object({
  displayId: z.string(),
  powerSchedule: powerScheduleOption.optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

function ManagePowerScheduleModalContent({
  customer,
  display,
  onCancel,
  onSuccess,
}: {
  customer: ManagePowerScheduleModal_CustomerFragment;
  display: ManagePowerScheduleModal_DisplayFragment;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}) {
  const options = useMemo<PowerScheduleOption[]>(
    () =>
      customer.powerSchedules.map<PowerScheduleOption>((p) => ({
        label: p.title,
        value: p.id,
      })),
    [customer],
  );

  const currentPowerSchedule = useMemo(
    () => options.find((o) => o.value === display.powerSchedule?.schedule?.id),
    [display, options],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayId: display.id,
      powerSchedule: currentPowerSchedule,
    },
    resolver: zodResolver(schema),
  });

  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const [bulkUpdatePowerSchedule] = useBulkUpdatePowerScheduleMutation();
  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await bulkUpdatePowerSchedule({
          variables: {
            input: {
              displayIds: [values.displayId],
              powerScheduleId: values.powerSchedule?.value,
            },
          },
        });

        analytics.track('displaySettingUpdate', { group: 'power', changeItem: 'powerSchedule' });

        await onSuccess();
      } catch (err) {
        toast({
          status: 'error',
          title: t('powerScheduleUpdateErr'),
          description: fromError(err, 'BulkUpdatePowerSchedule'),
        });
      }
    },
    [analytics, bulkUpdatePowerSchedule, onSuccess, toast],
  );

  const { verifyUserPermissions } = useAuth();

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <>
      <ModalHeader>{t('choosePowerSchedule')}</ModalHeader>
      <ModalCloseButton />
      <form onSubmit={handleSubmit(performSubmit)}>
        <ModalBody>
          <FormControl isInvalid={Boolean(errors.powerSchedule)}>
            <InfoAlert mb="8">
              <Trans i18nKey="syncPowerScheduleInfo" components={{ strong: <strong /> }} />
            </InfoAlert>
            <FormLabel>{t('chooseSchedule')}</FormLabel>
            <Controller
              name="powerSchedule"
              control={control}
              render={({ field }) => (
                <Select
                  value={field.value}
                  options={options}
                  components={{
                    ...components,
                    Option: ManagePowerScheduleSelectOption,
                  }}
                  onChange={field.onChange}
                  isLoading={isSubmitting}
                  placeholder={t('clearPowerSchedule')}
                  isMulti={false}
                  escapeClearsValue={false}
                  isClearable={true}
                  isSearchable={true}
                  menuPlacement="auto"
                  isDisabled={isDisplaySettingsUpdateDisabled}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="powerSchedule"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onCancel}
            variant="ghost"
            colorScheme="blue"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            marginLeft="3"
            type="submit"
            isDisabled={isDisplaySettingsUpdateDisabled || isSubmitting}
            isLoading={isSubmitting}
          >
            {t('apply')}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}

export function ManagePowerScheduleSelectOption({
  isSelected,
  innerProps,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
OptionProps<any, any, any>) {
  const data = rest.data as PowerScheduleOption;

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingX="3.5"
      paddingY="2"
      cursor="pointer"
      color={isSelected ? 'white' : 'blue.800'}
      background={isSelected ? 'blue.400' : 'white'}
      fontSize="md"
      _hover={{
        background: isSelected ? 'blue.400' : 'blue.50',
      }}
      {...innerProps}
    >
      <chakra.span display="inline-block" flex="1">
        {data.label}
      </chakra.span>
    </Box>
  );
}

ManagePowerScheduleModal.graphql = {
  fragments: {
    ManagePowerScheduleModal_customer: gql`
      fragment ManagePowerScheduleModal_customer on Customer {
        id
        powerSchedules {
          id
          title
        }
      }
    `,
    ManagePowerScheduleModal_display: gql`
      fragment ManagePowerScheduleModal_display on Display {
        id
        powerSchedule {
          schedule {
            id
            title
            timeBlocks {
              start
              end
              day
            }
          }
          latestJob {
            id
            __typename
          }
        }
      }
    `,
  },
  mutations: {
    BulkUpdatePowerSchedule: gql`
      mutation BulkUpdatePowerSchedule($input: DisplayBulkUpdatePowerScheduleInput!) {
        displayBulkUpdatePowerSchedule(input: $input) {
          displays {
            id
            ...ManagePowerScheduleModal_display
          }
        }
      }
    `,
  },
};
