import { t } from 'i18next';
import { EmptyView, EmptyViewExternalLink } from '~components/EmptyView';
import { EmptyAppsIllustration } from '~components/ui/illustrations/EmptyAppsIllustration';
import { useFeatureFlag } from '~utils/features';

function AppsEmptyState() {
  const { isEnabled: isMarketPlaceLinkEnabled } = useFeatureFlag('appDirectMarketplaceLink');

  return (
    <EmptyView
      icon={<EmptyAppsIllustration />}
      title={t('noAppsYet')}
      description={t('noAppsYetDesc')}
    >
      {isMarketPlaceLinkEnabled && (
        <EmptyViewExternalLink
          label={t('visitProstore')}
          href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
        />
      )}
    </EmptyView>
  );
}

export default AppsEmptyState;
