import { Checkbox } from '@chakra-ui/react';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { SubscriptionTag } from '~components/ui/SubscriptionTag';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { getSubscriptionType, isWaveSubscription } from '~utils/subscriptions';
import { SubscriptionCustomersTableCustomerFragment as CustomerFragment } from './__generated__/SubscriptionCustomersTable.graphql';
import { Columns, SelectableSubscriptionCustomersTableCustomerFragment } from './types';

const TableCell = generateTableCellComponent<
  SelectableSubscriptionCustomersTableCustomerFragment,
  ReactNode
>();

const TableCellBold = generateTableCellComponent<
  SelectableSubscriptionCustomersTableCustomerFragment,
  ReactNode
>({
  isBold: true,
});

export function useSubscriptionCustomersTable(
  data: CustomerFragment[],
  currentCustomerIds: string[],
  availableSeats: number,
  setAvailableSeats: React.Dispatch<React.SetStateAction<number>>,
) {
  const columns = useMemo<Array<Column<SelectableSubscriptionCustomersTableCustomerFragment>>>(
    () => [
      {
        id: Columns.Selection,
        Cell: ({ row }: { row: Row<SelectableSubscriptionCustomersTableCustomerFragment> }) => {
          const { checked, indeterminate, onChange, role, title } = row.getToggleRowSelectedProps();

          /**
           * A customer is disabled if:
           * - The customer is not already linked to the subscription and the subscription is an essential subscription
           * OR
           * - The customers display count is greater than the available seats and the customer is not checked
           */
          const hasAnotherSubscription =
            !currentCustomerIds.includes(row.original.id) &&
            isWaveSubscription(row.original.waveSubscription);
          const notEnoughAvailableSeats = row.original.displayCount > availableSeats && !checked;

          const isDisabled = hasAnotherSubscription || notEnoughAvailableSeats;

          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              setAvailableSeats(availableSeats - row.original.displayCount);
            } else {
              setAvailableSeats(availableSeats + row.original.displayCount);
            }

            if (!isNil(onChange)) {
              onChange(event);
            }
          };

          return (
            <StopClickPropagation>
              <Checkbox
                isDisabled={isDisabled}
                isChecked={checked}
                isIndeterminate={indeterminate}
                onChange={handleChange}
                role={role}
                title={title}
              />
            </StopClickPropagation>
          );
        },
        width: '48px',
        sortType: (rowA, rowB) => {
          return (
            Number(rowA.original.isLinkedToSubscription) -
            Number(rowB.original.isLinkedToSubscription)
          );
        },
      },
      {
        id: Columns.Name,
        Header: t('customer'),
        accessor: 'name',
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Subscription,
        Header: t('subscription'),
        Cell: ({ row }: { row: Row<SelectableSubscriptionCustomersTableCustomerFragment> }) => {
          const subscriptionType = getSubscriptionType(row.original.waveSubscription);

          return <SubscriptionTag variant={subscriptionType} />;
        },
        width: '100px',
      },
      {
        id: Columns.Displays,
        Header: t('Displays'),
        accessor: 'displayCount',
        Cell: TableCell,
        width: '100px',
      },
    ],
    [currentCustomerIds, availableSeats, setAvailableSeats],
  );

  // The react-table docs specify that this *must* be memoized
  // Because the `useSortBy` plugin for `react-table` is executed _before_ `useRowSelect`, we don't have access to `isSelected` when sorting is applied.
  // We do want to sort on selection state, however, so down below, where we memoize the data, we will be adding that property manually
  const dataMemoized = useMemo<SelectableSubscriptionCustomersTableCustomerFragment[]>(
    () =>
      data.map((customer) => ({
        ...customer,
        isLinkedToSubscription: currentCustomerIds.includes(customer.id),
      })),
    [data, currentCustomerIds],
  );

  // The react-table docs specify that this *must* be memoized
  const getRowId = useCallback((row: CustomerFragment) => row.id, []);

  const selectedRowIds = useMemo(
    () =>
      currentCustomerIds.reduce<Record<string, boolean>>((map, current) => {
        map[current] = true;
        return map;
      }, {}),
    [currentCustomerIds],
  );

  return useTable(
    {
      columns,
      data: dataMemoized,
      initialState: {
        pageSize: 15,
        sortBy: [{ id: Columns.Selection, desc: true }, { id: Columns.Name }],
        selectedRowIds,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
}
