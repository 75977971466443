import { gql } from '@apollo/client';
import {
  Box,
  Center,
  chakra,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { usePowerToggle } from '~components/displays/DisplayDetail/power/usePowerToggle';
import { StatusCard_DisplayFragment } from '~components/displays/DisplayDetail/summary/__generated__/StatusCard.graphql';
import { DisplayRebootModal } from '~components/displays/DisplayDetail/summary/DisplayRebootModal';
import { SummaryCard } from '~components/displays/DisplayDetail/summary/SummaryCard';
import PowerBadge from '~components/displays/PowerBadge';
import PresenceBadge from '~components/displays/PresenceBadge';
import { ChevronDownIcon, OnIcon, PowerScheduleIcon, StandbyIcon } from '~components/ui/icons';
import { RelativeTime, SHORT_FORMAT_TIME } from '~components/ui/RelativeTime';
import { Power } from '~graphql/__generated__/types';
import { AlertRebootScheduled } from './AlertRebootScheduled';

interface Props {
  display: StatusCard_DisplayFragment;
}

export function StatusCard({ display }: Props) {
  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();
  const isConnected = Boolean(display.presence?.connected);
  const displayRebootModal = useDisclosure();
  const rebootJob = useMemo(() => display.reboot?.latestJob, [display]);
  const {
    currentPower,
    confirmationNode: togglePowerConfirmationNode,
    handleChange: togglePower,
  } = usePowerToggle({ display });
  const isOn = currentPower === Power.On;

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <SummaryCard title={t('status')}>
      <Center>
        <HStack spacing="20">
          <HStack spacing="6">
            <PresenceBadge display={display} noTooltip />
            <Box>
              <Heading size="md" mb={0}>
                {isConnected ? t('connected') : t('disconnected')}
              </Heading>
              <RelativeTime
                ts={display.presence?.timestamp}
                format={SHORT_FORMAT_TIME}
                render={(time) => (
                  <chakra.span fontSize="sm" color="gray.500">
                    {t('lastChanged', { time: time })}
                  </chakra.span>
                )}
              />
            </Box>
          </HStack>
          <HStack spacing="6">
            <PowerBadge display={display} noTooltip />
            <Box>
              <Menu>
                <MenuButton as={Heading} size="md" mb={0} cursor="pointer">
                  {capitalize(currentPower)} <ChevronDownIcon />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    iconSpacing="5"
                    icon={
                      isOn ? (
                        <StandbyIcon color="blue.800" width="5" height="5" />
                      ) : (
                        <OnIcon color="blue.800" width="5" height="5" />
                      )
                    }
                    onClick={togglePower}
                    isDisabled={isDisplaySettingsUpdateDisabled}
                  >
                    {t('switchTo')} {isOn ? t('standby') : t('on')}
                  </MenuItem>

                  <MenuItem
                    iconSpacing="5"
                    icon={<PowerScheduleIcon width="5" />}
                    onClick={displayRebootModal.onOpen}
                    isDisabled={
                      isDisplaySettingsUpdateDisabled ||
                      ['RebootJobPlanned', 'RebootJobInProgress'].includes(
                        rebootJob?.__typename || '',
                      )
                    }
                  >
                    {t('planReboot')}
                  </MenuItem>
                </MenuList>
              </Menu>
              <RelativeTime
                ts={display.power?.reportedAt}
                format={SHORT_FORMAT_TIME}
                render={(time) => (
                  <chakra.span fontSize="sm" color="gray.500">
                    {t('lastChanged', { time: time })}
                  </chakra.span>
                )}
              />
            </Box>
          </HStack>
        </HStack>
      </Center>

      <AlertRebootScheduled display={display} />

      <DisplayRebootModal
        display={display}
        isOpen={displayRebootModal.isOpen}
        onCancel={displayRebootModal.onClose}
        onSuccess={displayRebootModal.onClose}
      />

      {togglePowerConfirmationNode}
    </SummaryCard>
  );
}

StatusCard.graphql = {
  fragments: {
    StatusCard_display: gql`
      fragment StatusCard_display on Display {
        id
        presence {
          connected
          timestamp
        }
        power {
          reported
          reportedAt
          desired
        }
        ...DisplayRebootModal_display
        ...PowerBadge_display
        ...PresenceBadge_display
        ...usePowerToggle_display
      }
    `,
  },
};
