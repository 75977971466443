import { gql } from '@apollo/client';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission, Role } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';
import { z } from 'zod';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { UserCustomersTable } from '~components/organization/UserCustomersTable';
import { UserCustomersTable_CustomerFragment } from '~components/organization/UserCustomersTable/__generated__/UserCustomersTable.graphql';
import {
  useUserCustomersTable,
  useUserLiteCustomersTable,
} from '~components/organization/UserCustomersTable/useUserCustomersTable';
import { userCreateValidationSchema } from '~components/organization/utils';
import { components } from '~components/ui/Select';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import useGoBack from '~components/useGoBack';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  useCreateUserMutation,
  useUserCreatePageOrganizationQuery,
} from './__generated__/Create.graphql';
import { getRoleOptions, getRoleOptionsForRoles } from './constants';

const schema = z.object({
  roles: z.array(z.object({ label: z.string(), value: z.nativeEnum(Role) })),
  customerIds: z.array(z.string()),
  ...userCreateValidationSchema,
});

type FormValues = z.infer<typeof schema>;

export function OrganizationUserCreatePage() {
  const handleGoBack = useGoBack();
  const toast = useToast();
  const [createUser] = useCreateUserMutation();
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();

  const { data, loading, error } = useUserCreatePageOrganizationQuery();

  const defaultCustomerIds: string[] = [];

  const createForm = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      roles: getRoleOptionsForRoles([Role.Guest]),
    },
    resolver: zodResolver(schema),
  });

  const { isSubmitting, isDirty, isValid } = createForm.formState;
  const canSubmit = !isSubmitting && isValid && isDirty;

  const handleCreateUser = async ({
    email,
    roles: roleOptions,
    givenName,
    familyName,
    customerIds,
  }: FormValues) => {
    const roles = roleOptions.map(({ value }) => value as Role);

    try {
      const { data } = await createUser({
        variables: {
          input: {
            email,
            roles,
            givenName,
            familyName,
            ...(verifyUserPermissions([Permission.FeatureCustomerScopedAccess]) && { customerIds }),
          },
        },
      });

      if (isNil(data) || isNil(data.userCreate.id)) {
        throw new Error('Create user failed');
      }

      analytics.track('userCreateComplete');

      toast({
        status: 'success',
        title: t('userCreated'),
      });

      handleGoBack();
    } catch (error) {
      toast({
        status: 'error',
        title: t('createUserErr'),
        description: fromError(error, 'CreateUser', {
          ORGANIZATION_USER_ALREADY_EXISTS: t('createUserErrDesc'),
          USER_ALREADY_EXISTS: t('createUserErrDesc1'),
          USER_EMAIL_EMPTY: t('createUserErrDesc2'),
          CUSTOMER_NOT_FOUND: t('createUserErrDesc3'),
        }),
      });
    }
  };

  // This page loads data asynchronously, so we need to "reset" the form when the data is available
  // to fill out the default values
  useEffect(() => {
    if (!loading) {
      createForm.reset({
        customerIds: defaultCustomerIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once, when the page is loaded
  }, [loading]);

  useEffect(() => {
    analytics.track('userCreateStart');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run on initial mount
  }, []);

  return (
    <VerticalTabContent
      title={t('createNewUser')}
      isLoading={loading}
      error={error}
      onGoBack={handleGoBack}
      data={data?.customersByOrganization}
      hasStickyHeader={true}
      action={{
        label: t('createUser'),
        disabled: !canSubmit,
        isLoading: isSubmitting,
        onClick: createForm.handleSubmit(handleCreateUser),
      }}
    >
      {(customersByOrganization) => (
        <CreateUserForm
          defaultCustomerIds={defaultCustomerIds}
          form={createForm}
          customers={customersByOrganization.customers}
        />
      )}
    </VerticalTabContent>
  );
}

type CreateUserFormProps = {
  defaultCustomerIds?: string[];
  form: UseFormReturn<FormValues>;
  customers: UserCustomersTable_CustomerFragment[];
};

export function CreateUserForm({ customers, form, defaultCustomerIds = [] }: CreateUserFormProps) {
  const { user: authUser, organization } = useAuth();
  const roleOptions = getRoleOptions(organization, authUser);
  const control = form.control;
  const essentialCustomers = useMemo(
    () => customers.filter((customer) => customer.waveSubscription),
    [customers],
  );
  const liteCustomers = useMemo(
    () => customers.filter((customer) => !customer.waveSubscription),
    [customers],
  );

  const table1 = useUserCustomersTable(essentialCustomers, defaultCustomerIds);
  const table2 = useUserLiteCustomersTable(liteCustomers, defaultCustomerIds);
  const {
    register,
    setValue,
    formState: { errors },
  } = form;

  const { ref: emailRef, ...emailInputProps } = register('email');

  // When user selection changes, update the form data
  useEffect(() => {
    const checkedCustomerIds = [
      ...Object.keys(table1.state.selectedRowIds).sort(),
      ...Object.keys(table2.state.selectedRowIds).sort(),
    ];
    const selectionChangeUpdatedData = checkedCustomerIds.length > 0;
    setValue('customerIds', checkedCustomerIds, {
      shouldTouch: true,
      shouldDirty: selectionChangeUpdatedData,
    });
  }, [setValue, table1.state.selectedRowIds, table2.state.selectedRowIds]);

  return (
    <form>
      <VStack alignItems="flex-start" spacing={10}>
        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('userDetails')}
          </Heading>
          <FormControl isInvalid={Boolean(errors.givenName)} mb="8">
            <FormLabel>{t('firstName')}</FormLabel>
            <Input {...register('givenName')} />
            <FormErrorMessage>{errors.givenName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.familyName)} mb="8">
            <FormLabel>{t('lastName')}</FormLabel>
            <Input {...register('familyName')} />
            <FormErrorMessage>{errors.familyName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.email)}>
            <FormLabel>{t('Email')}</FormLabel>
            <Input ref={emailRef} placeholder={t('emailPlaceholder')} {...emailInputProps} />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </FormControl>

        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('permissions')}
          </Heading>
          <FormControl isInvalid={Boolean(errors.roles)}>
            <FormLabel>{t('roles')}</FormLabel>
            <Controller
              control={control}
              name="roles"
              render={({ field }) => {
                return (
                  <Select
                    components={components}
                    options={roleOptions}
                    isMulti={true}
                    required={true}
                    isClearable={false}
                    menuPlacement="auto"
                    {...field}
                  />
                );
              }}
            />
            <FormErrorMessage>{errors.roles?.message}</FormErrorMessage>
          </FormControl>
        </FormControl>

        <Shield requiredPermissions={[Permission.FeatureCustomerScopedAccess]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              {t('customers')}
            </Heading>
            {isNil(customers) || isEmpty(customers) ? (
              <>{t('noCustomers')}</>
            ) : (
              <UserCustomersTable table1={table1} table2={table2} />
            )}
          </FormControl>
        </Shield>
      </VStack>
    </form>
  );
}

OrganizationUserCreatePage.graphql = {
  queries: {
    UserCreatePage: gql`
      query UserCreatePageOrganization {
        customersByOrganization {
          customers {
            ...UserCustomersTable_customer
          }
        }
        organization {
          id
        }
        me {
          id
        }
      }
    `,
  },

  mutations: {
    UserCreatePage: gql`
      mutation CreateUser($input: UserCreateInput!) {
        userCreate(input: $input) {
          id
          email
          roles
          givenName
          familyName
        }
      }
    `,
  },
};
