import { Button } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { EditIcon, TrashIcon } from '~components/ui/icons';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { Role } from '~graphql/__generated__/types';
import { useAnalyticsReporter } from '~utils/analytics';
import { useDeleteUser } from '../mutations/DeleteUser';
import { UsersTableUserFragment } from './__generated__/UsersTable.graphql';
import { Columns } from './types';

const TableCell = generateTableCellComponent<UsersTableUserFragment, ReactNode>();

const TableCellBold = generateTableCellComponent<UsersTableUserFragment, ReactNode>({
  isBold: true,
});

function UsersActionsCell({ row: { original: users } }: { row: Row<UsersTableUserFragment> }) {
  const navigate = useNavigate();
  const analytics = useAnalyticsReporter();

  const deleteUsers = useDeleteUser({
    onCompleted: () => {
      analytics.track('userDeleteComplete');
    },
  });

  const handleDelete = async () => {
    analytics.track('userDeleteStart');
    await deleteUsers.action.askConfirmation(users);
  };

  const handleEdit = () => navigate(users.id, { state: { canGoBack: true } });

  return (
    <Shield requiredPermissions={[Permission.UserDelete, Permission.UserUpdate]}>
      <Button size="sm" variant="inline" colorScheme="gray" onClick={handleEdit}>
        <EditIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
      </Button>
      <Button size="sm" variant="inline" colorScheme="gray" onClick={handleDelete}>
        <TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />
      </Button>
      {deleteUsers.action.confirmationNode}
    </Shield>
  );
}

export function useUsersTable(data: UsersTableUserFragment[]) {
  const { verifyUserPermissions } = useAuth();

  const columns = useMemo<Array<Column<UsersTableUserFragment>>>(
    () => [
      {
        id: Columns.Name,
        Header: t('users'),
        accessor: (user) => `${user.givenName} ${user.familyName}`,
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.givenName
            .toLowerCase()
            .localeCompare(rowB.original.givenName.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Roles,
        Header: t('roles'),
        accessor: (user) =>
          [...user.roles]
            .filter((role) => role !== Role.Mfa)
            .sort()
            .join(', '),
        Cell: TableCell,
        width: 'auto',
      },
      {
        id: Columns.Customers,
        Header: t('customers'),
        accessor: (user) => user.customerIds?.length ?? 0,
        Cell: TableCell,
        width: '90px',
      },
      {
        id: Columns.Email,
        Header: t('email'),
        accessor: (user) => user.email,
        Cell: TableCell,
        width: 'auto',
      },
      {
        id: Columns.Actions,
        Cell: UsersActionsCell,
        width: '160px',
      },
    ],
    [],
  );

  return useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Name }],
        filters: [{ id: Columns.Name, value: [] }],
        hiddenColumns: !verifyUserPermissions([Permission.FeatureCustomerScopedAccess])
          ? [Columns.Customers]
          : [],
      },

      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );
}
