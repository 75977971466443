import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { isNil } from 'lodash';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import MultiSelectPills from '~components/MultiSelectPills';
import { HighlightDaysAndTimes } from '~components/powerSchedules/PowerScheduleDetail/HighlightDaysAndTimes';
import { TimeSelect } from '~components/powerSchedules/TimeSelect';
import { InfoAlert } from '~components/ui/Alert';
import { Day, TimeBlock } from '~graphql/__generated__/types';
import { DAYS_OF_WEEK_MON_FIRST, HOUR_REGEX, validateAndSortTimeBlocks } from '~utils/timeBlocks';
import { ensure } from '~utils/types';

const FormData = z.object({
  start: z.string().regex(HOUR_REGEX),
  end: z.string().regex(HOUR_REGEX),
  days: z.array(z.nativeEnum(Day)),
  isRemoved: z.boolean(),
});
type FormData = z.infer<typeof FormData>;

const areTimeBlocksEqual = (tb1?: TimeBlock, tb2?: TimeBlock) => {
  if ((!tb1 && tb2) || (tb1 && !tb2)) return false;
  return tb1?.start === tb2?.start && tb1?.end === tb2?.end && tb1?.day === tb2?.day;
};

export function useEditTimeBlocks({
  timeBlocks: existingTimeBlocks,
  defaultValues,
  onSubmit,
  onCancel,
  allowSelectDays,
  currentTimeBlock,
  useShortErrorMessage = false,
}: {
  timeBlocks: TimeBlock[];
  defaultValues?: { start: string; end: string; days: Day[] };
  onSubmit: (timeBlocks: TimeBlock[]) => void;
  onCancel: () => void;
  allowSelectDays: boolean;
  currentTimeBlock?: TimeBlock;
  useShortErrorMessage?: boolean;
}) {
  const [formDisabled, setFormDisabled] = useState<{
    isDisabled: boolean;
    reason: string | undefined;
  }>({
    isDisabled: false,
    reason: undefined,
  });

  const [timeBlocks, setTimeBlocks] = useState(existingTimeBlocks);

  const newDefaultValues = {
    isRemoved: false,
    ...(currentTimeBlock
      ? {
          start: currentTimeBlock.start,
          end: currentTimeBlock.end,
          days: [currentTimeBlock.day],
        }
      : ensure(defaultValues)),
  };

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: newDefaultValues,
    resolver: zodResolver(FormData),
  });

  const currentValues = {
    isRemoved: watch('isRemoved', newDefaultValues.isRemoved),
    start: watch('start', newDefaultValues.start),
    end: watch('end', newDefaultValues.end),
    days: watch('days', newDefaultValues.days),
  };

  // Custom implementation of react-form's native `isDirty`, because that doesn't work when not all inputs are registered
  const isDirty = !isEqual(currentValues, newDefaultValues);

  const updateTimeValue = (property: 'start' | 'end', value: string) => {
    // Only update time value if typing is complete (e.g. not yet on "01:1")
    if (!HOUR_REGEX.test(value)) return;

    setValue(property, value);

    // Update end to be after start if necessary
    const start = property === 'start' ? value : currentValues.start;
    const end = property === 'end' ? value : currentValues.end;
    if (property === 'start' && end !== '00:00' && start >= end) {
      const [hour, minute] = start.split(':').map((h) => parseInt(h));
      const newEnd =
        hour * 100 + minute <= 2300
          ? DateTime.fromObject({ hour, minute }).plus({ hours: 1 }).toFormat('HH:mm')
          : '00:00';
      updateTimeValue('end', newEnd);
    }
  };

  useEffect(
    function onFormChange() {
      const validateTimeBlocks = ({
        start,
        end,
        days,
        isRemoved,
      }: {
        start?: string;
        end?: string;
        days?: Array<Day | undefined>;
        isRemoved?: boolean;
      }) => {
        if (!start || !end || !days || !days.length || days.some((d) => !d)) {
          return setFormDisabled({ isDisabled: true, reason: undefined });
        }

        try {
          const newTimeBlocks = (() => {
            // Remove existing slot
            if (isRemoved) {
              return existingTimeBlocks.filter((tb) => !areTimeBlocksEqual(tb, currentTimeBlock));
            }

            // Edit existing slot
            if (currentTimeBlock) {
              return existingTimeBlocks.map((tb) =>
                areTimeBlocksEqual(tb, currentTimeBlock)
                  ? { start, end, day: days[0] as Day }
                  : { start: tb.start, end: tb.end, day: tb.day },
              );
            }

            // Add new slots
            return [
              ...existingTimeBlocks.map(({ start, end, day }) => ({ start, end, day })),
              ...days.map((day) => ({
                start,
                end,
                day: day as Day,
              })),
            ];
          })();
          const { validatedTimeBlocks } = validateAndSortTimeBlocks(newTimeBlocks, {
            useShortErrorMessage,
          });
          setTimeBlocks(validatedTimeBlocks);
          setFormDisabled({ isDisabled: false, reason: undefined });
        } catch (err) {
          if (err instanceof Error) setFormDisabled({ isDisabled: true, reason: err.message });
        }
      };

      const subscription = watch(validateTimeBlocks);

      return () => subscription.unsubscribe();
    },
    [currentTimeBlock, existingTimeBlocks, useShortErrorMessage, watch],
  );

  const submit = handleSubmit(() => {
    onSubmit(timeBlocks);
    if (!currentValues.isRemoved) reset();
  });

  const FormBody = (
    <>
      <Box>
        <Flex marginBottom={5} alignItems="end">
          <FormControl isInvalid={Boolean(errors.start)} maxWidth="80px">
            <FormLabel>{t('On')}</FormLabel>
            <TimeSelect
              value={currentValues.start}
              onChange={updateTimeValue}
              property="start"
              disabled={currentValues.isRemoved}
            />
            <FormErrorMessage>{errors.start?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.end)} maxWidth="80px" marginLeft={10}>
            <FormLabel>{t('Standby')}</FormLabel>
            <TimeSelect
              value={currentValues.end}
              onChange={updateTimeValue}
              property="end"
              disabled={currentValues.isRemoved}
            />
            <FormErrorMessage>{errors.end?.message}</FormErrorMessage>
          </FormControl>
          {currentTimeBlock && (
            <Button
              variant="ghost"
              color="red.400"
              padding={2}
              marginLeft={4}
              isDisabled={currentValues.isRemoved}
              _disabled={{ background: 'red.50', opacity: 0.5, pointerEvents: 'none' }}
              onClick={() => {
                setValue('isRemoved', true);
              }}
            >
              {t('removeTimeSlot')}
            </Button>
          )}
        </Flex>
        {allowSelectDays && (
          <FormControl isInvalid={Boolean(errors.days)}>
            <FormLabel>{t('days')}</FormLabel>
            <MultiSelectPills
              options={DAYS_OF_WEEK_MON_FIRST.map((day) => ({
                value: day,
                label: capitalize(t(day).substring(0, 3)),
              }))}
              onChange={(values) => {
                setValue('days', values as Day[]);
              }}
              inputProps={register('days')}
              value={currentValues.days}
            />
            <FormErrorMessage>
              {(errors.days ?? [])
                .map?.((day) => day?.message)
                .filter(isNil)
                .join('\n')}
            </FormErrorMessage>{' '}
          </FormControl>
        )}
        <Collapse in={formDisabled.isDisabled && !!formDisabled.reason} animateOpacity>
          <Box
            marginTop={formDisabled.isDisabled ? (useShortErrorMessage ? 8 : 10) : 0}
            transition="margin 100ms"
          >
            <InfoAlert>
              <HighlightDaysAndTimes>{formDisabled.reason || ''}</HighlightDaysAndTimes>
            </InfoAlert>
          </Box>
        </Collapse>
      </Box>
    </>
  );

  const SubmitButton = ({ children }: { children: ReactNode }) => (
    <Button
      variant="solid"
      colorScheme="blue"
      marginLeft="3"
      type="submit"
      isDisabled={
        !isDirty || isSubmitting || (currentValues.isRemoved ? false : formDisabled.isDisabled)
      }
      isLoading={isSubmitting}
    >
      {children}
    </Button>
  );

  const CancelButton = ({ children }: { children?: ReactNode }) => {
    return (
      <Button
        variant="ghost"
        colorScheme="blue"
        onClick={() => {
          // Reset isRemoved after close animation
          if (currentValues.isRemoved) setTimeout(() => setValue('isRemoved', false), 500);
          onCancel();
          reset();
        }}
        isDisabled={isSubmitting}
      >
        {children || 'Cancel'}
      </Button>
    );
  };

  return {
    handleSubmit: submit,
    reset,
    currentValues,
    FormBody,
    SubmitButton,
    CancelButton,
  };
}
