import { Avatar, Box, Button, useDisclosure } from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAuth } from '~auth/useAuth';
import { MenuBackdrop, MenuItem } from '~components/MenuBackdrop';
import { DocumentationIcon, HelpIcon, LogoutIcon, SettingsIcon } from '~components/ui/icons';
import { getInitials } from '~components/ui/styles/Avatar';
import { useTheme } from '~components/ui/styles/hooks';
import { useAnalyticsReporter } from '~utils/analytics';
import { useDimensions } from '~utils/dimensions';

export function UserMenu() {
  const theme = useTheme();
  const { user, organization } = useAuth();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [ref] = useDimensions<HTMLButtonElement>();
  const baseURL = organization && `/${organization.handle}`;
  const analytics = useAnalyticsReporter();

  const handleLogout = useCallback(() => {
    analytics.track('logout', { from: 'header' });
    onClose();
  }, [analytics, onClose]);

  return (
    <Box display="flex" alignItems="center">
      <ClickAwayListener onClickAway={onClose}>
        <Box position="relative" display="flex" alignItems="center">
          <Button
            aria-haspopup={true}
            aria-expanded={isOpen}
            ref={ref}
            variant="unstyled"
            height="auto"
            display="flex"
            onClick={onToggle}
          >
            <Avatar
              name={user.givenName ? `${user.givenName} ${user.familyName || ''}` : user.name}
              getInitials={getInitials}
              background={isOpen ? 'blue.500' : 'gray.50'}
              color={isOpen ? 'white' : 'gray.900'}
              border={isOpen ? `2px solid ${theme.colors.blue[500]}` : '2px solid transparent'}
              _hover={{
                border: '2px solid',
                borderColor: 'blue.500',
              }}
            />
          </Button>

          <MenuBackdrop width={theme.sizes[64]} isOpen={isOpen} right>
            <MenuItem
              to={`${baseURL}/settings`}
              onClick={onClose}
              icon={<SettingsIcon color="gray.900" height="6" width="6" />}
            >
              {t('settings')}
            </MenuItem>

            <MenuItem
              to="https://docs.wave.ppds.com"
              onClick={onClose}
              isExternal
              icon={<DocumentationIcon height="6" width="6" color="gray.900" />}
            >
              {t('documentation')}
            </MenuItem>

            <MenuItem
              to="https://tpvcrmplus.force.com/TPVB2B/s/"
              onClick={onClose}
              isExternal
              icon={<HelpIcon height="6" width="6" color="gray.900" />}
            >
              {t('contactSupport')}
            </MenuItem>

            <MenuItem
              to="/logout"
              onClick={handleLogout}
              icon={<LogoutIcon color="red.400" height="6" width="6" />}
              danger
            >
              {t('logout')}
            </MenuItem>
          </MenuBackdrop>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
