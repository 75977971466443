import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  EditPowerScheduleModal_PowerScheduleFragment,
  usePowerScheduleEditMutation,
} from './__generated__/EditPowerScheduleModal.graphql';

interface Props {
  powerSchedule: EditPowerScheduleModal_PowerScheduleFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (update: EditPowerScheduleModal_PowerScheduleFragment) => Promise<void> | void;
}

const schema = z.object({
  powerScheduleId: z.string(),
  title: z.string(),
  description: z.string().nullish(),
});

type FormValues = z.infer<typeof schema>;

export function EditPowerScheduleModal({ isOpen, onCancel, onSuccess, powerSchedule }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <EditPowerScheduleModalContent
          powerSchedule={powerSchedule}
          initialFocusRef={initialFocusRef}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

type ContentProps = {
  powerSchedule: EditPowerScheduleModal_PowerScheduleFragment;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
  onSuccess: (update: EditPowerScheduleModal_PowerScheduleFragment) => Promise<void> | void;
};

function EditPowerScheduleModalContent({
  onSuccess,
  onCancel,
  initialFocusRef,
  powerSchedule,
}: ContentProps) {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      powerScheduleId: powerSchedule.id,
      title: powerSchedule.title,
      description: powerSchedule.description,
    },
    resolver: zodResolver(schema),
  });
  const toast = useToast();
  const [editPowerSchedule] = usePowerScheduleEditMutation();
  const { ref: titleInputRef, ...titleInputProps } = register('title');
  const { ref: descriptionInputRef, ...descriptionInputProps } = register('description');
  const analytics = useAnalyticsReporter();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const { data } = await editPowerSchedule({
          variables: {
            input: {
              powerScheduleId: values.powerScheduleId,
              title: values.title,
              description: values.description,
            },
          },
        });

        if (!data) {
          throw new Error('no data received');
        }

        analytics.track('scheduleUpdate');
        await onSuccess?.(data.powerScheduleUpdate.powerSchedule);
        reset();

        toast({
          status: 'success',
          title: t('powerScheduleEdited'),
          description: t('powerScheduleEditedDesc'),
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('powerScheduleEditErr'),
          description: fromError(err, 'EditPowerSchedule'),
        });
      }
    },
    [analytics, editPowerSchedule, onSuccess, reset, toast],
  );

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>{t('editPowerSchedule')}</ModalHeader>
      <ModalCloseButton tabIndex={5} />

      <ModalBody>
        <VStack spacing="4">
          <FormControl isInvalid={Boolean(errors.title)} marginBottom={3}>
            <FormLabel>{t('powerScheduleTitle')}</FormLabel>
            <Input
              placeholder={t('powerSchedulePlaceholder')}
              ref={(r) => {
                titleInputRef(r);
                initialFocusRef.current = r;
              }}
              {...titleInputProps}
            />
            <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.description)}>
            <FormLabel>{t('description')}</FormLabel>
            <Input
              placeholder={t('someDescription')}
              ref={(r) => {
                descriptionInputRef(r);
                initialFocusRef.current = r;
              }}
              {...descriptionInputProps}
            />

            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleClose} variant="ghost" colorScheme="blue" isDisabled={isSubmitting}>
          {t('cancel')}
        </Button>
        <Button
          variant="solid"
          colorScheme="blue"
          marginLeft="3"
          type="submit"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          {t('save')}
        </Button>
      </ModalFooter>
    </form>
  );
}

EditPowerScheduleModal.graphql = {
  fragments: {
    EditPowerScheduleModal_powerSchedule: gql`
      fragment EditPowerScheduleModal_powerSchedule on PowerSchedule {
        id
        title
        description
      }
    `,
  },
  mutations: {
    PowerScheduleEdit: gql`
      mutation PowerScheduleEdit($input: PowerScheduleUpdateInput!) {
        powerScheduleUpdate(input: $input) {
          powerSchedule {
            id
            ...EditPowerScheduleModal_powerSchedule
          }
        }
      }
    `,
  },
};
