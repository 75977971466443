import { gql } from '@apollo/client';
import { Flex, useToast } from '@chakra-ui/react';
import { cloneDeep, isNil, merge } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { GroupBase, OptionProps, SingleValue, SingleValueProps } from 'react-select';
import { components } from '~components/ui/Select';
import { useTheme } from '~components/ui/styles/hooks';
import { LedStripColor } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import {
  DisplayLedStripColorSelect_DisplayFragment,
  useUpdateLedStripColorMutation,
} from './__generated__/DisplayLedStripColorSelect.graphql';

interface LedStripColorValue {
  readonly label: string;
  readonly value: string;
  readonly colorCode?: string;
  readonly strikethrough?: boolean;
}

const ColorIcon = ({ ledStripColor }: { ledStripColor: LedStripColorValue }) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{ marginRight: 16 }}
    >
      <rect
        fill={ledStripColor.colorCode || 'white'}
        stroke={theme.colors.gray[200]}
        strokeWidth="2"
        x="1"
        y="1"
        width="18"
        height="18"
        rx="1"
        ry="1"
      />
      {ledStripColor.strikethrough && (
        <path fill="#bcccdc" d="M1,17.59L17.59,1l1.41,1.41L2.41,19l-1.41-1.41Z" />
      )}
    </svg>
  );
};

const UpdateLedStripColorSelectOption = ({
  isSelected,
  innerProps,
  data,
}: OptionProps<LedStripColorValue, false, GroupBase<LedStripColorValue>>) => (
  <Flex
    alignItems="center"
    paddingX="3.5"
    paddingY="2"
    cursor="pointer"
    color={isSelected ? 'white' : 'blue.800'}
    background={isSelected ? 'blue.400' : 'white'}
    fontSize="md"
    _hover={{
      background: isSelected ? 'blue.400' : 'blue.50',
    }}
    {...innerProps}
  >
    <ColorIcon ledStripColor={data} />
    {data.label}
  </Flex>
);

const UpdateLedStripColorSelectSingleValue = ({
  innerProps,
  data,
}: SingleValueProps<LedStripColorValue, false, GroupBase<LedStripColorValue>>) => (
  <Flex alignItems="center" marginX="-1" {...innerProps}>
    <ColorIcon ledStripColor={data} />
    {data.label}
  </Flex>
);

export function DisplayLedStripColorSelect({
  display,
  isDisabled = false,
}: {
  display: DisplayLedStripColorSelect_DisplayFragment;
  isDisabled?: boolean;
}) {
  const toast = useToast();
  const [updateLedStripColor] = useUpdateLedStripColorMutation();
  const { t } = useTranslation();

  const LED_STRIP_COLORS: readonly LedStripColorValue[] = [
    { label: t('disabled'), value: 'UNSPECIFIED', strikethrough: true },
    { label: t('white'), value: 'WHITE', colorCode: '#fff' },
    { label: t('red'), value: 'RED', colorCode: '#E12D39' },
    { label: t('green'), value: 'GREEN', colorCode: '#27AB83' },
    { label: t('blue'), value: 'BLUE', colorCode: '#1D85E8' },
    { label: t('yellow'), value: 'YELLOW', colorCode: '#F6E05E' },
    { label: t('cyan'), value: 'CYAN', colorCode: '#4AF1F1' },
    { label: t('magenta'), value: 'MAGENTA', colorCode: '#E84CE8' },
  ];
  const handleChange = useCallback(
    async (newValue: SingleValue<LedStripColorValue>) => {
      try {
        if (isNil(newValue)) {
          throw new Error('No value selected for the led strip color.');
        }

        const ledStripColor = newValue.value as LedStripColor;

        await updateLedStripColor({
          variables: {
            input: {
              displayIds: [display.id],
              ledStripColor,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            displayBulkUpdateLedStripColor: {
              __typename: 'DisplayBulkUpdateLedStripColorPayload',
              displays: [
                {
                  __typename: 'Display',
                  id: display.id,
                  ledStripColor: merge(cloneDeep(display.ledStripColor), {
                    desired: ledStripColor,
                  }),
                },
              ],
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('ledStripColorUpdateErr'),
          description: fromError(err, 'UpdateLedStripColor'),
        });
      }
    },
    [display, toast, updateLedStripColor, t],
  );

  return (
    <Select
      options={LED_STRIP_COLORS}
      value={LED_STRIP_COLORS.find(
        (o) => o.value === (display.ledStripColor?.desired || display.ledStripColor?.reported),
      )}
      onChange={handleChange}
      isMulti={false}
      isSearchable={false}
      isDisabled={isDisabled}
      components={{
        ...components,
        Option: UpdateLedStripColorSelectOption,
        SingleValue: UpdateLedStripColorSelectSingleValue,
      }}
    />
  );
}

DisplayLedStripColorSelect.graphql = {
  fragments: {
    DisplayLedStripColorSelect_display: gql`
      fragment DisplayLedStripColorSelect_display on Display {
        id
        ledStripColor {
          reported
          desired
        }
      }
    `,
  },
  mutations: {
    UpdateLedStripColor: gql`
      mutation UpdateLedStripColor($input: DisplayBulkUpdateLedStripColorInput!) {
        displayBulkUpdateLedStripColor(input: $input) {
          displays {
            ...DisplayLedStripColorSelect_display
          }
        }
      }
    `,
  },
};
