import {
  Box,
  Button,
  chakra,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEditTimeBlocks } from '~components/powerSchedules/PowerScheduleDetail/useEditTimeBlocks';
import { CloseIcon, EditIcon, OnIcon } from '~components/ui/icons';
import { useTheme } from '~components/ui/styles/hooks';
import { PowerSchedule, TimeBlock } from '~graphql/__generated__/types';
import { hourToDecimal } from '~utils/timeBlocks';

interface TimeBlockBoxProps {
  allTimeBlocks: PowerSchedule['timeBlocks'];
  currentTimeBlock: TimeBlock;
  onSubmit: (timeBlocks: TimeBlock[]) => Promise<void>;
  isDisabled?: boolean;
}

export const TimeBlockBox = ({
  allTimeBlocks,
  currentTimeBlock: { start, end, day },
  onSubmit,
  isDisabled,
}: TimeBlockBoxProps) => {
  const theme = useTheme();
  const { isOpen, onToggle: togglePopover, onClose: closePopover } = useDisclosure();
  const width = (hourToDecimal('end', end) - hourToDecimal('start', start)) * 100;

  const { handleSubmit, reset, currentValues, FormBody, SubmitButton, CancelButton } =
    useEditTimeBlocks({
      timeBlocks: allTimeBlocks,
      currentTimeBlock: { start, end, day },
      useShortErrorMessage: true,
      onSubmit: (timeBlocks) => {
        onSubmit(timeBlocks).then(() => onClose());
      },
      onCancel: closePopover,
      allowSelectDays: false,
    });

  const onClose = () => {
    closePopover();
    reset();
  };

  return (
    <Popover
      placement="auto"
      flip={true}
      gutter={30}
      isOpen={isOpen}
      onClose={onClose}
      computePositionOnMount
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button
          onClick={togglePopover}
          variant="unstyled"
          overflow="hidden"
          backgroundColor="blue.100"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="left"
          flexWrap="wrap"
          borderRadius={4}
          padding="0 16px"
          lineHeight="40px"
          position="absolute"
          left={`${hourToDecimal('start', start) * 100}%`}
          width={`${width}%`}
          transition="all 200ms ease"
          isDisabled={isDisabled}
          boxShadow={[
            // Fade in blue border
            `inset 0px 0px 0px 2px transparent`,
            '0px 10px 15px -3px rgba(0, 56, 95, 0)',
            '0px 4px 6px -2px rgba(0, 56, 95, 0)',
          ].join(', ')}
          sx={{
            _hover: {
              background: 'blue.50',
              boxShadow: [
                `inset 0px 0px 0px 2px ${theme.colors.blue[500]}`,
                '0px 10px 15px -3px rgba(0, 56, 95, 0.1)',
                '0px 4px 6px -2px rgba(0, 56, 95, 0.05)',
              ].join(', '),
              bottom: '2px',
              svg: {
                // EditIcon
                transform: 'translateY(0px)',
                opacity: '1 !important',
              },
            },
          }}
        >
          {/* Hack to flex-wrap the icon and "On" out of the overflow when button is too short */}
          <chakra.div width="1px">&nbsp;</chakra.div>
          <chakra.span display="inline-flex" alignItems="center">
            <OnIcon width={6} height={6} color="blue.400" />
            <chakra.strong marginX={2}>{t('On')}</chakra.strong>
          </chakra.span>
          <chakra.span fontWeight="normal">
            {start} - {end}
          </chakra.span>
          <Spacer />
          <EditIcon
            color="gray.500"
            width="actionIconSize"
            height="actionIconSize"
            transition="all 200ms ease"
            sx={{ transform: 'translateY(50px)' }}
            opacity={0}
          />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="1px solid gray.100"
          box-shadow="0px 10px 15px -3px rgba(0, 56, 95, 0.1), 0px 4px 6px -2px rgba(0, 56, 95, 0.05)"
          border-radius={1}
          padding={6}
          minWidth="500px"
          maxWidth="650px"
        >
          <form onSubmit={handleSubmit}>
            <PopoverHeader
              color="gray.900"
              fontFamily="Inter"
              fontWeight="600"
              fontSize="xl"
              lineHeight="150%"
              display="flex"
              paddingX="0"
              paddingBottom="2"
              marginBottom="4"
              borderBottom="1px solid"
              borderColor="blue.100"
            >
              {t('editTimeSlot')}
              <Spacer />
              <PopoverCloseButton position="relative">
                <CloseIcon onClick={onClose} color="black" width="6" height="6" />
              </PopoverCloseButton>
            </PopoverHeader>
            <PopoverBody>
              <Box pb={8}>{FormBody}</Box>
            </PopoverBody>
            <PopoverFooter border="0" textAlign="right" padding="0">
              <CancelButton />
              <SubmitButton>{currentValues.isRemoved ? t('remove') : t('update')}</SubmitButton>
            </PopoverFooter>
          </form>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
