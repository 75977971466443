import { useTranslation } from 'react-i18next';
import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function TermsOfUsePage() {
  const { t } = useTranslation();
  return (
    <PolicyPage title={t('termsofUse')} heading={t('wave_terms')}>
      <div dangerouslySetInnerHTML={{ __html: t('tnc') }} />
    </PolicyPage>
  );
}
