import { Checkbox } from '@chakra-ui/react';
import { t } from 'i18next';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { defaultPageSizes as pageSizes } from '~components/ui/PageSizeSelector';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { SubscriptionTag } from '~components/ui/SubscriptionTag';
import { generateTableCellComponent } from '~components/ui/TableCell';
import { useFeatureFlag } from '~utils/features';
import { getSubscriptionType, isWaveSubscription } from '~utils/subscriptions';
import { UserCustomersTable_CustomerFragment as CustomerFragment } from './__generated__/UserCustomersTable.graphql';
import { Columns, SelectableUserCustomersTableCustomerFragment } from './types';

const TableCellBold = generateTableCellComponent<
  SelectableUserCustomersTableCustomerFragment,
  ReactNode
>({
  isBold: true,
});

export function useUserCustomersTable(data: CustomerFragment[], currentCustomerIds: string[]) {
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const columns = useMemo<Array<Column<SelectableUserCustomersTableCustomerFragment>>>(
    () => [
      {
        id: Columns.Selection,
        Header: ({ getToggleAllPageRowsSelectedProps, page }) => {
          const { role, title } = getToggleAllPageRowsSelectedProps();

          const changeableRows: Array<Row<SelectableUserCustomersTableCustomerFragment>> = [];
          let allRowsSelected = true;
          let allRowsDeselected = true;

          for (const row of page) {
            if (isSubscriptionsEnabled && !isWaveSubscription(row.original.waveSubscription)) {
              continue;
            }

            changeableRows.push(row);

            if (row.isSelected) {
              allRowsDeselected = false;
            } else {
              allRowsSelected = false;
            }
          }

          const onChange = () => {
            changeableRows.forEach((row) => row.toggleRowSelected(!allRowsSelected));
          };

          return (
            <Checkbox
              isChecked={!allRowsDeselected}
              isIndeterminate={!allRowsSelected && !allRowsDeselected}
              onChange={onChange}
              role={role}
              title={title}
            />
          );
        },
        Cell: ({ row }: { row: Row<SelectableUserCustomersTableCustomerFragment> }) => {
          const { checked, indeterminate, onChange, role, title } = row.getToggleRowSelectedProps();
          const hasSubscription = isWaveSubscription(row.original.waveSubscription);

          const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

          const isDisabled = isSubscriptionsEnabled ? !hasSubscription : false;

          return (
            <StopClickPropagation>
              <Checkbox
                isChecked={isSubscriptionsEnabled ? (hasSubscription ? checked : true) : checked}
                isIndeterminate={indeterminate}
                onChange={onChange}
                role={role}
                title={title}
                isDisabled={isDisabled}
              />
            </StopClickPropagation>
          );
        },
        width: '48px',
        sortType: (rowA, rowB) => {
          return Number(rowA.original.hasCustomerAccess) - Number(rowB.original.hasCustomerAccess);
        },
      },
      {
        id: Columns.Name,
        Header: t('customer'),
        accessor: (customer) => customer.name,
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Subscription,
        Header: t('subscription'),
        Cell: ({ row }: { row: Row<SelectableUserCustomersTableCustomerFragment> }) => {
          const subscriptionType = getSubscriptionType(row.original.waveSubscription);

          return <SubscriptionTag variant={subscriptionType} />;
        },
        width: 'auto',
      },
    ],
    [isSubscriptionsEnabled],
  );

  // The react-table docs specify that this *must* be memoized
  // Because the `useSortBy` plugin for `react-table` is executed _before_ `useRowSelect`, we don't have access to `isSelected` when sorting is applied.
  // We do want to sort on selection state, however, so down below, where we memoize the data, we will be adding that property manually
  const dataMemoized = useMemo<SelectableUserCustomersTableCustomerFragment[]>(
    () =>
      data.map((user) => ({ ...user, hasCustomerAccess: currentCustomerIds.includes(user.id) })),
    [data, currentCustomerIds],
  );

  const selectedRowIds = useMemo(
    () =>
      currentCustomerIds.reduce<Record<string, boolean>>((map, current) => {
        map[current] = true;
        return map;
      }, {}),
    [currentCustomerIds],
  );

  // The react-table docs specify that this *must* be memoized
  const getRowId = useCallback((row: CustomerFragment) => row.id, []);

  return useTable(
    {
      columns,
      data: dataMemoized,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Selection, desc: true }, { id: Columns.Name }],
        selectedRowIds,
        hiddenColumns: !isSubscriptionsEnabled ? [Columns.Subscription] : [],
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
}

export function useUserLiteCustomersTable(data: CustomerFragment[], currentCustomerIds: string[]) {
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const columns = useMemo<Array<Column<SelectableUserCustomersTableCustomerFragment>>>(
    () => [
      {
        id: Columns.Selection,
        Header: ({ getToggleAllPageRowsSelectedProps, page }) => {
          const { role, title } = getToggleAllPageRowsSelectedProps();

          const changeableRows: Array<Row<SelectableUserCustomersTableCustomerFragment>> = [];
          let allRowsSelected = true;
          let allRowsDeselected = true;
          for (const row of page) {
            if (isWaveSubscription(row.original.waveSubscription)) {
              continue;
            }
            changeableRows.push(row);
            if (row.isSelected) {
              allRowsDeselected = false;
            } else {
              allRowsSelected = false;
            }
          }

          const onChange = () => {
            changeableRows.forEach((row) => row.toggleRowSelected(!allRowsSelected));
          };
          return (
            <Checkbox
              isChecked={!allRowsDeselected}
              isIndeterminate={!allRowsSelected && !allRowsDeselected}
              onChange={onChange}
              role={role}
              title={title}
            />
          );
        },
        Cell: ({ row }: { row: Row<SelectableUserCustomersTableCustomerFragment> }) => {
          const { checked, indeterminate, onChange, role, title } = row.getToggleRowSelectedProps();
          return (
            <StopClickPropagation>
              <Checkbox
                isChecked={checked}
                isIndeterminate={indeterminate}
                onChange={onChange}
                role={role}
                title={title}
              />
            </StopClickPropagation>
          );
        },
        width: '48px',
        sortType: (rowA, rowB) => {
          return Number(rowA.original.hasCustomerAccess) - Number(rowB.original.hasCustomerAccess);
        },
      },
      {
        id: Columns.Name,
        Header: t('customer'),
        accessor: (customer) => customer.name,
        Cell: TableCellBold,
        sortType: (rowA, rowB) =>
          rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase()),
        width: 'auto',
      },
      {
        id: Columns.Subscription,
        Header: t('subscription'),
        Cell: ({ row }: { row: Row<SelectableUserCustomersTableCustomerFragment> }) => {
          const subscriptionType = getSubscriptionType(row.original.waveSubscription);
          return <SubscriptionTag variant={subscriptionType} />;
        },
        width: 'auto',
      },
    ],
    [],
  );

  // The react-table docs specify that this *must* be memoized
  // Because the `useSortBy` plugin for `react-table` is executed _before_ `useRowSelect`, we don't have access to `isSelected` when sorting is applied.
  // We do want to sort on selection state, however, so down below, where we memoize the data, we will be adding that property manually
  const dataMemoized = useMemo<SelectableUserCustomersTableCustomerFragment[]>(
    () =>
      data.map((user) => ({ ...user, hasCustomerAccess: currentCustomerIds.includes(user.id) })),
    [data, currentCustomerIds],
  );

  // The react-table docs specify that this *must* be memoized
  const getRowId = useCallback((row: CustomerFragment) => row.id, []);

  const selectedRowIds = useMemo(
    () =>
      currentCustomerIds.reduce<Record<string, boolean>>((map, current) => {
        map[current] = true;
        return map;
      }, {}),
    [currentCustomerIds],
  );

  return useTable(
    {
      columns,
      data: dataMemoized,
      initialState: {
        pageSize: pageSizes[0],
        sortBy: [{ id: Columns.Selection, desc: true }, { id: Columns.Name }],
        selectedRowIds,
        hiddenColumns: !isSubscriptionsEnabled ? [Columns.Subscription] : [],
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
}
