import { gql } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { DisplaysQueryProvider } from '~components/displays/DisplayTable/useDisplaysQuery';
import { PageLoader } from '~components/ui/PageLoader';
import { HandleApiError } from '~graphql/HandleApiError';
import { useCookies } from '~utils/analytics/cookies';
import {
  useApp_GetOrganizationQuery,
  useSelfProfileUpdateMutation,
} from './__generated__/App.graphql';
import { AppRoutes } from './AppRoutes';

function App() {
  const { i18n } = useTranslation();
  const toast = useToast();
  const { isLoading, isAuthenticated, setOrganization, user } = useAuth();
  const [SelfProfileUpdateMutation] = useSelfProfileUpdateMutation();
  useEffect(() => {
    if (user.universalLocale && !isEqual(user.universalLocale, localStorage.getItem('locale'))) {
      SelfProfileUpdateMutation({
        variables: {
          input: {
            locale: user.universalLocale,
          },
        },
      });
      i18n.changeLanguage(user.universalLocale);
      localStorage.setItem('locale', user.universalLocale);
    } else if (localStorage.getItem('locale')) {
      i18n.changeLanguage(localStorage.getItem('locale') ?? 'en-GB');
    } else {
      i18n.changeLanguage(user.locale);
    }
  }, [SelfProfileUpdateMutation, i18n, toast, user.locale, user.universalLocale]);
  const { data, loading, error } = useApp_GetOrganizationQuery({
    skip: !isAuthenticated,
  });

  const appIsLoading = isLoading || loading;

  useCookies();

  useEffect(() => {
    if (data?.organization) {
      setOrganization(data.organization);
    }
  }, [data, setOrganization]);

  return (
    <>
      {appIsLoading ? (
        <PageLoader />
      ) : error ? (
        <HandleApiError error={error} />
      ) : (
        <DisplaysQueryProvider>
          <AppRoutes />
        </DisplaysQueryProvider>
      )}
    </>
  );
}

App.graphql = {
  queries: {
    App_query: gql`
      query App_getOrganization {
        organization {
          id
          handle
          ...AuthorizationProvider_organization
          ...LogoSettings_organization
        }
      }
    `,
  },
  mutations: {
    SelfProfileUpdate: gql`
      mutation SelfProfileUpdate($input: SelfProfileUpdateInput!) {
        selfProfileUpdate(input: $input) {
          user {
            locale
          }
        }
      }
    `,
  },
};

export default Sentry.withProfiler(App);
