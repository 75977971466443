import { gql } from '@apollo/client';
import {
  Box,
  Checkbox,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column, Row, TableInstance, useRowSelect, useTable } from 'react-table';
import { useAuth } from '~auth/useAuth';
import { DraggableRow } from '~components/ui/DraggableRow';
import { FileUploadButton } from '~components/ui/FileUploadButton';
import { GripIcon, TrashIcon } from '~components/ui/icons';
import { PlaylistUploadIllustration } from '~components/ui/illustrations/PlaylistUploadIllustration';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';
import { PlaylistType } from '~graphql/__generated__/types';
import { useFeatureFlag } from '~utils/features';
import { PlaylistDetail_PlaylistFragment } from '../PlaylistDetail/__generated__/PlaylistDetail.graphql';
import { PlaylistMediaTable_MediaFragment } from './__generated__/PlaylistMediaTable.graphql';
import { CreatedAtCell } from './Cell/CreatedAtCell';
import { DurationCell } from './Cell/DurationCell';
import { FileTypeCell } from './Cell/FileTypeCell';
import { SizeCell } from './Cell/SizeCell';
import { TitleCell } from './Cell/TitleCell';

export enum Columns {
  ChangeOrder = 'changeOrder',
  Selection = 'selection',
  Order = 'order',
  Title = 'title',
  FileType = 'fileType',
  Size = 'size',
  CreatedAt = 'createdAt',
  Delete = 'Delete',
  Duration = 'Duration',
}

export type PlaylistTableMedia = Omit<PlaylistMediaTable_MediaFragment, '__typename'> & {
  isUploading?: boolean;
};

export function usePlaylistMediaTable(
  playlist: PlaylistDetail_PlaylistFragment,
  data: PlaylistTableMedia[],
  { onRemoveMedia }: { onRemoveMedia: (id: string[]) => void },
) {
  const { verifyUserPermissions } = useAuth();
  const hasPlaylistsUpdatePermission = verifyUserPermissions([Permission.PlaylistUpdate]);
  const { isEnabled: isPlaylistTypesEnabled } = useFeatureFlag('playlistTypes');

  const columnsWithDuration: Array<Column<PlaylistTableMedia>> = [
    ...(hasPlaylistsUpdatePermission
      ? [
          {
            id: Columns.ChangeOrder,
            Cell: () => (
              <Box display="flex" justifyContent="center" alignItems="center">
                <GripIcon color="gray.300" />
              </Box>
            ),
            width: 'auto',
          },
          {
            id: Columns.Selection,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const { checked, indeterminate, onChange, role, title } =
                getToggleAllRowsSelectedProps();

              return (
                <Checkbox
                  isChecked={checked}
                  isIndeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                  title={title}
                />
              );
            },
            Cell: ({ row }: { row: Row<PlaylistTableMedia> }) => {
              const { checked, indeterminate, onChange, role, title } =
                row.getToggleRowSelectedProps();

              return (
                <StopClickPropagation>
                  <Checkbox
                    isChecked={checked}
                    isIndeterminate={indeterminate}
                    onChange={onChange}
                    role={role}
                    title={title}
                  />
                </StopClickPropagation>
              );
            },
            width: 'auto',
          },
        ]
      : []),
    {
      id: Columns.Order,
      Header: t('order'),
      accessor: (_, index) => index + 1,
      Cell: ({ value }: { value: number }) => <Text as="span">{value}</Text>,
      width: 10,
    },
    {
      id: Columns.Title,
      Header: t('title'),
      accessor: (m) => m.title,
      Cell: TitleCell,
      width: 300,
    },
    {
      id: Columns.FileType,
      Header: t('type'),
      accessor: (m) => m.type,
      Cell: FileTypeCell,
      width: 'auto',
    },
    {
      id: Columns.CreatedAt,
      Header: t('dateAdded'),
      accessor: (m) => m.createdAt,
      Cell: CreatedAtCell,
      width: 'auto',
    },
    {
      id: Columns.Size,
      Header: t('size'),
      accessor: (m) => m.size,
      Cell: SizeCell,
      width: 'auto',
    },
    {
      id: Columns.Duration,
      Header: t('duration'),
      accessor: (m) => ({ ...m, playlist }),
      Cell: DurationCell,
      width: 'auto',
    },
    {
      id: Columns.Delete,
      accessor: (m) => ({ isUploading: m.isUploading, id: m.id }),
      Cell: ({ value }: { value: { isUploading: boolean; id: string } }) =>
        value.isUploading ? (
          <Box display="flex" justifyContent="center" alignContent="center">
            <Spinner w={5} h={5} color="blue.700" />
          </Box>
        ) : (
          <IconButton
            variant="inline"
            colorScheme="gray"
            aria-label="Delete media"
            icon={<TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />}
            isDisabled={!hasPlaylistsUpdatePermission}
            onClick={() => {
              onRemoveMedia([value.id]);
            }}
          />
        ),
      width: 16,
    },
  ];

  const columnsWithoutDuration: Array<Column<PlaylistTableMedia>> = [
    ...(hasPlaylistsUpdatePermission
      ? [
          {
            id: Columns.ChangeOrder,
            Cell: () => (
              <Box display="flex" justifyContent="center" alignItems="center">
                <GripIcon color="gray.300" />
              </Box>
            ),
            width: 'auto',
          },
          {
            id: Columns.Selection,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const { checked, indeterminate, onChange, role, title } =
                getToggleAllRowsSelectedProps();

              return (
                <Checkbox
                  isChecked={checked}
                  isIndeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                  title={title}
                />
              );
            },
            Cell: ({ row }: { row: Row<PlaylistTableMedia> }) => {
              const { checked, indeterminate, onChange, role, title } =
                row.getToggleRowSelectedProps();

              return (
                <StopClickPropagation>
                  <Checkbox
                    isChecked={checked}
                    isIndeterminate={indeterminate}
                    onChange={onChange}
                    role={role}
                    title={title}
                  />
                </StopClickPropagation>
              );
            },
            width: 'auto',
          },
        ]
      : []),
    {
      id: Columns.Order,
      Header: t('order'),
      accessor: (_, index) => index + 1,
      Cell: ({ value }: { value: number }) => <Text as="span">{value}</Text>,
      width: 10,
    },
    {
      id: Columns.Title,
      Header: t('title'),
      accessor: (m) => m.title,
      Cell: TitleCell,
      width: 300,
    },
    {
      id: Columns.FileType,
      Header: t('type'),
      accessor: (m) => m.type,
      Cell: FileTypeCell,
      width: 'auto',
    },
    {
      id: Columns.Size,
      Header: t('size'),
      accessor: (m) => m.size,
      Cell: SizeCell,
      width: 'auto',
    },
    {
      id: Columns.CreatedAt,
      Header: t('dateAdded'),
      accessor: (m) => m.createdAt,
      Cell: CreatedAtCell,
      width: 'auto',
    },
    {
      id: Columns.Delete,
      accessor: (m) => ({ isUploading: m.isUploading, id: m.id }),
      Cell: ({ value }: { value: { isUploading: boolean; id: string } }) =>
        value.isUploading ? (
          <Box display="flex" justifyContent="center" alignContent="center">
            <Spinner w={5} h={5} color="blue.700" />
          </Box>
        ) : (
          <IconButton
            variant="inline"
            colorScheme="gray"
            aria-label="Delete media"
            icon={<TrashIcon color="gray.300" width="actionIconSize" height="actionIconSize" />}
            isDisabled={!hasPlaylistsUpdatePermission}
            onClick={() => {
              onRemoveMedia([value.id]);
            }}
          />
        ),
      width: 16,
    },
  ];

  // Needed to make table work with drag and drop
  const getRowId = (row: { id: string }) => {
    return row.id;
  };

  const table = useTable(
    {
      columns:
        isPlaylistTypesEnabled && playlist.playlistType === PlaylistType.Image
          ? columnsWithDuration
          : columnsWithoutDuration,
      data,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetHiddenColumns: false,
      getRowId,
    },
    useRowSelect,
  );

  return table;
}

export function PlaylistMediaTable({
  playlist,
  table,
  onRowMove,
  onFileUpload,
}: {
  playlist: PlaylistDetail_PlaylistFragment;
  table: TableInstance<PlaylistTableMedia>;
  onFileUpload: (file: FileList) => void;
  onRowMove: (dragIndex: number, hoverIndex: number) => void;
}) {
  const { verifyUserPermissions } = useAuth();
  const hasPlaylistsUpdatePermission = verifyUserPermissions([Permission.PlaylistUpdate]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, columns } = table;

  const tableComponent = (
    <Table {...getTableProps()} variant="simple" width="100%">
      <Thead>
        {headerGroups.map((headerGroup) => {
          const { key: rowKey, ...rowProps } = headerGroup.getHeaderGroupProps();
          return (
            <Tr {...rowProps} key={rowKey}>
              {headerGroup.headers.map((column) => {
                const { key: headerKey, ...headerProps } = column.getHeaderProps();
                return (
                  <Th
                    {...headerProps}
                    key={headerKey}
                    color="gray.600"
                    minWidth={`${column.minWidth}px`}
                    maxWidth={`${column.maxWidth}px`}
                    width={column.width}
                    onClick={(e) => {
                      if (column.id === Columns.Selection) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (headerProps as any).onClick?.(e);
                      }
                    }}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box flex="1">{column.render('Header') as ReactNode}</Box>
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          );
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.length === 0 ? (
          <>
            <Tr>
              <Td colSpan={columns.length}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  paddingX="10"
                  paddingY="10"
                >
                  <PlaylistUploadIllustration marginBottom="8" />
                  <Box marginBottom="6">
                    <Text
                      as="span"
                      fontSize="3xl"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="blue.800"
                    >
                      {t('emptyPlaylist')}
                    </Text>
                  </Box>
                  <FileUploadButton
                    leftIcon={undefined}
                    variant="solid"
                    colorScheme="blue"
                    onChange={onFileUpload}
                    isDisabled={!hasPlaylistsUpdatePermission}
                    playlistType={playlist.playlistType}
                  >
                    {t('addMedia')}
                  </FileUploadButton>
                </Box>
              </Td>
            </Tr>
          </>
        ) : (
          <>
            {rows.map((row) => {
              prepareRow(row);
              return hasPlaylistsUpdatePermission ? (
                <DraggableRow row={row} onMove={onRowMove} key={row.getRowProps().key} />
              ) : (
                <Tr {...row.getRowProps()} key={row.getRowProps().key}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        minWidth={`${cell.column.minWidth}px`}
                        maxWidth={`${cell.column.maxWidth}px`}
                        width={cell.column.width}
                        cursor={hasPlaylistsUpdatePermission ? 'grab' : undefined}
                        onClick={
                          cell.column.id === 'selection'
                            ? () => cell.row.toggleRowSelected()
                            : undefined
                        }
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell') as ReactNode}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </>
        )}
      </Tbody>
    </Table>
  );

  if (hasPlaylistsUpdatePermission) {
    return (
      <DndProvider backend={HTML5Backend} options={{ disabled: !hasPlaylistsUpdatePermission }}>
        {tableComponent}
      </DndProvider>
    );
  } else {
    return tableComponent;
  }
}

PlaylistMediaTable.graphql = {
  fragments: {
    PlaylistMediaTable_media: gql`
      fragment PlaylistMediaTable_media on Media {
        id
        ...TitleCell_media
        ...SizeCell_media
        ...DurationCell_media
        ...FileTypeCell_media
        ...CreatedAtCell_media
      }
    `,
  },
};
