import { chakra, Tooltip } from '@chakra-ui/react';
import { t } from 'i18next';
import _ from 'lodash';
import { LoadingIcon } from './icons';

interface HasDesiredState<T> {
  desired?: T;
}

export function isPropertyPending<D, T extends HasDesiredState<D>>(property: T | null | undefined) {
  return !_.isNil(property?.desired);
}

interface Props {
  label?: string;
  isPending: boolean;
  variant?: 'default' | 'danger';
}

export function FormLabelPendingIndicator({
  label = t('changePending'),
  isPending,
  variant = 'default',
}: Props) {
  return (
    <>
      {isPending ? (
        <Tooltip label={label}>
          <chakra.span
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            verticalAlign="middle"
            marginLeft="2"
          >
            <LoadingIcon color={variant === 'default' ? 'gray.400' : 'red.400'} />
          </chakra.span>
        </Tooltip>
      ) : (
        <chakra.span
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          verticalAlign="middle"
          marginLeft="2"
        >
          <chakra.span display="inline-block" width="16px" height="16px" />
        </chakra.span>
      )}
    </>
  );
}
