import { gql } from '@apollo/client';
import { Box, chakra, Text } from '@chakra-ui/react';
import { FocusEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { components, selectOptionSchema } from '~components/ui/Select';
import {
  useCreateSelectableSiteMutation,
  useGetSelectableSitesQuery,
} from './__generated__/SiteCreatableSelect.graphql';

interface Props {
  customerId: string;
  tabIndex?: number;
  value: string | null | undefined;
  onChange?: (option: string | null | undefined) => void;
  onBlur?: FocusEventHandler;
}

interface SiteSelectOption {
  label: string;
  address?: string | null;
  value: string;
}

function SiteCreatableSelect({ customerId, value, onChange, onBlur, tabIndex }: Props) {
  const selectableSites = useGetSelectableSitesQuery({
    variables: {
      customerId,
    },
  });
  const [createSelectableSite, createSelectableSiteResult] = useCreateSelectableSiteMutation();
  const { t } = useTranslation();
  const options = useMemo(() => {
    if (!selectableSites.data || !selectableSites.data.customer) return [];

    return selectableSites.data.customer.sites.map<SiteSelectOption>((site) => ({
      label: site.name,
      address: site.address,
      value: site.id,
    }));
  }, [selectableSites]);

  const formatOptionLabel = useCallback((data: SiteSelectOption) => {
    const { label, address } = data;

    return (
      <chakra.span>
        {label}
        {address && <chakra.span color="gray.500"> - {address}</chakra.span>}
      </chakra.span>
    );
  }, []);

  const handleCreateOption = useCallback(
    (name: string) => {
      createSelectableSite({
        variables: {
          input: {
            customerId,
            name,
          },
        },
      }).then(({ data }) => {
        if (!data?.siteCreate.site) return;

        onChange?.(data.siteCreate.site.id);
      });
    },
    [customerId, createSelectableSite, onChange],
  );

  const isLoading = selectableSites.loading || createSelectableSiteResult.loading;

  if (selectableSites.error) {
    return (
      <Box>
        <Text>{t('siteNotLoadingErr')}</Text>
      </Box>
    );
  }

  return (
    <CreatableSelect
      tabIndex={tabIndex}
      value={options.find((o) => o.value === value)}
      options={options}
      formatOptionLabel={formatOptionLabel}
      isLoading={isLoading}
      onChange={(value) => onChange?.(value?.value)}
      onBlur={onBlur}
      onCreateOption={handleCreateOption}
      maxMenuHeight={200}
      placeholder={t('search')}
      components={components}
      isClearable
      menuPlacement="auto"
      isMulti={false}
    />
  );
}

SiteCreatableSelect.validationSchema = selectOptionSchema;

SiteCreatableSelect.graphql = {
  queries: {
    getSelectableSites: gql`
      query GetSelectableSites($customerId: ID!) {
        customer(id: $customerId) {
          id
          sites {
            id
            name
            address
          }
        }
      }
    `,
  },
  mutations: {
    createSelectableSite: gql`
      mutation CreateSelectableSite($input: SiteCreateInput!) {
        siteCreate(input: $input) {
          site {
            id
            name
            address
          }
          customer {
            id
            sites {
              id
              name
              address
            }
          }
        }
      }
    `,
  },
};

export default SiteCreatableSelect;
