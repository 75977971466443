import { gql } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import {
  SyncDisplaysModal_PlaylistFragment,
  useSyncPlaylistToDisplaysMutation,
} from './__generated__/SyncDisplaysModal.graphql';

interface Props {
  playlist: SyncDisplaysModal_PlaylistFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess?: () => Promise<void> | void;
}

const schema = z.object({
  playlistId: z.string(),
});

type FormValues = z.infer<typeof schema>;

export function SyncDisplaysModal({ playlist, isOpen, onCancel, onSuccess }: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      // only playlistId is set as a default value in the form because it won't update through polling.
      // displayIds to sync will be supplied at submission time.
      // Alternative approach is to run an effect everytime playlist updates and reset the form.
      playlistId: playlist.id,
    },
    resolver: zodResolver(schema),
  });

  const handleClose = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const [syncDisplays] = useSyncPlaylistToDisplaysMutation();
  const toast = useToast();
  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await syncDisplays({
          variables: {
            input: {
              playlistId: values.playlistId,
            },
          },
        });

        toast({
          status: 'success',
          title: t('syncDisplayStart'),
          description: t('syncDisplayStartDesc'),
        });

        reset();
        await onSuccess?.();
      } catch (err) {
        toast({
          status: 'error',
          title: t('syncDisplayStartErr'),
          description: fromError(err, 'SyncToDisplays'),
        });
      }
    },
    [syncDisplays, toast, reset, onSuccess],
  );

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('syncDisplays')}</ModalHeader>
        <ModalCloseButton tabIndex={5} />
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalBody>
            <Text>{t('syncDisplaysAlert')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleClose}
              variant="ghost"
              colorScheme="blue"
              isDisabled={isSubmitting}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              marginLeft="3"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t('syncPlaylist')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

SyncDisplaysModal.graphql = {
  fragments: {
    SyncDisplaysModal_playlist: gql`
      fragment SyncDisplaysModal_playlist on Playlist {
        id
        outOfSyncDisplays: displays(filter: { state: OUT_OF_SYNC }) {
          id
        }
      }
    `,
  },
  mutations: {
    SyncPlaylistToDisplays: gql`
      mutation SyncPlaylistToDisplays($input: PlaylistSyncDisplaysInput!) {
        playlistSyncDisplays(input: $input) {
          displays {
            id
          }
        }
      }
    `,
  },
};
