import { AlertTitle, Box, Button, chakra, useDisclosure, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import gql from 'graphql-tag';
import last from 'lodash/last';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { InfoAlert, WarningAlert } from '~components/ui/Alert';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { fromError } from '~utils/errors';
import { UpdateFirmwareModal } from '../../UpdateFirmwareModal';
import { formatDownloadProgress } from '../../utils';
import {
  DisplayFirmwareUpdate_DisplayFragment,
  useAbortFirmwareUpdateJobMutation,
} from './__generated__/DisplayFirmwareUpdate.graphql';

interface Props {
  display: DisplayFirmwareUpdate_DisplayFragment;
}

export function DisplayFirmwareUpdate({ display }: Props) {
  const updateFirmwareModal = useDisclosure();
  const hasUpdatesAvailable = useMemo(
    () => Boolean(display.firmware?.android.availableUpdates.length),
    [display],
  );
  const updateJob = useMemo(() => display.firmware?.android.latestJob, [display]);
  const { t } = useTranslation();
  const toast = useToast();
  const [abortJob] = useAbortFirmwareUpdateJobMutation();
  const abortFirmwareUpdate = useDestructiveAction<{ jobId: string }>({
    title: t('firmwareUpdateCancel'),
    message: t('firmwareUpdateCancelDesc'),
    confirmLabel: t('yes'),
    onConfirm: async ({ jobId }) => {
      try {
        await abortJob({
          variables: {
            input: {
              jobId,
            },
          },
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('firmwareUpdateErr'),
          description: fromError(err, 'AbortFirmwareUpdateJob', {
            JOB_ALREADY_COMPLETED: t('firmwareUpdateCompleted'),
            JOB_ALREADY_REJECTED: t('firmwareUpdateFailed'),
            JOB_ALREADY_ABORTED: t('firmwareUpdateAborted'),
            JOB_NOT_ABORTABLE: t('firmwareUpdateNotAbortable'),
          }),
        });
      }
    },
  });

  const { verifyUserPermissions } = useAuth();
  const hasFirmwareUpdatePermission = verifyUserPermissions([Permission.DisplayFirmwareUpdate]);

  if (!display.firmware) {
    return null;
  }

  return (
    <>
      <Box display="flex" flex="1">
        {updateJob?.__typename === 'AndroidUpdateRejected' && hasUpdatesAvailable ? (
          <WarningAlert
            actionButton={
              <Button
                size="sm"
                variant="outline"
                colorScheme="orange"
                color="orange.700"
                onClick={updateFirmwareModal.onOpen}
              >
                {t('retry')}
              </Button>
            }
          >
            <AlertTitle>{mapRejectionCodeToMessage(updateJob.rejectionCode)}</AlertTitle>
          </WarningAlert>
        ) : updateJob?.__typename === 'AndroidUpdatePlanned' ? (
          <InfoAlert
            actionButton={
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                color="blue.700"
                onClick={() => abortFirmwareUpdate.askConfirmation({ jobId: updateJob.id })}
              >
                {t('cancel')}
              </Button>
            }
          >
            <AlertTitle>
              {t('updateScheduled')}
              {updateJob.plannedAt !== updateJob.createdAt && (
                <>
                  {t('for')} {DateTime.fromISO(updateJob.plannedAt).toFormat('d MMM - HH:mm')}
                </>
              )}
            </AlertTitle>
          </InfoAlert>
        ) : updateJob?.__typename === 'AndroidUpdateDownloading' ? (
          <InfoAlert
            actionButton={
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                color="blue.700"
                onClick={() => abortFirmwareUpdate.askConfirmation({ jobId: updateJob.id })}
              >
                {t('cancel')}
              </Button>
            }
          >
            <AlertTitle>
              {t('downloading')} {formatDownloadProgress(updateJob.downloadProgress)}
            </AlertTitle>
          </InfoAlert>
        ) : updateJob?.__typename === 'AndroidUpdateInstalling' ? (
          <InfoAlert>
            <AlertTitle> {t('Updating')}</AlertTitle>
          </InfoAlert>
        ) : hasUpdatesAvailable ? (
          <InfoAlert
            actionButton={
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                color="blue.700"
                onClick={updateFirmwareModal.onOpen}
                isDisabled={!hasFirmwareUpdatePermission}
              >
                {t('planUpdate')}
              </Button>
            }
          >
            <AlertTitle>
              {t('firmwareUpdateAvailable')}
              <chakra.span fontWeight="400">
                ({last(display.firmware?.android.availableUpdates)})
              </chakra.span>
            </AlertTitle>
          </InfoAlert>
        ) : (
          <InfoAlert>{t('firmwareUpToDate')}</InfoAlert>
        )}
      </Box>
      <UpdateFirmwareModal
        display={display}
        isOpen={updateFirmwareModal.isOpen}
        onCancel={updateFirmwareModal.onClose}
        onSuccess={updateFirmwareModal.onClose}
      />
      {abortFirmwareUpdate.confirmationNode}
    </>
  );
}

function mapRejectionCodeToMessage(rejectionCode: string): string {
  switch (rejectionCode) {
    case 'DISPLAY_WAS_NOT_CONNECTED_AT_PLANNED_TIME':
      return 'Display was not connected at scheduled time.';
    default:
      return 'Display encountered an unexpected error.';
  }
}

DisplayFirmwareUpdate.graphql = {
  fragments: {
    DisplayFirmwareUpdate_display: gql`
      fragment DisplayFirmwareUpdate_display on Display {
        id
        firmware {
          android {
            availableUpdates
            latestJob {
              id
              targetVersion
              plannedAt
              createdAt
              ... on AndroidUpdateDownloading {
                downloadProgress
              }
              ... on AndroidUpdateRejected {
                rejectionCode
                rejectedAt
              }
            }
          }
        }
        ...UpdateFirmwareModal_display
      }
    `,
  },
  mutations: {
    AbortFirmwareUpdateJob: gql`
      mutation AbortFirmwareUpdateJob($input: DisplayAbortJobInput!) {
        displayAbortJob(input: $input) {
          display {
            id
            ...DisplayFirmwareUpdate_display
          }
        }
      }
    `,
  },
};
