import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { z } from 'zod';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { components, SelectOption, selectOptionSchema } from '~components/ui/Select';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';
import {
  ContentSourceOption,
  useContentSource,
  useContentSourceOptions,
} from '../useContentSource';
import BackToAllActionsButton from './BackToAllActionsButton';
import { BulkActionComponent } from './BulkActionModal';

const contentSourceTypeOptions = [
  {
    value: ContentSourceOption.App,
    label: ContentSourceOption.App,
  },
  {
    value: ContentSourceOption.Bookmark,
    label: ContentSourceOption.Bookmark,
  },
  {
    value: ContentSourceOption.Input,
    label: ContentSourceOption.Input,
  },
  {
    value: ContentSourceOption.Playlist,
    label: ContentSourceOption.Playlist,
  },
] as const;

const schema = z.object({
  displayIds: z.array(z.string()),
  contentSourceType: selectOptionSchema,
  contentSourceOption: selectOptionSchema.optional().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

export const BulkUpdateContentSource: BulkActionComponent = ({
  displays,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const { bulkUpdateContentSource } = useContentSource();
  const { excludedContentSources, sharedContentSourcesOptionsByType } =
    useContentSourceOptions(displays);

  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const { t } = useTranslation();
  const displayIds = displays.map((display) => display.id);

  const { appOptions, bookmarkOptions, inputOptions, playlistOptions } =
    sharedContentSourcesOptionsByType;

  const initialContentSourceTypeOption = {
    value: ContentSourceOption.Input,
    label: ContentSourceOption.Input,
  };

  const initialContentSourceOption = inputOptions.at(0);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      contentSourceType: initialContentSourceTypeOption,
      contentSourceOption: initialContentSourceOption,
      displayIds: displays.map((display) => display.id),
    },
    resolver: zodResolver(schema),
  });
  const contentSourceTypeWatch = watch('contentSourceType');

  const [subOptions, setSubOptions] = useState<SelectOption[]>([]);
  const [subOptionsLabel, setSubOptionsLabel] = useState('');

  useEffect(() => {
    if (contentSourceTypeWatch?.value === subOptionsLabel) {
      return;
    }

    switch (contentSourceTypeWatch?.value) {
      case ContentSourceOption.App:
        setSubOptions(appOptions);
        setSubOptionsLabel('App');
        setValue('contentSourceOption', appOptions.at(0) ?? null);

        break;
      case ContentSourceOption.Input:
        setSubOptions(inputOptions);
        setSubOptionsLabel('Input');
        setValue('contentSourceOption', inputOptions.at(0) ?? null);

        break;
      case ContentSourceOption.Bookmark:
        setSubOptions(bookmarkOptions);
        setSubOptionsLabel('Bookmark');
        setValue('contentSourceOption', bookmarkOptions.at(0) ?? null);

        break;
      case ContentSourceOption.Playlist:
        setSubOptions(playlistOptions);
        setSubOptionsLabel('Playlist');
        setValue('contentSourceOption', playlistOptions.at(0) ?? null);
        break;
    }
  }, [
    appOptions,
    bookmarkOptions,
    contentSourceTypeWatch?.value,
    inputOptions,
    playlistOptions,
    setValue,
    subOptions,
    subOptionsLabel,
  ]);

  const areSomeContentSourcesExcluded = excludedContentSources.length > 0;

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (
        !isDefined(values.contentSourceType) ||
        !isDefined(values.contentSourceType.value) ||
        !isDefined(values.contentSourceOption) ||
        !isDefined(values.contentSourceOption.value)
      ) {
        toast({
          status: 'info',
          title: t('selectValidSource'),
          description: t('inputSourceNotSelected'),
        });
        return;
      }
      const contentSource = values.contentSourceType?.value;
      if (isNil(contentSource)) return;

      try {
        if (values.contentSourceType.value === ContentSourceOption.App) {
          await bulkUpdateContentSource(displays, {
            __typename: 'AppContentSource',
            applicationId: values.contentSourceOption.value,
            label: values.contentSourceOption.label,
          });
        }

        if (values.contentSourceType.value === ContentSourceOption.Bookmark) {
          await bulkUpdateContentSource(displays, {
            __typename: 'BookmarkContentSource',
            index: parseInt(values.contentSourceOption.value, 10),
          });
        }

        if (values.contentSourceType.value === ContentSourceOption.Input) {
          await bulkUpdateContentSource(displays, {
            __typename: 'InputContentSource',
            source: values.contentSourceOption.value,
          });
        }

        if (values.contentSourceType.value === ContentSourceOption.Playlist) {
          await bulkUpdateContentSource(displays, {
            __typename: 'PlaylistContentSource',
            playlistId: values.contentSourceOption.value,
          });
        }

        analytics.track('displayBulkActionComplete', {
          action: 'updateContentSource',
          displayCount: displayIds.length,
        });

        await onSuccess();
      } catch (error) {
        toast({
          status: 'error',
          title: t('bulkUpdateInputSourcesErr'),
          description: fromError(error, 'bulkUpdateContentSource'),
        });
      }
    },
    [analytics, bulkUpdateContentSource, displayIds.length, displays, onSuccess, toast, t],
  );

  function formatOptionLabel({ label }: { label: string }) {
    return (
      <chakra.span title={label}>
        {label.length > 45 ? `${label.slice(0, 45)}...` : label}
      </chakra.span>
    );
  }

  return (
    <>
      <ModalContent>
        <ModalHeader>{t('changeInputSource')}</ModalHeader>
        <ModalCloseButton onClick={onCancel} isDisabled={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack direction="column" spacing="6">
              {areSomeContentSourcesExcluded && (
                <InfoAlert>{t('inputSourceSelectionAlert')}</InfoAlert>
              )}
              <FormControl isInvalid={!isNil(errors.contentSourceType)}>
                <FormLabel>{t('setNewInputSource')}</FormLabel>
                <Controller
                  name="contentSourceType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={contentSourceTypeOptions}
                      components={components}
                      onChange={field.onChange}
                      placeholder={t('noOptionSelected')}
                      isLoading={isSubmitting}
                      isMulti={false}
                      escapeClearsValue={false}
                      isClearable={false}
                      isSearchable={true}
                      menuPlacement="auto"
                      formatOptionLabel={formatOptionLabel}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contentSource"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FormControl>
              {isDefined(contentSourceTypeWatch) && (
                <FormControl isInvalid={!isNil(errors.contentSourceOption)}>
                  <FormLabel>{subOptionsLabel}</FormLabel>
                  <Controller
                    name="contentSourceOption"
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        options={subOptions}
                        components={components}
                        onChange={field.onChange}
                        placeholder={t('noOptionSelected')}
                        isLoading={isSubmitting}
                        isMulti={false}
                        escapeClearsValue={false}
                        isClearable={false}
                        isSearchable={true}
                        menuPlacement="auto"
                        formatOptionLabel={formatOptionLabel}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="contentSourceOption"
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack flex="1" direction="row" alignItems="center">
              <BackToAllActionsButton onBack={onBack} isDisabled={isSubmitting} />
              <Box flex="1" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={onCancel}
                  isDisabled={isSubmitting}
                >
                  {t('cancel')}
                </Button>
                <Button
                  marginLeft="3"
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {t('apply')}
                </Button>
              </Box>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};

BulkUpdateContentSource.graphql = {
  fragments: {
    BulkUpdateContentSource_contentSource: gql`
      fragment BulkUpdateContentSource_contentSource on ContentSource {
        ... on AppContentSource {
          label
          applicationId
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    `,
    BulkUpdateContentSource_display: gql`
      fragment BulkUpdateContentSource_display on Display {
        id
        ...UseContentSource_display
        playlist {
          current {
            id
            title
          }
        }
        contentSource {
          available {
            ...BulkUpdateContentSource_contentSource
          }
          current {
            reported {
              ...BulkUpdateContentSource_contentSource
            }
            desired {
              ...BulkUpdateContentSource_contentSource
            }
          }
        }
      }
    `,
  },
};
