import { Box, FormLabel, GridItem, Stack, Text } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { ConnectivitySection_DisplayFragment } from '~components/displays/DisplayDetail/connectivity/__generated__/ConnectivitySection.graphql';
import CopyButton from '~components/ui/CopyButton';
import { NetworkType } from '~graphql/__generated__/types';

interface Props {
  display: ConnectivitySection_DisplayFragment;
}

export function DisplayProperty({ label, value }: { label: string; value?: string | null }) {
  return (
    <Box>
      <FormLabel marginBottom="0">{label}</FormLabel>
      <Text>
        {value}
        {value && <CopyButton value={value} label={label} ml="4" />}
      </Text>
    </Box>
  );
}

export function ConnectivitySection({ display }: Props) {
  const isEthernetActive = display.networkInformation.networkType === NetworkType.Ethernet;
  const isWifiActive = display.networkInformation.networkType === NetworkType.Wifi;
  const activeLabel = '(Active)';
  const { t } = useTranslation();
  return (
    <>
      <GridItem colSpan={{ base: 4, md: 3 }}>
        <Stack direction="column" spacing="5">
          <DisplayProperty
            label={t('ethernetMACDetail', {
              activeLabel: isEthernetActive ? ` ${activeLabel}` : '',
            })}
            value={display.networkInformation.ethernetMacAddress}
          />
          <DisplayProperty label={t('localIP')} value={display.networkInformation.localIPAddress} />
        </Stack>
      </GridItem>

      <GridItem colSpan={{ base: 4, md: 5 }}>
        <DisplayProperty
          label={t('wifiMacLabel', { activeLabel: isWifiActive ? ` ${activeLabel}` : '' })}
          value={display.networkInformation.wifiMacAddress}
        />
      </GridItem>
    </>
  );
}

ConnectivitySection.graphql = {
  fragments: {
    ConnectivitySection_display: gql`
      fragment ConnectivitySection_display on Display {
        id
        networkInformation {
          ethernetMacAddress
          wifiMacAddress
          localIPAddress
          networkType
        }
      }
    `,
  },
};
