import { gql } from '@apollo/client';
import { AlertDescription, AlertTitle, Box, Button, useDisclosure } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '~auth/useAuth';
import UpgradeToWaveEssentialModal from '~components/organization/UpgradeToWaveEssentialModal/UpgradeToWaveEssentialModal';
import { CreatePowerScheduleModal } from '~components/powerSchedules/CreatePowerScheduleModal';
import { EmptyPowerSchedules } from '~components/powerSchedules/EmptyPowerSchedules';
import {
  PowerScheduleTable,
  usePowerScheduleTable,
} from '~components/powerSchedules/PowerScheduleTable/PowerScheduleTable';
import { WarningAlert } from '~components/ui/Alert';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { HandleApiError } from '~graphql/HandleApiError';
import { useAnalyticsReporter } from '~utils/analytics';
import { FeatureFlag, useFeatureFlag } from '~utils/features';
import { isWaveSubscription } from '~utils/subscriptions';
import { ensure } from '~utils/types';
import { useLimits } from '~utils/useLimits';
import { useCustomerPowerSchedulesPageQuery } from './__generated__/index.graphql';

const POLL_INTERVAL = 5000;

export function CustomerPowerSchedulesPage() {
  const { customer } = useParams();
  const navigate = useNavigate();
  const { verifyUserPermissions } = useAuth();

  const customerHandle = ensure(customer);
  const { data, loading, error, refetch, startPolling, stopPolling } =
    useCustomerPowerSchedulesPageQuery({
      variables: {
        customerHandle,
      },
      pollInterval: POLL_INTERVAL,
    });

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
  const { isEnabled: isSubscriptionsEnabled } = useFeatureFlag('subscriptions');

  const analytics = useAnalyticsReporter();
  const table = usePowerScheduleTable(data?.customer?.powerSchedules ?? []);

  const {
    powerSchedules: {
      powerSchedulesNumberPerCustomerLimit: powerSchedulesLimit,
      isPowerSchedulesLimitPerCustomerReached: isPowerSchedulesLimitPerCustomerReached,
    },
  } = useLimits();

  const handlePowerScheduleClicked = useCallback((id: string) => navigate(id), [navigate]);

  const createPowerScheduleModal = useDisclosure();

  const handleCreate = useCallback(() => {
    analytics.track('scheduleCreateStart');
    createPowerScheduleModal.onOpen();
  }, [analytics, createPowerScheduleModal]);

  const handleCreateSuccess = useCallback(() => {
    analytics.track('scheduleCreateComplete');
    refetch({ customerHandle });
    createPowerScheduleModal.onClose();
  }, [analytics, createPowerScheduleModal, customerHandle, refetch]);

  const hasEssentialSubscription = isWaveSubscription(data?.customer?.waveSubscription);

  const isPowerSchedulesLimitReached = isPowerSchedulesLimitPerCustomerReached(
    data?.customer?.powerSchedules,
    hasEssentialSubscription,
  );

  const hasCreatePowerSchedulePermission = verifyUserPermissions([Permission.PowerscheduleCreate]);

  const canCreatePowerSchedule = hasCreatePowerSchedulePermission && !isPowerSchedulesLimitReached;

  const shouldRenderUpgradeToWaveEssentialModal =
    !hasEssentialSubscription && isPowerSchedulesLimitReached;

  const shouldRenderPowerSchedulesTable = data?.customer?.powerSchedules.length ?? 0 > 0;

  const shouldRenderLimitReachedAlert = isPowerSchedulesLimitReached;

  const shouldRenderEmptyPowerSchedules = !shouldRenderPowerSchedulesTable;

  return (
    <Page title="Power schedules" pageName="power_schedules_overview">
      <PageContent>
        {loading ? (
          <PageLoader />
        ) : error ? (
          <HandleApiError error={error} />
        ) : (
          data &&
          data.customer && (
            <>
              <PageHeading
                actions={
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={handleCreate}
                    // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
                    isDisabled={!canCreatePowerSchedule}
                  >
                    {t('createSchedule')}
                  </Button>
                }
                marginBottom={isPowerSchedulesLimitReached ? '8' : undefined}
              >
                {t('powerSchedules')}
              </PageHeading>
              {
                // Remove once subscriptions are released to everyone: https://inthepocket.atlassian.net/browse/TPVWAVE-1460
              }
              {shouldRenderLimitReachedAlert ? (
                <WarningAlert marginBottom="6">
                  <AlertTitle>{t('powerSchedulesLimitReached')}</AlertTitle>
                  <AlertDescription>
                    {isSubscriptionsEnabled && !hasEssentialSubscription
                      ? t('powerSchedulesLimitReachedAlert')
                      : t('powerSchedulesMessage', { powerSchedulesLimit })}
                  </AlertDescription>
                </WarningAlert>
              ) : undefined}
              <Box
                boxShadow="elevated"
                border="1px solid"
                borderRadius="md"
                borderColor="gray.100"
                overflowX="auto"
                background="white"
              >
                {shouldRenderEmptyPowerSchedules ? (
                  <EmptyPowerSchedules handleCreate={handleCreate} />
                ) : (
                  <PowerScheduleTable table={table} onGoToDetail={handlePowerScheduleClicked} />
                )}
              </Box>
              {shouldRenderUpgradeToWaveEssentialModal && (
                <FeatureFlag feature="subscriptions">
                  <Box
                    m="auto"
                    marginY="16"
                    maxW="50VW"
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="gray.100"
                    overflow="hidden"
                    bg="white"
                    boxShadow="elevated"
                  >
                    <UpgradeToWaveEssentialModal.EmptyView />
                  </Box>
                </FeatureFlag>
              )}
              <CreatePowerScheduleModal
                customerId={data.customer.id}
                isOpen={createPowerScheduleModal.isOpen}
                onCancel={createPowerScheduleModal.onClose}
                onSuccess={handleCreateSuccess}
              />
            </>
          )
        )}
      </PageContent>
    </Page>
  );
}

CustomerPowerSchedulesPage.graphql = {
  queries: {
    CustomerPowerSchedulesPage: gql`
      query CustomerPowerSchedulesPage($customerHandle: String!) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          powerSchedules {
            ...PowerScheduleTable_powerSchedule
          }
          waveSubscription {
            id
          }
        }
      }
    `,
  },
};
