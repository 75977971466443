import { Flex } from '@chakra-ui/react';
import { t } from 'i18next';
import { ConnectedIcon } from '~components/ui/icons';
import { BrightnessIcon } from '~components/ui/icons/BrightnessIcon';
import { InputSourceIcon } from '~components/ui/icons/InputSourceIcon';
import { CustomerDisplaysIndexPageQuery } from '~pages/[organization]/customers/[handle]/displays/__generated__/Index.graphql';
import { isDefined } from '~utils/types';
import StatisticsBar, { StatisticsItem } from './StatisticsBar';

type UsageStatisticsProps = {
  customer: CustomerDisplaysIndexPageQuery['customer'];
  onItemClicked: (item: StatisticsItem) => void;
};

export default function UsageStatistics({ customer, onItemClicked }: UsageStatisticsProps) {
  const displayUsage = customer?.displayUsage;
  const totalDisplays = customer?.displays?.length ?? 0;

  const unplannedPowerStates =
    (displayUsage?.powerState.unplannedOn ?? 0) + (displayUsage?.powerState.unplannedOn ?? 0);

  const sources = getSources(customer);

  return (
    <Flex
      width="full"
      justifyContent="space-between"
      alignItems="center"
      mt="12"
      mb="2"
      gap="6"
      direction={['column', 'column', 'column', 'row']}
    >
      <StatisticsBar
        title={t('connectivity')}
        icon={ConnectedIcon}
        items={[
          {
            name: 'Connected',
            count: displayUsage?.presence.connected ?? 0,
            color: 'blue.300',
            hoverLabel: getHoverLabel(
              t('connected'),
              displayUsage?.presence.connected ?? 0,
              totalDisplays,
            ),
            clickable: true,
          },
          {
            name: 'Disconnected',
            count: displayUsage?.presence.disconnected ?? 0,
            color: 'red.400',
            hoverLabel: getHoverLabel(
              t('disconnected'),
              displayUsage?.presence.disconnected ?? 0,
              totalDisplays,
            ),
            clickable: true,
          },
        ]}
        onItemClicked={onItemClicked}
      />
      <StatisticsBar
        title={t('powerState')}
        icon={BrightnessIcon}
        items={[
          {
            name: 'Planned on',
            count: displayUsage?.powerState.plannedOn ?? 0,
            color: 'blue.300',
            hoverLabel: getHoverLabel(
              t('plannedOn'),
              displayUsage?.powerState.plannedOn ?? 0,
              totalDisplays,
            ),
          },
          {
            name: 'Planned standby',
            count: displayUsage?.powerState.plannedStandBy ?? 0,
            color: 'blue.400',
            hoverLabel: getHoverLabel(
              t('plannedStandby'),
              displayUsage?.powerState.plannedStandBy ?? 0,
              totalDisplays,
            ),
          },
          {
            name: 'Unplanned Power States',
            count: unplannedPowerStates,
            color: 'red.400',
            hoverLabel: getHoverLabel(
              t('unplannedPowerStates'),
              unplannedPowerStates,
              totalDisplays,
            ),
          },
          {
            name: 'Disconnected',
            count: displayUsage?.powerState.disconnected ?? 0,
            color: 'gray.300',
            hoverLabel: getHoverLabel(
              t('disconnected'),
              displayUsage?.powerState.disconnected ?? 0,
              totalDisplays,
            ),
            clickable: true,
          },
        ]}
        onItemClicked={onItemClicked}
      />
      <StatisticsBar
        title={t('InputSource')}
        icon={InputSourceIcon}
        items={[
          ...sources,
          {
            name: 'Standby',
            count: displayUsage?.contentSource.standby ?? 0,
            color: 'gray.600',
            hoverLabel: getHoverLabel(
              t('Standby'),
              displayUsage?.contentSource.standby ?? 0,
              totalDisplays,
            ),
            clickable: true,
          },
          {
            name: 'Disconnected',
            count: displayUsage?.contentSource.disconnected ?? 0,
            color: 'gray.300',
            hoverLabel: getHoverLabel(
              t('disconnected'),
              displayUsage?.contentSource.disconnected ?? 0,
              totalDisplays,
            ),
            clickable: true,
          },
        ]}
        onItemClicked={onItemClicked}
      />
    </Flex>
  );
}

function getSources(customer: CustomerDisplaysIndexPageQuery['customer']): StatisticsItem[] {
  const total =
    (customer?.displayUsage?.contentSource.disconnected ?? 0) +
    (customer?.displayUsage?.contentSource.standby ?? 0) +
    (customer?.displayUsage?.contentSource.sources.reduce((sum, source) => sum + source.count, 0) ??
      0);

  const sources =
    customer?.displayUsage?.contentSource.sources
      .filter((source) => isDefined(source))
      .sort((a, b) => b.count - a.count) ?? [];

  if (sources.length <= 8) {
    return sources.map(
      (source, index): StatisticsItem => ({
        name: source.source,
        count: source.count,
        color: `blue.${(index + 1) * 100}`,
        hoverLabel: `${source.count} of ${total} ${source.source}`,
      }),
    );
  }

  const topSources = sources.slice(0, 8);
  const otherSources = sources.slice(8);
  const otherCount = otherSources.reduce((sum, source) => sum + source.count, 0);

  return [
    ...topSources.map((source, index) => ({
      name: source.source,
      count: source.count,
      color: `blue.${(index + 1) * 100}`,
      hoverLabel: `${source.count} of ${total} ${source.source}`,
    })),
    {
      name: 'other',
      count: otherCount,
      color: 'blue.900',
      hoverLabel: `${otherCount} of ${total} other`,
    },
  ];
}

function getHoverLabel(name: string, count: number, total: number): string {
  return `${count} of ${total} ${name}`;
}
