import {
  Box,
  Button,
  chakra,
  IconButton,
  IconButtonProps,
  Input,
  Stack,
  useNumberInput,
} from '@chakra-ui/react';
import { t } from 'i18next';
import _ from 'lodash';
import { KeyboardEvent, useCallback, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';

const MAX_TOTAL_VISIBLE = 6;
const MAX_VISIBLE = MAX_TOTAL_VISIBLE - 1;

interface Props {
  pageCount: number;
  currentPageIndex: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  goToPage: (pageIndex: number) => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

export function PaginationServerSide({
  pageCount,
  currentPageIndex,
  hasPreviousPage,
  hasNextPage,
  goToPreviousPage,
  goToNextPage,
  goToPage,
}: Props) {
  const allPages = Array(pageCount)
    .fill(0)
    .map((_val, i) => i);

  const useVariants = pageCount > MAX_TOTAL_VISIBLE;
  const firstPage = allPages[0];
  const lastPage = allPages[pageCount - 1];

  const isFirstVariant = currentPageIndex < MAX_VISIBLE - 2;
  const isLastVariant = currentPageIndex > pageCount - MAX_VISIBLE + 1;

  const firstFewPages = allPages.slice(0, MAX_VISIBLE - 1);
  const lastFewPages = allPages.slice(-(MAX_VISIBLE - 1));
  const betweenPages = allPages.slice(currentPageIndex - 1, currentPageIndex + 2);

  if (pageCount <= 1) {
    return null;
  }

  return (
    <Box display="flex" userSelect="none">
      <Stack direction="row" alignItems="center">
        <PaginationIconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon />}
          onClick={goToPreviousPage}
          isDisabled={!hasPreviousPage}
        />
        {useVariants ? (
          <>
            {isFirstVariant ? (
              <>
                {firstFewPages.map((p) => (
                  <PaginationPager
                    key={p}
                    currentPageIndex={currentPageIndex}
                    pageIndex={p}
                    goToPage={goToPage}
                  />
                ))}
                <PaginationDots />
                <PaginationPager
                  currentPageIndex={currentPageIndex}
                  pageIndex={lastPage}
                  goToPage={goToPage}
                />
              </>
            ) : isLastVariant ? (
              <>
                <PaginationPager
                  currentPageIndex={currentPageIndex}
                  pageIndex={firstPage}
                  goToPage={goToPage}
                />
                <PaginationDots />
                {lastFewPages.map((p) => (
                  <PaginationPager
                    key={p}
                    currentPageIndex={currentPageIndex}
                    pageIndex={p}
                    goToPage={goToPage}
                  />
                ))}
              </>
            ) : (
              <>
                <PaginationPager
                  currentPageIndex={currentPageIndex}
                  pageIndex={firstPage}
                  goToPage={goToPage}
                />
                <PaginationDots />
                {betweenPages.map((p) => (
                  <PaginationPager
                    key={p}
                    currentPageIndex={currentPageIndex}
                    pageIndex={p}
                    goToPage={goToPage}
                  />
                ))}
                <PaginationDots />
                <PaginationPager
                  currentPageIndex={currentPageIndex}
                  pageIndex={lastPage}
                  goToPage={goToPage}
                />
              </>
            )}
          </>
        ) : (
          <>
            {allPages.map((p) => (
              <PaginationPager
                key={p}
                currentPageIndex={currentPageIndex}
                pageIndex={p}
                goToPage={goToPage}
              />
            ))}
          </>
        )}
        <PaginationIconButton
          aria-label="Next page"
          icon={<ChevronRightIcon />}
          onClick={goToNextPage}
          isDisabled={!hasNextPage}
        />
      </Stack>
      <Box marginLeft="4">
        <PaginationGoTo
          currentPageIndex={currentPageIndex}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      </Box>
    </Box>
  );
}

function PaginationIconButton(
  props: Pick<IconButtonProps, 'aria-label' | 'icon' | 'onClick' | 'isDisabled'>,
) {
  return (
    <IconButton
      variant="ghost"
      colorScheme="blue"
      size="sm"
      borderRadius="full"
      _disabled={{
        color: 'gray.300',
      }}
      {...props}
    />
  );
}

function PaginationPager({
  pageIndex,
  currentPageIndex,
  goToPage,
}: Pick<Props, 'currentPageIndex' | 'goToPage'> & { pageIndex: number }) {
  const isCurrentPageIndex = pageIndex === currentPageIndex;

  return (
    <Box
      as="button"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingX="3"
      paddingY="3"
      width="24px"
      height="24px"
      borderRadius="full"
      background={isCurrentPageIndex ? 'blue.700' : 'gray.100'}
      color={isCurrentPageIndex ? 'white' : 'gray.600'}
      fontSize="xs"
      fontWeight={isCurrentPageIndex ? 'semibold' : 'normal'}
      _hover={{
        background: isCurrentPageIndex ? 'blue.600' : 'gray.200',
      }}
      onClick={(e) => {
        e.preventDefault();
        goToPage(pageIndex);
      }}
    >
      <chakra.span>{pageIndex + 1}</chakra.span>
    </Box>
  );
}

function PaginationDots() {
  return <Box color="gray.200">...</Box>;
}

function PaginationGoTo({
  pageCount,
  goToPage,
}: Pick<Props, 'currentPageIndex' | 'pageCount' | 'goToPage'>) {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const { getInputProps } = useNumberInput({
    defaultValue: 1,
    min: 1,
    max: pageCount,
    precision: 0,
    onChange: (_v, valueAsNumber) => setPageIndex(valueAsNumber - 1),
  });

  const handleGoTo = useCallback(() => {
    if (_.isNil(pageIndex)) return;

    goToPage(pageIndex);
  }, [pageIndex, goToPage]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleGoTo();
      }
    },
    [handleGoTo],
  );

  return (
    <Stack direction="row" alignItems="center">
      <Box>
        <chakra.span color="blue.800" fontSize="sm" fontWeight="normal">
          {t('goToPage')}
        </chakra.span>
      </Box>
      <Input width="12" textAlign="center" size="sm" {...getInputProps()} onKeyUp={handleKeyUp} />
      <Button variant="outline" colorScheme="gray" size="sm" onClick={handleGoTo}>
        {t('go')}
      </Button>
    </Stack>
  );
}
