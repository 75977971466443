import { gql } from '@apollo/client';
import { GridItem, Stack } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { DisplayLockingSection_DisplayFragment } from '~components/displays/DisplayDetail/locking/__generated__/DisplayLockingSection.graphql';
import { DisplayInfraRedControlToggle } from './DisplayInfraRedControlToggle';
import { DisplayKeyboardControlToggle } from './DisplayKeyboardControlToggle';
import { DisplayPortsControlToggle } from './DisplayPortsControlToggle';

interface Props {
  display: DisplayLockingSection_DisplayFragment;
}

export function DisplayLockingSection({ display }: Props) {
  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();
  const isSupported =
    !!display.keyboardControl?.reported ||
    !!display.infraRedControl?.reported ||
    !!display.portsControl?.reported;

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <GridItem colSpan={{ base: 8, md: 7 }}>
      {isSupported ? (
        <Stack direction="column" spacing="10">
          <Stack direction="column" spacing="10">
            <DisplayInfraRedControlToggle
              display={display}
              isDisabled={isDisplaySettingsUpdateDisabled}
            />
            <DisplayKeyboardControlToggle
              display={display}
              isDisabled={isDisplaySettingsUpdateDisabled}
            />
          </Stack>
          <DisplayPortsControlToggle display={display} />
        </Stack>
      ) : (
        <>{t('lockingNotSupported')}</>
      )}
    </GridItem>
  );
}

DisplayLockingSection.graphql = {
  fragments: {
    DisplayLockingSection_display: gql`
      fragment DisplayLockingSection_display on Display {
        id
        ...DisplayInfraRedControlToggle_display
        ...DisplayKeyboardControlToggle_display
        ...DisplayPortsControlToggle_display
      }
    `,
  },
};
