import { gql } from '@apollo/client';
import { FormLabel, Stack, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { cloneDeep, isNil, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import {
  DisplayPortsControlToggle_DisplayFragment,
  useUpdatePortsControlMutation,
} from '~components/displays/DisplayDetail/locking/__generated__/DisplayPortsControlToggle.graphql';
import { toAvailableControlToggleOptions } from '~components/displays/utils';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { PortsControlLockState } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { DisplayControlsToggle, ToggleOptionsConfig } from '../DisplayControlsToggle';

interface Props {
  display: DisplayPortsControlToggle_DisplayFragment;
}

export function DisplayPortsControlToggle({ display }: Props) {
  const toast = useToast();
  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();
  const [updatePortsControlState, { loading }] = useUpdatePortsControlMutation();

  const handleChange = async (portsControlState: PortsControlLockState) => {
    const { id, portsControl } = display;

    try {
      updatePortsControlState({
        variables: {
          input: {
            displayIds: [id],
            portsControlState,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayBulkUpdatePortsControl: {
            __typename: 'DisplayBulkUpdatePortsControlPayload',
            displays: [
              {
                __typename: 'Display',
                id,
                portsControl: merge(cloneDeep(portsControl), {
                  desired: portsControlState,
                }),
              },
            ],
          },
        },
      });
    } catch (error) {
      toast({
        status: 'error',
        title: t('controlUpdateErr'),
        description: fromError(error, 'UpdatePortsControl'),
      });
    }
  };

  const portsControlOptions: Array<ToggleOptionsConfig<PortsControlLockState>> = [
    { value: PortsControlLockState.Locked, label: t('lockAllPorts') },
    { value: PortsControlLockState.Unlocked, label: t('unlockAllPorts') },
  ];
  const currentControlState = display.portsControl?.desired ?? display.portsControl?.reported;

  const controlOptions = toAvailableControlToggleOptions(
    portsControlOptions,
    display.portsControl?.supportedValues,
  );

  const isPending = isPropertyPending(display.portsControl);

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  if (isNil(currentControlState)) {
    return null;
  }

  return (
    <Stack direction="column" spacing="3">
      <FormLabel>
        {t('lockUSBMicroSD')}
        <FormLabelPendingIndicator isPending={isPending} />
      </FormLabel>

      <DisplayControlsToggle
        name="portsControlState"
        value={currentControlState}
        handleChange={handleChange}
        isLoading={loading}
        options={controlOptions}
        isDisabled={isDisplaySettingsUpdateDisabled}
      />
    </Stack>
  );
}

DisplayPortsControlToggle.graphql = {
  fragments: {
    DisplayInfraRedControlToggle_display: gql`
      fragment DisplayPortsControlToggle_display on Display {
        id
        portsControl {
          desired
          reported
          supportedValues
        }
      }
    `,
  },
  mutations: {
    UpdateInfraRedControl: gql`
      mutation UpdatePortsControl($input: DisplayBulkUpdatePortsControlInput!) {
        displayBulkUpdatePortsControl(input: $input) {
          displays {
            id
            portsControl {
              desired
              reported
              supportedValues
            }
          }
        }
      }
    `,
  },
};
