import { useTranslation } from 'react-i18next';
import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function ServiceSchedulePage() {
  const { t } = useTranslation();

  return (
    <PolicyPage title={t('service_schedule')} heading={t('ppds_wave')}>
      <div dangerouslySetInnerHTML={{ __html: t('serviceSchedule') }} />
    </PolicyPage>
  );
}
