import { gql } from '@apollo/client';
import { Box, Button, chakra, Link, Stack } from '@chakra-ui/react';
import { t } from 'i18next';
import AppsEmptyState from '~components/organization/AppsEmptyState';
import AppsOverviewTable from '~components/organization/AppsOverviewTable';
import { ExternalLinkIcon } from '~components/ui/icons';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useConditionalPolling } from '~components/useConditionalPolling';
import { useFeatureFlag } from '~utils/features';
import { useAppsPageQuery } from './__generated__/Apps.graphql';

const POLL_INTERVAL = 10000;

export function OrganizationAppsSettingsPage() {
  const { data, loading, error, startPolling, stopPolling } = useAppsPageQuery({
    pollInterval: POLL_INTERVAL,
  });

  const { isEnabled: isMarketPlaceLinkEnabled } = useFeatureFlag('appDirectMarketplaceLink');

  useConditionalPolling({ startPolling, stopPolling, pollInterval: POLL_INTERVAL });

  return (
    <VerticalTabContent title={t('apps')} isLoading={loading} error={error} data={data}>
      {({ organization }) => (
        <>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ sm: 6, md: 36 }} mb="10">
            <chakra.span color="gray.500">{t('appsMsg')}</chakra.span>
            {isMarketPlaceLinkEnabled && (
              <Link
                href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button variant="solid" colorScheme="blue">
                  <Stack direction="row" marginX="2" marginY="3">
                    <chakra.span textDecoration="none">{t('visitProStore')}</chakra.span>
                    <ExternalLinkIcon />
                  </Stack>
                </Button>
              </Link>
            )}
          </Stack>
          <Box
            boxShadow="elevated"
            border="1px solid"
            borderRadius="md"
            borderColor="gray.100"
            overflowX="auto"
            background="white"
          >
            {organization.appSubscriptions.length === 0 ? (
              <AppsEmptyState />
            ) : (
              <AppsOverviewTable subscriptions={organization.appSubscriptions} />
            )}
          </Box>
        </>
      )}
    </VerticalTabContent>
  );
}

OrganizationAppsSettingsPage.graphql = {
  queries: {
    AppsPage: gql`
      query AppsPage {
        organization {
          id
          appSubscriptions {
            ...OrganizationSubscriptions
          }
        }
      }
    `,
  },
};
