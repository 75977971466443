import { gql } from '@apollo/client';
import { chakra, FormControl, FormLabel, GridItem, Stack, Text, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import _ from 'lodash';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { PercentageInput } from '~components/ui/PercentageInput';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';
import {
  DisplayVolumeLimits_DisplayFragment,
  useUpdateVolumeLimitMaxMutation,
  useUpdateVolumeLimitMinMutation,
} from './__generated__/DisplayVolumeLimits.graphql';

interface Props {
  display: DisplayVolumeLimits_DisplayFragment;
}

export function DisplayVolumeLimits({ display }: Props) {
  const min = useVolumeMinLimit(display);
  const max = useVolumeMaxLimit(display);

  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();
  if (!min.isSupported && !max.isSupported) {
    return null;
  }

  const isDisplaySettingsUpdateDisabled = !verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);

  return (
    <>
      <GridItem colSpan={{ base: 8, md: 7 }} mt={10}>
        <FormLabel htmlFor="volumeLimits">
          {t('volumeLimits')}
          <FormLabelPendingIndicator
            isPending={
              isPropertyPending(display.volume?.limits?.min) ||
              isPropertyPending(display.volume?.limits?.max)
            }
          />
        </FormLabel>
        <Text color="gray.500" marginBottom="3">
          {t('volumeLimitsText')}
        </Text>
      </GridItem>
      <GridItem colSpan={{ base: 8, sm: 6, lg: 8, xl: 3 }}>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing="8">
          {min.isSupported && (
            <FormControl maxWidth="45%">
              <FormLabel>{t('min')}</FormLabel>
              <PercentageInput
                min={0}
                {...min.getInputProps()}
                id="volumeLimits"
                isDisabled={isDisplaySettingsUpdateDisabled}
              />
            </FormControl>
          )}
          {max.isSupported && (
            <FormControl maxWidth="45%">
              <FormLabel>{t('max')}</FormLabel>
              <PercentageInput
                max={100}
                {...max.getInputProps()}
                isDisabled={isDisplaySettingsUpdateDisabled}
              />
            </FormControl>
          )}
        </Stack>
        {isDefined(min.error || max.error) && (
          <chakra.span color="red.500" fontSize="sm">
            {min.error ?? max.error}
          </chakra.span>
        )}
      </GridItem>
    </>
  );
}

function useVolumeMinLimit(display: DisplayVolumeLimits_DisplayFragment) {
  const maxLimit = useMemo(
    () => display.volume?.limits?.max?.desired ?? display.volume?.limits?.max?.reported,
    [display],
  );
  const minLimit = useMemo(
    () => display.volume?.limits?.min?.desired ?? display.volume?.limits?.min?.reported,
    [display],
  );
  const [minValue, setMinValue] = useState(minLimit ?? 0);
  /**
   * Store the initial value in a ref.
   * This allows us to skip submitting the mutations if the value in the input hasn't changed at all.
   */
  const previousMin = useRef(minValue);
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const updateValue = minLimit ?? 0;

    setMinValue(updateValue);
    previousMin.current = updateValue;
  }, [minLimit]);

  useEffect(() => {
    if (minValue > (maxLimit ?? 100)) setError('Min value exceeds the max limit.');
    else if (minValue < 0 || _.isNaN(minValue)) setError('Values below 0 are not allowed.');
    else setError(undefined);
  }, [maxLimit, minLimit, minValue]);

  const toast = useToast();
  const [updateVolumeLimitMin] = useUpdateVolumeLimitMinMutation();
  const performUpdateVolumeLimitMin = useCallback(async () => {
    if (minValue === previousMin.current) return;
    if (error) return;

    try {
      await updateVolumeLimitMin({
        variables: {
          input: {
            id: display.id,
            min: minValue,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayUpdateVolumeLimitMin: {
            __typename: 'DisplayUpdateVolumeLimitMinPayload',
            display: {
              __typename: 'Display',
              id: display.id,
              volume: _.merge(_.cloneDeep(display.volume), {
                limits: { min: { desired: minValue } },
              }),
            },
          },
        },
      });
      // The initial value has changed so update the ref.
      previousMin.current = minValue;
    } catch (err) {
      toast({
        status: 'error',
        title: t('volumeMinimumErr'),
        description: fromError(err, 'UpdateVolumeLimitMin'),
      });
    }
  }, [minValue, error, updateVolumeLimitMin, display, toast, t]);

  const getInputProps = useCallback(() => {
    return {
      value: minValue,
      onChange: (val: string) => setMinValue(Number(val)),
      onBlur: () => performUpdateVolumeLimitMin(),
      onKeyUp: (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          performUpdateVolumeLimitMin();
        }
      },
    };
  }, [minValue, performUpdateVolumeLimitMin]);

  return useMemo(
    () => ({
      isSupported: !_.isNil(minLimit),
      value: minValue,
      error,
      getInputProps,
    }),
    [error, getInputProps, minLimit, minValue],
  );
}

function useVolumeMaxLimit(display: DisplayVolumeLimits_DisplayFragment) {
  const minLimit = useMemo(
    () => display.volume?.limits?.min?.desired ?? display.volume?.limits?.min?.reported,
    [display],
  );
  const maxLimit = useMemo(
    () => display.volume?.limits?.max?.desired ?? display.volume?.limits?.max?.reported,
    [display],
  );
  const [maxValue, setMaxValue] = useState(maxLimit ?? 0);
  /**
   * Store the initial value in a ref.
   * This allows us to skip submitting the mutations if the value in the input hasn't changed at all.
   */
  const previousMax = useRef(maxValue);
  const { t } = useTranslation();

  const [error, setError] = useState<string | undefined>(undefined);

  const toast = useToast();
  const [updateVolumeLimitMax] = useUpdateVolumeLimitMaxMutation();

  useEffect(() => {
    const updateValue = maxLimit ?? 100;

    setMaxValue(updateValue);
    previousMax.current = updateValue;
  }, [maxLimit]);

  useEffect(() => {
    if (maxValue < (minLimit ?? 0)) setError('Max value is lower than the min limit.');
    else if (maxValue > 100) setError('Values above 100 are not allowed.');
    else setError(undefined);
  }, [maxLimit, maxValue, minLimit]);

  const performUpdateVolumeLimitMax = useCallback(async () => {
    if (maxValue === previousMax.current) return;
    if (error) return;

    try {
      await updateVolumeLimitMax({
        variables: {
          input: {
            id: display.id,
            max: maxValue,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayUpdateVolumeLimitMax: {
            __typename: 'DisplayUpdateVolumeLimitMaxPayload',
            display: {
              __typename: 'Display',
              id: display.id,
              volume: _.merge(_.cloneDeep(display.volume), {
                limits: { max: { desired: maxValue } },
              }),
            },
          },
        },
      });
      // The initial value has changed so update the ref.
      previousMax.current = maxValue;
    } catch (err) {
      toast({
        status: 'error',
        title: t('volumeMaximumErr'),
        description: fromError(err, 'UpdateVolumeLimitMax'),
      });
    }
  }, [maxValue, error, updateVolumeLimitMax, display, toast, t]);

  const getInputProps = useCallback(() => {
    return {
      value: maxValue,
      onChange: (val: string) => setMaxValue(Number(val)),
      onBlur: () => performUpdateVolumeLimitMax(),
      onKeyUp: (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          performUpdateVolumeLimitMax();
        }
      },
    };
  }, [maxValue, performUpdateVolumeLimitMax]);

  return useMemo(
    () => ({
      isSupported: !_.isNil(maxLimit),
      value: maxValue,
      error,
      getInputProps,
    }),
    [error, getInputProps, maxLimit, maxValue],
  );
}

DisplayVolumeLimits.graphql = {
  fragments: {
    DisplayVolumeLimits_display: gql`
      fragment DisplayVolumeLimits_display on Display {
        id
        volume {
          limits {
            min {
              reported
              desired
            }
            max {
              reported
              desired
            }
          }
        }
      }
    `,
  },
  mutations: {
    UpdateVolumeLimitMin: gql`
      mutation UpdateVolumeLimitMin($input: DisplayUpdateVolumeLimitMinInput!) {
        displayUpdateVolumeLimitMin(input: $input) {
          display {
            id
            ...DisplayVolumeLimits_display
          }
        }
      }
    `,
    UpdateVolumeLimitMax: gql`
      mutation UpdateVolumeLimitMax($input: DisplayUpdateVolumeLimitMaxInput!) {
        displayUpdateVolumeLimitMax(input: $input) {
          display {
            id
            ...DisplayVolumeLimits_display
          }
        }
      }
    `,
  },
};
