import { Permission } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { useAuth } from '~auth/useAuth';
import { EmptyView, EmptyViewExternalLink, EmptyViewInternalLink } from '~components/EmptyView';
import { EmptySubscriptionsIllustration } from '~components/ui/illustrations/EmptySubscriptionsIllustration';
import { useFeatureFlag } from '~utils/features';

type UpgradeToWaveEssentialEmptyStateProps = {
  externalLink?: boolean;
  noSubscriptions?: boolean;
};

function UpgradeToWaveEssentialEmptyState({
  externalLink = false,
  noSubscriptions = false,
}: UpgradeToWaveEssentialEmptyStateProps) {
  const { isEnabled: isAppDirectMarketplaceLinkEnabled } = useFeatureFlag(
    'appDirectMarketplaceLink',
  );

  const { verifyUserPermissions, organization } = useAuth();
  const baseURL = organization && `/${organization.handle}`;

  const canManageSubscriptions = verifyUserPermissions([Permission.WaveSubscriptionUpdate]);

  return (
    <EmptyView
      icon={<EmptySubscriptionsIllustration />}
      title={noSubscriptions ? t('emptyState') : t('emptyStateTitle')}
      description={
        canManageSubscriptions
          ? externalLink
            ? t('emptyStateDesc')
            : t('emptyStateDesc1')
          : t('emptyStateDesc2')
      }
      theme="purple"
    >
      {externalLink ? (
        <>
          {isAppDirectMarketplaceLinkEnabled && (
            <EmptyViewExternalLink
              href={process.env.REACT_APP_APPDIRECT_MARKETPLACE_LINK}
              label={t('purchaseOnProStore')}
            />
          )}
        </>
      ) : (
        <>
          {canManageSubscriptions && (
            <EmptyViewInternalLink
              label={t('manageSubcriptions')}
              to={`${baseURL}/settings/subscriptions`}
            />
          )}
        </>
      )}
    </EmptyView>
  );
}

export default UpgradeToWaveEssentialEmptyState;
