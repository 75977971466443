import { Button, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { Link } from '~components/ui/Link';
import { Page } from '~components/ui/Page';
import { PageContent } from '~components/ui/PageContent';

export function CustomerNotFoundPage() {
  return (
    <Page title="404" pageName="customers_404" analyticsDisableTracking={true}>
      <PageContent maxWidth="300px" textAlign="center">
        <Text fontWeight="semibold" color="gray.600">
          {t('unauthorisedCustomerErr')}
        </Text>

        <Text marginTop="8" fontSize="sm">
          {t('unauthorisedCustomerErrMsg')}{' '}
          <Button as={Link} variant="link" size="sm" colorScheme="blue" to="../">
            {t('unauthorisedCustomerErrMsg1')}
          </Button>
        </Text>
      </PageContent>
    </Page>
  );
}
