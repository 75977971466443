import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { ClaimCodeTable_ClaimCodeFragment } from '../__generated__/ClaimCodeTable.graphql';

export function ValidityCell({
  value,
}: PropsWithChildren<CellProps<ClaimCodeTable_ClaimCodeFragment, number>>) {
  const expiryDate = new Date(value); // Convert value to Date object
  const currentDate = new Date();
  const remainingDays = Math.ceil(
    (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
  );
  const isExpired = remainingDays <= 0;
  return (
    <Box
      display="inline-block"
      padding="4px 8px"
      borderRadius="8px"
      textAlign="center"
      backgroundColor={isExpired ? 'red.100' : 'green.100'}
      color={'gray.700'}
      fontWeight="bold"
      fontSize="xs"
    >
      {isExpired ? t('expired') : `${remainingDays}` + ' ' + t('DAYS')}
    </Box>
  );
}

ValidityCell.graphql = {
  fragments: {
    ValidityCell_claimCode: gql`
      fragment ValidityCell_claimCode on MultiClaimCodeDocument {
        id
        expiry
      }
    `,
  },
};
