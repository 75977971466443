import { gql } from '@apollo/client';
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { Ref, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~auth/useAuth';
import { BulkManageApps } from '~components/displays/BulkAction/BulkManageApps';
import { BulkUpdateFirmware } from '~components/displays/BulkAction/BulkUpdateFirmware';
import { ChevronRightIcon, SearchIcon } from '~components/ui/icons';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { isDefined } from '~utils/types';
import {
  BulkActionModal_CustomerFragment,
  BulkActionModal_DisplayFragment,
  BulkActionModal_OrganizationFragment,
} from './__generated__/BulkActionModal.graphql';
import { BulkAddGroups } from './BulkAddGroups';
import { BulkEditSite } from './BulkEditSite';
import { BulkEditWebPages } from './BulkEditWebPages';
import { BulkManagePlaylist } from './BulkManagePlaylist';
import { BulkManagePowerSchedule } from './BulkManagePowerSchedule';
import { BulkRemoveGroups } from './BulkRemoveGroups';
import { BulkSyncDisplays } from './BulkSyncDisplays';
import { BulkUpdateAutoRestart } from './BulkUpdateAutoRestart';
import { BulkUpdateContentSource } from './BulkUpdateContentSource';
import { BulkUpdatePower } from './BulkUpdatePower';
import { BulkUpdateVolume } from './BulkUpdateVolume';

interface Props {
  displays: BulkActionModal_DisplayFragment[];
  customer: BulkActionModal_CustomerFragment;
  organization: BulkActionModal_OrganizationFragment;
  initialFocusRef?: Ref<HTMLButtonElement>;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
  onBack?: () => Promise<void> | void;
}

export type BulkActionComponent = ((props: Props) => JSX.Element) & {
  graphql?: unknown;
};

type Action = {
  id: string;
  name: string;
  component: BulkActionComponent;
};

export function BulkActionModal(props: Props) {
  const { isOpen, onCancel } = props;
  const initialFocusRef = useRef(null);

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onCancel} closeOnEsc>
      <ModalOverlay />
      <BulkActionModalContent initialFocusRef={initialFocusRef} {...props} />
    </Modal>
  );
}

function BulkActionModalContent(props: Props) {
  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();
  const hasDisplaySettingsUpdatePermission = verifyUserPermissions([
    Permission.DisplaySettingsUpdate,
  ]);
  const hasDisplaySiteUpdatePermission = verifyUserPermissions([Permission.DisplaySiteUpdate]);
  const hasDisplayGroupAssignPermission = verifyUserPermissions([Permission.DisplayGroupAssign]);
  const hasDisplayGroupUnassignPermission = verifyUserPermissions([
    Permission.DisplayGroupUnassign,
  ]);
  const hasDisplayFirmwareUpdatePermission = verifyUserPermissions([
    Permission.DisplayFirmwareUpdate,
  ]);
  const hasAppInstallPermission = verifyUserPermissions([Permission.AppInstall]);

  const actions: Action[] = [
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'power',
          name: t('onStandby'),
          component: BulkUpdatePower,
        }
      : undefined,
    hasDisplaySiteUpdatePermission
      ? {
          id: 'edit-site',
          name: t('bulkEditSite'),
          component: BulkEditSite,
        }
      : undefined,
    hasDisplayGroupAssignPermission
      ? {
          id: 'add-groups',
          name: t('addGroups'),
          component: BulkAddGroups,
        }
      : undefined,
    hasDisplayGroupUnassignPermission
      ? {
          id: 'remove-groups',
          name: t('removeGroups'),
          component: BulkRemoveGroups,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'edit-web-pages',
          name: t('editWebPages'),
          component: BulkEditWebPages,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'update-content-source',
          name: t('changeInputSource'),
          component: BulkUpdateContentSource,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'update-volume',
          name: t('changeAudioSettings'),
          component: BulkUpdateVolume,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'manage-playlist',
          name: t('managePlaylist'),
          component: BulkManagePlaylist,
        }
      : undefined,
    hasAppInstallPermission
      ? {
          id: 'manage-apps',
          name: t('manageApplications'),
          component: BulkManageApps,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'sync-playlists',
          name: t('syncPlaylists'),
          component: BulkSyncDisplays,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'manage-power-schedule',
          name: t('managePowerSchedule'),
          component: BulkManagePowerSchedule,
        }
      : undefined,
    hasDisplayFirmwareUpdatePermission
      ? {
          id: 'update-firmware',
          name: t('updateFirmware'),
          component: BulkUpdateFirmware,
        }
      : undefined,
    hasDisplaySettingsUpdatePermission
      ? {
          id: 'update-auto-restart',
          name: t('autoRestart'),
          component: BulkUpdateAutoRestart,
        }
      : undefined,
  ].filter(isDefined);

  const canExecuteBulkActions = actions.length > 0;

  const { onCancel } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [action, setAction] = useState<Action | null>(null);

  const searchedActions = useMemo(() => {
    return searchValue === ''
      ? actions
      : actions.filter((filter) => filter.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [actions, searchValue]);

  return action === null ? (
    <ModalContent>
      <ModalHeader>{t('bulkAction')}</ModalHeader>
      <ModalCloseButton onClick={onCancel} />

      <ModalBody>
        {canExecuteBulkActions && (
          <InputGroup mb="4" width="100%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.500" />
            </InputLeftElement>

            <Input
              onChange={(e) => setSearchValue(e.target.value)}
              bgColor="gray.50"
              borderColor="gray.100"
              placeholder={t('searchActions')}
            />
          </InputGroup>
        )}

        <VStack spacing="1" minHeight="36">
          {canExecuteBulkActions ? (
            searchedActions.map((action, index) => {
              return (
                <Button
                  key={action.id}
                  ref={index === 0 ? props.initialFocusRef : undefined}
                  rightIcon={<ChevronRightIcon />}
                  variant="ghost"
                  width="100%"
                  justifyContent="space-between"
                  paddingLeft="1"
                  paddingRight="1"
                  paddingY="1"
                  border="none"
                  color="blue.800"
                  fontWeight="regular"
                  _hover={{
                    fontWeight: 'semibold',
                  }}
                  _active={{
                    background: 'inherit',
                  }}
                  onClick={() => {
                    setAction(action);
                  }}
                >
                  {action.name}
                </Button>
              );
            })
          ) : (
            <Text color="#5A779A">{t('bulkActionsPermissionsError')}</Text>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </ModalContent>
  ) : (
    <action.component onBack={() => setAction(null)} {...props} />
  );
}

BulkActionModal.graphql = {
  fragments: {
    BulkActionModal_display: gql`
      fragment BulkActionModal_display on Display {
        id
        ...BulkRemoveGroups_display
        ...BulkUpdatePower_display
        ...BulkUpdateVolume_display
        ...BulkSyncDisplays_display
        ...BulkEditWebPages_display
        ...BulkUpdateContentSource_display
        ...BulkUpdateFirmware_display
        ...BulkManageApps_display
        ...BulkUpdateAutoRestart_display
      }
    `,
    BulkActionModal_customer: gql`
      fragment BulkActionModal_customer on Customer {
        id
        ...BulkEditSite_customer
        ...BulkAddGroups_customer
        ...BulkManagePlaylist_customer
        ...BulkManagePowerSchedule_customer
      }
    `,
    BulkActionModal_organization: gql`
      fragment BulkActionModal_organization on Organization {
        id
        ...BulkManageApps_organization
      }
    `,
  },
};
