import { Box, Button, chakra, Flex, HStack, Input, Text, Tooltip, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import _ from 'lodash';
import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { useDestructiveAction } from '~components/ui/DestructiveAction';
import { TrashIcon } from '~components/ui/icons';

type Props = {
  onChange: (image?: File) => void;
  onError: (error: Error) => void;
  imageUrl?: string | undefined;
  borderRadius?: string | number;
  color?: string;
  previewComponent?: (imageUrl?: string) => ReactNode;
  title?: string;
  clearLabel?: string;
  confirmToDelete?: boolean;
};

const MAX_FILE_SIZE = 512000;
const MIN_FILE_SIZE = 1;
const MAX_FILE_SIZE_TEXT = '500 KB';
const IMAGE_WIDTH = 100;
const IMAGE_HEIGHT = 100;
const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png'];

export function ImageInput({
  onChange,
  onError,
  imageUrl,
  borderRadius,
  previewComponent,
  title,
  clearLabel,
  confirmToDelete = false,
}: Props) {
  const [preview, setPreview] = useState<string | undefined>(imageUrl);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (_.isNil(event.target.files) || _.isEmpty(event.target.files)) {
        setPreview(imageUrl);
        return;
      }

      const fileData = event.target.files[0];

      if (fileData.size > MAX_FILE_SIZE || fileData.size < MIN_FILE_SIZE) {
        onError(new Error('Invalid image selected. The size exceeded the allowed limits.'));
        return;
      }

      if (!ALLOWED_MIME_TYPES.includes(fileData.type)) {
        onError(
          new Error(
            `Invalid image selected. The type "${
              fileData.type.split('/')[1] ?? fileData.type
            }" is not supported.`,
          ),
        );
        return;
      }

      setPreview(URL.createObjectURL(fileData));
      onChange(fileData);
    },
    [imageUrl, onChange, onError],
  );

  const clearImage = useCallback(() => {
    setPreview(undefined);
    onChange(undefined);
  }, [onChange]);

  const clearImageAction = useDestructiveAction({
    title: clearLabel,
    message: () => <>{t('confirmClearImage')}</>,
    onConfirm: clearImage,
  });

  const handleRemove = useCallback(async () => {
    confirmToDelete ? clearImageAction.askConfirmation(undefined) : clearImage();
  }, [clearImage, clearImageAction, confirmToDelete]);

  return (
    <Flex flexDirection={'column'}>
      <HStack spacing="4">
        {previewComponent ? (
          <>{previewComponent(preview ?? imageUrl)}</>
        ) : (
          <Box
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            borderRadius={borderRadius ?? 'full'}
            overflow="hidden"
            {...(preview || imageUrl
              ? { background: `url(${preview ?? imageUrl}) no-repeat center` }
              : {})}
            backgroundColor="gray.200"
            backgroundSize="cover"
          />
        )}

        <VStack alignItems="left">
          {title && (
            <Text color="blue.800" fontWeight="600">
              {title}
            </Text>
          )}
          <HStack>
            <chakra.label
              sx={{
                padding: 1.5,
                paddingLeft: 4,
                paddingRight: 4,
                backgroundColor: 'gray.50',
                borderColor: 'gray.200',
                borderRadius: borderRadius ?? 5,
                borderWidth: '1px',
                color: 'blue.800',
                cursor: 'pointer',
                fontWeight: 'semibold',
                width: 'max-content',
                borderStyle: 'solid',
              }}
              _hover={{
                backgroundColor: 'gray.100',
              }}
            >
              <Input
                type="file"
                id={'file'}
                onChange={handleChange}
                variant={'unstyled'}
                required={false}
                accept={ALLOWED_MIME_TYPES.join(',')}
                css={{ opacity: 0, width: '0.1px', height: '0.1px', position: 'absolute' }}
              />
              {t('chooseFile')}
            </chakra.label>

            {preview ?? imageUrl ? (
              <>
                <Tooltip label={clearLabel || 'Clear image'} placement="top">
                  <Button size="sm" variant="inline" colorScheme="gray" onClick={handleRemove}>
                    <TrashIcon color="gray.500" width="actionIconSize" height="actionIconSize" />
                  </Button>
                </Tooltip>
                {clearImageAction.confirmationNode}
              </>
            ) : null}
          </HStack>
          <Text color="gray.500">
            {t('maxFileSize')} {MAX_FILE_SIZE_TEXT}.
          </Text>
        </VStack>
      </HStack>
    </Flex>
  );
}
