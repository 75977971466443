import { gql } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import React, { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { useCreatePowerScheduleMutation } from './__generated__/CreatePowerScheduleModal.graphql';

const FormData = z.object({
  customerId: z.string(),
  title: z
    .string()
    .min(1)
    .regex(/.*\S.*/, t('titleSpaceWarn')),
  description: z.string().optional(),
});
type FormData = z.infer<typeof FormData>;

interface Props {
  customerId: string;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (id: string) => Promise<void> | void;
}

export function CreatePowerScheduleModal({ customerId, isOpen, onCancel, onSuccess }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <CreatePowerScheduleModalContent
          customerId={customerId}
          initialFocusRef={initialFocusRef}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

type ContentProps = {
  customerId: string;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => void;
  onSuccess: (id: string) => Promise<void> | void;
};

function CreatePowerScheduleModalContent({
  customerId,
  initialFocusRef,
  onCancel,
  onSuccess,
}: ContentProps) {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      customerId,
      title: '',
    },
    mode: 'onChange',
    resolver: zodResolver(FormData),
  });
  const { ref: titleInputRef, ...titleInputProps } = register('title');

  const [createPowerSchedule] = useCreatePowerScheduleMutation();

  const onSubmit = useCallback(
    async ({ customerId, title, description }: FormData) => {
      try {
        const { data } = await createPowerSchedule({
          variables: {
            input: {
              customerId,
              title,
              description,
            },
          },
        });

        if (!data || !data.powerScheduleCreate.powerSchedule) {
          throw new Error('Create power schedule failed');
        }

        await onSuccess?.(data.powerScheduleCreate.powerSchedule.id);
      } catch (err) {
        toast({
          status: 'error',
          title: t('powerScheduleErr'),
          description: fromError(err, 'CreatePowerSchedule', {
            POWER_SCHEDULE_LIMIT_REACHED: t('powerScheduleErrDesc'),
            POWER_SCHEDULE_EMPTY_TITLE: t('powerScheduleErrDesc1'),
          }),
        });
      }
    },
    [toast, createPowerSchedule, onSuccess],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>{t('newPowerSchedule')}</ModalHeader>
      <ModalCloseButton onClick={onCancel} />
      <ModalBody>
        <VStack>
          <FormControl isRequired isInvalid={Boolean(errors.title)} marginBottom={5}>
            <FormLabel>{t('scheduleTitle')}</FormLabel>
            <Input
              ref={(r) => {
                titleInputRef(r);
                initialFocusRef.current = r;
              }}
              {...titleInputProps}
            />
            <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.description)}>
            <FormLabel>{t('description')}</FormLabel>
            <Textarea {...register('description')} background="white" borderRadius="base" />
            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" onClick={onCancel} isDisabled={isSubmitting}>
          {t('cancel')}
        </Button>
        <Button
          variant="solid"
          colorScheme="blue"
          marginLeft="3"
          type="submit"
          isDisabled={!isDirty || isSubmitting || Object.keys(errors).length > 0}
          isLoading={isSubmitting}
        >
          {t('createSchedule')}
        </Button>
      </ModalFooter>
    </form>
  );
}

CreatePowerScheduleModal.graphql = {
  mutations: {
    CreatePowerSchedule: gql`
      mutation CreatePowerSchedule($input: PowerScheduleCreateInput!) {
        powerScheduleCreate(input: $input) {
          powerSchedule {
            id
            ...PowerScheduleTable_powerSchedule
          }
        }
      }
    `,
  },
};
