import { Permission, Role } from '@tp-vision/roles-permissions';
import jwtDecode from 'jwt-decode';
import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

export interface AuthUser {
  name?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  permissions?: Permission[];
  roles?: Role[];
  'https://keat.io/audiences'?: string[];
  locale: string;
  universalLocale: string;
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [locale, setLocale] = useState<string>('en-GB');
  const [universalLocale, setUniversalLocale] = useState<string>('');

  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }

  const auth = useContext(context);

  if (auth.isAuthenticated && permissions.length === 0) {
    auth.getAccessTokenSilently().then((token) => {
      const decodedToken = jwtDecode<{
        permissions?: Permission[];
        'https://wave.ppds.com/user_roles'?: Role[];
        'https://wave.ppds.com/locale'?: string;
        'https://wave.ppds.com/universalLocale'?: string;
      }>(token);

      const tokenRoles = decodedToken['https://wave.ppds.com/user_roles'] ?? [];
      const tokenPermissions = decodedToken.permissions ?? [];
      const tokenlocale = decodedToken['https://wave.ppds.com/locale'] ?? 'en-GB';
      const tokenUniversalLocale = decodedToken['https://wave.ppds.com/universalLocale'] ?? '';
      if (!isEqual(tokenRoles, roles)) {
        setRoles(tokenRoles);
      }

      if (!isEqual(tokenPermissions, permissions)) {
        setPermissions(tokenPermissions);
      }
      if (!isEqual(tokenlocale, locale)) {
        setLocale(tokenlocale);
      }
      if (!isEqual(tokenUniversalLocale, universalLocale)) {
        setUniversalLocale(tokenUniversalLocale);
      }
    });
  }

  const user: AuthUser = {
    ...auth.user,
    name: auth.user?.name,
    givenName: auth.user?.given_name,
    familyName: auth.user?.family_name,
    email: auth.user?.email,
    'https://keat.io/audiences': auth.user?.['https://keat.io/audiences'],
    permissions,
    roles,
    locale,
    universalLocale,
  };

  return {
    ...auth,
    user,
  };
}
