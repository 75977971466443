import { Flex, HStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { LandscapeIcon, LoadingIcon, WarningIcon } from '~components/ui/icons';
import { Stat } from '~components/ui/Stat';

type Props = {
  onClickSyncedDisplays?: () => void;
  onClickOutOfSyncDisplays?: () => void;
  outOfSyncCount: number;
  removingCount: number;
  syncingCount: number;
  syncedCount: number;
};

export function DisplaysDetailStats({
  onClickSyncedDisplays,
  onClickOutOfSyncDisplays,
  outOfSyncCount,
  removingCount,
  syncingCount,
  syncedCount,
}: Props) {
  return (
    <Flex justifyContent="space-between" marginBottom="4">
      <HStack spacing="12">
        <Stat
          icon={LandscapeIcon}
          amount={syncedCount}
          label={syncedCount === 1 ? t('Display') : t('Displays')}
          backgroundColor="gray.50"
          iconColor="blue.700"
          onClick={onClickSyncedDisplays}
        />
        {outOfSyncCount > 0 && (
          <Stat
            icon={WarningIcon}
            amount={outOfSyncCount}
            label={t('OutOfSync')}
            backgroundColor="orange.50"
            iconColor="orange.400"
            onClick={onClickOutOfSyncDisplays}
          />
        )}
        {syncingCount > 0 && (
          <Stat
            icon={LoadingIcon}
            amount={syncingCount}
            label={t('Syncing')}
            backgroundColor="gray.50"
            iconColor="gray.400"
          />
        )}
        {removingCount > 0 && (
          <Stat
            icon={LoadingIcon}
            amount={removingCount}
            label={t('Removing')}
            backgroundColor="gray.50"
            iconColor="gray.400"
          />
        )}
      </HStack>
    </Flex>
  );
}
