import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { FilterOption } from '~components/displays/DisplayTable/constants';
import { ChevronDownIcon, SearchIcon } from '~components/ui/icons';
import { StopClickPropagation } from '~components/ui/StopClickPropagation';

export function FilterSection({
  name,
  filters,
  onToggle,
}: {
  name: string;
  filters: {
    all: FilterOption[];
    enabled: FilterOption[];
  };
  onToggle: (filter: FilterOption) => void;
}) {
  const [searchValue, setSearchValue] = useState<string>('');
  const filterGrid = useRef<HTMLDivElement | null>(null);

  const searchedFilters = useMemo(() => {
    return searchValue === ''
      ? filters.all
      : filters.all.filter((filter) =>
          filter.label.toLowerCase().includes(searchValue.toLowerCase()),
        );
  }, [filters.all, searchValue]);

  const scroll = () => {
    if (!filterGrid.current) return;
    filterGrid.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  };

  const filterCount = filters.enabled.length;

  return (
    <AccordionItem position="relative">
      {({ isExpanded }) => (
        <>
          <chakra.h2 borderBottom={isExpanded ? '1px solid' : 'none'} borderBottomColor="gray.50">
            <AccordionButton paddingY="4" _hover={{ background: 'inherit' }}>
              <ChevronDownIcon
                transform={isExpanded ? 'rotate(180deg)' : ''}
                transition="transform .2s"
                transitionTimingFunction="ease-in-out"
              />
              <Box
                flex="1"
                ml="3"
                textAlign="left"
                fontWeight="semibold"
                fontSize="sm"
                color="gray.900"
              >
                {capitalize(name)}
                {filterCount > 0 && (
                  <chakra.span marginLeft="4" color="blue.500">
                    {filterCount}
                  </chakra.span>
                )}
              </Box>
            </AccordionButton>
          </chakra.h2>
          <AccordionPanel bgColor="gray.50" maxHeight="48" overflow="auto" pb={4} fontSize="sm">
            <InputGroup mt="2" mb="4">
              <InputLeftElement pointerEvents="none">
                <SearchIcon bgColor="white" color="gray.500" />
              </InputLeftElement>
              <Input
                onChange={(e) => setSearchValue(e.target.value)}
                borderColor="gray.100"
                bgColor="white"
                placeholder={`Search ${name}`}
              ></Input>
            </InputGroup>
            <CheckboxGroup value={filters.enabled.map((filter) => filter.value)}>
              <VStack ref={filterGrid} spacing={4} alignItems="flex-start">
                {searchedFilters.length === 0 ? (
                  <Text color="gray.400">{t('noItemFound', { name: name })}</Text>
                ) : (
                  searchedFilters.map((filter) => (
                    <Checkbox
                      colorScheme="blue"
                      key={filter.value}
                      value={filter.value}
                      onChange={() => onToggle(filter)}
                    >
                      {filter.label}
                    </Checkbox>
                  ))
                )}
              </VStack>
            </CheckboxGroup>
            {isExpanded && searchedFilters.length > 6 ? (
              <StopClickPropagation>
                <IconButton
                  zIndex="10"
                  position="absolute"
                  bottom="4"
                  right="6"
                  size="xs"
                  rounded="full"
                  variant="solid"
                  background="white"
                  onClick={scroll}
                  aria-label="scroll"
                  icon={<ChevronDownIcon border="none" />}
                  _hover={{
                    background: 'gray.100',
                  }}
                />
              </StopClickPropagation>
            ) : null}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}
