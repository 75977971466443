import { ApolloError, gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerClaimCodeTable_ClaimCodeFragment } from '~components/displays/ClaimCodeTable/__generated__/ClaimCodeTable.graphql';
import {
  ClaimCodeTable,
  useClaimCodeTable,
} from '~components/displays/ClaimCodeTable/ClaimCodeTable';
import {
  ClaimCodeTableContext,
  ClaimCodeTableProvider,
} from '~components/displays/ClaimCodeTable/ClaimCodeTableContext';
import { FilterOption } from '~components/displays/ClaimCodeTable/constants';
import { DisplaysQueryContext } from '~components/displays/DisplayTable/useDisplaysQuery';
import { BackButton } from '~components/ui/BackButton';
import { Page } from '~components/ui/Page';
import { PageActions } from '~components/ui/PageActions';
import { PageContent } from '~components/ui/PageContent';
import { PageHeading } from '~components/ui/PageHeading';
import { PageLoader } from '~components/ui/PageLoader';
import { HandleApiError } from '~graphql/HandleApiError';
import { ensure, isDefined } from '~utils/types';
import { ClaimCodePageQuery, useClaimCodePageQuery } from './__generated__/claimCode.graphql';

export type DataWithGroupSite = Omit<ClaimCodePageQuery, 'customer'> & {
  customer: ClaimCodePageQuery['customer'];
};

export type MultiClaimFilters = Array<{ id: string; value: FilterOption[] }>;

type CustomerClaimCodeOverviewProps = {
  data: DataWithGroupSite;
  error: ApolloError | undefined;
  pageIndex: number;
  pageSize: number;
  filters: MultiClaimFilters;
  totalRowCount: number;
  search: string;
  handleSetSearch: (searchText: string) => void;
  handleSetPageSize: (pageSize: number) => void;
  handleSetPageIndex: (pageIndex: number) => void;
  handleSetFilters: (filterId: string, newValue: FilterOption[]) => void;
};

function ClaimCodeOverviewHeading() {
  const navigate = useNavigate();

  const tableContext = useContext(ClaimCodeTableContext);
  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }

  const handleGoBack = () => {
    navigate('../displays');
  };

  return (
    <>
      <PageHeading floatingButton={<BackButton onClick={handleGoBack} />}>
        {t('claimCodeHistory')}
      </PageHeading>
    </>
  );
}

function ClaimCodeOverview({
  data,
  pageIndex,
  pageSize,
  filters,
  handleSetPageSize,
  handleSetPageIndex,
  totalRowCount,
  handleSetFilters,
  search,
  handleSetSearch,
}: CustomerClaimCodeOverviewProps) {
  const navigate = useNavigate();
  const table = useClaimCodeTable({
    data: data?.customer?.multiClaimCodes ?? [],
    customerData: (data?.customer as CustomerClaimCodeTable_ClaimCodeFragment) || [],
    pageIndex,
    pageSize,
    totalRowCount,
  });
  const { setDisplaysQuery } = useContext(DisplaysQueryContext);
  const tableContext = useContext(ClaimCodeTableContext);

  if (isNil(tableContext)) {
    throw new Error('TableContext can only be used within TableContext Provider');
  }
  const { setTable } = tableContext;

  useEffect(() => {
    setTable?.(table);
  }, [table, setTable]);

  async function handleClaimCodeClicked(id: string, code: string, name: string) {
    if (!setDisplaysQuery) return;
    const combinedClaimCode = `${code}|${name}|${id}`;
    const newQueryParams = new URLSearchParams();
    newQueryParams.set('claimCode', combinedClaimCode);
    setDisplaysQuery(newQueryParams);
    navigate(`../displays?claimCode=${encodeURIComponent(combinedClaimCode)}`);
  }

  return (
    <>
      <PageActions
        left={
          <>
            <ClaimCodeTable.Filter
              table={table}
              data={data}
              filtersState={filters}
              handleSetFilters={handleSetFilters}
            />
          </>
        }
        searchbar={
          <ClaimCodeTable.SearchSelector search={search} handleSetSearch={handleSetSearch} />
        }
      />
      <Box
        boxShadow="elevated"
        border="1px solid"
        borderRadius="md"
        borderColor="gray.100"
        overflowX="auto"
        bgColor="white"
      >
        <ClaimCodeTable
          table={table}
          onGoToDisplays={(id, code, name) => handleClaimCodeClicked(id, code, name)}
        />
      </Box>
      <Box display="flex" marginTop="4">
        <Box flex="1">
          <ClaimCodeTable.PageSizeSelector
            table={table}
            handleSetPageSize={handleSetPageSize}
            pageSize={pageSize}
          />
        </Box>
        <ClaimCodeTable.Pagination
          pageIndex={pageIndex}
          totalRowCount={totalRowCount}
          handleSetPageIndex={handleSetPageIndex}
          pageSize={pageSize}
        />
      </Box>
    </>
  );
}
export function ClaimCodePage() {
  const { customer } = useParams();

  const customerHandle = ensure(customer);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<MultiClaimFilters>([
    { id: 'groups', value: [] },
    { id: 'site', value: [] },
  ]);
  interface TransformedResult {
    groupIds: string[];
    siteIds: string[];
  }
  function transformFiltersData(filters: MultiClaimFilters): TransformedResult {
    const transformed: TransformedResult = {
      groupIds: [],
      siteIds: [],
    };

    filters.forEach((filter) => {
      switch (filter.id) {
        case 'groups':
          transformed.groupIds = filter.value.map((option) => option.value);
          break;
        case 'site':
          transformed.siteIds = filter.value.map((option) => option.value);
          break;
        default:
          break;
      }
    });

    return transformed;
  }
  const transformedFilters = transformFiltersData(filters);
  const handleSetPageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handleSetPageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };

  const handleSetFilters = (filterId: string, newValue: FilterOption[]) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === filterId ? { ...filter, value: newValue } : filter,
      ),
    );
  };

  const handleSetSearch = (searchText: string) => {
    setSearch(searchText);
  };

  const { data, loading, error } = useClaimCodePageQuery({
    variables: {
      customerHandle,
      pageNumber: pageIndex + 1,
      pageSize: pageSize,
      searchTerm: search,
      filter: transformedFilters,
    },
  });
  const totalRowCount = data?.customer?.totalFilteredMultiClaimCount || 0;

  return (
    <Page title="Claim codes" pageName="claimCode_overview">
      <ClaimCodeTableProvider>
        {!loading && !error && (
          <PageContent backgroundColor="gray.50">
            <ClaimCodeOverviewHeading />
          </PageContent>
        )}
        <PageContent>
          {loading ? (
            <PageLoader />
          ) : error ? (
            <HandleApiError error={error} />
          ) : (
            isDefined(data?.customer) && (
              <ClaimCodeOverview
                data={data as DataWithGroupSite}
                error={error}
                pageSize={pageSize}
                pageIndex={pageIndex}
                totalRowCount={totalRowCount}
                filters={filters}
                search={search}
                handleSetSearch={handleSetSearch}
                handleSetPageSize={handleSetPageSize}
                handleSetPageIndex={handleSetPageIndex}
                handleSetFilters={handleSetFilters}
              />
            )
          )}
        </PageContent>
      </ClaimCodeTableProvider>
    </Page>
  );
}

ClaimCodePage.graphql = {
  queries: {
    ClaimCodePage: gql`
      query ClaimCodePage(
        $customerHandle: String!
        $pageNumber: Int
        $pageSize: Int
        $searchTerm: String
        $filter: MultiClaimFilters
      ) {
        customer: customerByHandle(handle: $customerHandle) {
          id
          groups {
            id
            name
          }
          sites {
            name
            id
          }
          multiClaimCodes(
            pageNumber: $pageNumber
            pageSize: $pageSize
            searchTerm: $searchTerm
            filter: $filter
          ) {
            ...ClaimCodeTable_claimCode
            createdAt
            customerId
            organizationId
            state
            usageCount
          }
          totalFilteredMultiClaimCount(searchTerm: $searchTerm, filter: $filter)
        }
      }
    `,
  },
};
