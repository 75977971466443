import { Avatar, Box, Button, Image, useDisclosure } from '@chakra-ui/react';
import ClickAwayListener from 'react-click-away-listener';
import { MenuBackdrop, MenuItem } from '~components/MenuBackdrop';
import creator from '~components/ui/assets/creator.png';
import prostore from '~components/ui/assets/prostore.png';
import { AppsIcon } from '~components/ui/icons';
import { useTheme } from '~components/ui/styles/hooks';
import { useDimensions } from '~utils/dimensions';

export function ApplicationMenu() {
  const theme = useTheme();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [ref] = useDimensions<HTMLButtonElement>();
  const PROSTORE_URL = process.env.REACT_APP_PROSTORE_URL ?? '';
  const CREATOR_URL = process.env.REACT_APP_CREATOR_URL ?? '';

  return (
    <Box display="flex" alignItems="center">
      <ClickAwayListener onClickAway={onClose}>
        <Box position="relative" display="flex" alignItems="center">
          <Button
            aria-haspopup={true}
            aria-expanded={isOpen}
            ref={ref}
            variant="unstyled"
            height="auto"
            display="flex"
            onClick={onToggle}
          >
            <Avatar
              background={isOpen ? 'white' : 'gray.50'}
              color={isOpen ? 'white' : 'gray.900'}
              icon={<AppsIcon color={isOpen ? 'blue.500' : 'black'} height="6" width="6" />}
              border={isOpen ? `2px solid ${theme.colors.blue[500]}` : '2px solid transparent'}
              _hover={{
                border: '2px solid',
                borderColor: 'blue.500',
              }}
            />
          </Button>

          <MenuBackdrop width={theme.sizes[64]} isOpen={isOpen} right>
            <Box height="1px" background="gray.50" width="100%" marginTop="2" />
            <MenuItem
              to={PROSTORE_URL}
              onClick={onClose}
              isExternal
              icon={<Image src={prostore} boxSize="24px" />}
            >
              Wave Prostore
            </MenuItem>

            <MenuItem
              to={CREATOR_URL}
              onClick={onClose}
              isExternal
              icon={<Image src={creator} boxSize="24px" />}
            >
              Wave Creator
            </MenuItem>
          </MenuBackdrop>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
