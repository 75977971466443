import { gql } from '@apollo/client';
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { ArrowLeftIcon } from '~components/ui/icons';
import { components } from '~components/ui/Select';
import { BulkClaimDisplayGroupsSelect } from '../../displays/BulkClaimDisplayGroupSelect';
import {
  BulkClaimDisplayGroupsSelect_CustomerFragment,
  BulkClaimDisplayGroupsSelect_GroupFragment,
} from '../__generated__/BulkClaimDisplayGroupSelect.graphql';
import { BulkClaimDisplaySiteSelect_CustomerFragment } from '../__generated__/BulkClaimDisplaySiteSelect.graphql';
import { useDisplayInlineGrouppsSelect_GroupCreateMutation } from '../DisplayDetail/general/__generated__/DisplayInlineGroupsSelect.graphql';
import { useActiveMultiClaimUpdateMutation } from './__generated__/BulkClaimDisplayModal.graphql';

interface Props {
  code: string;
  id: string;
  isOpen: boolean;
  onCancel: () => void;
  onBack: () => void;
  isDisabled?: boolean;
  customer: BulkClaimDisplayGroupsSelect_CustomerFragment;
  customerSite: BulkClaimDisplaySiteSelect_CustomerFragment;
  onSuccess: (data: { status: boolean }) => Promise<void> | void;
}
interface SiteSelectOption {
  label: string;
  address?: string | null;
  value: string;
}
export function BulkClaimModal({
  isOpen,
  onCancel,
  onBack,
  code,
  id,
  customer,
  customerSite,
  onSuccess,
}: Props) {
  const [values, setValues] = useState(() =>
    Array(6)
      .fill('')
      .map((_, i) => code[i] || ''),
  );
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const [expiryDays, setExpiryDays] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [claimCodeName, setClaimCodeName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [aliasPrefix, setAliasPrefix] = useState('');

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const handleGroupChange = (groups: BulkClaimDisplayGroupsSelect_GroupFragment[]) => {
    const ids = groups.map((group) => group.id);
    setSelectedGroups(ids);
  };

  const currentDate = new Date();

  const handleDaysChange = (days: string) => {
    setExpiryDays(days);
    const numDays = parseInt(days, 10);
    if (isNaN(numDays)) {
      setExpiryDays('');
      setExpiryDate('');
      return;
    }
    const futureDate = new Date(currentDate.getTime() + numDays * 24 * 60 * 60 * 1000);
    const dateString = futureDate.toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    setExpiryDate(dateString);
  };

  useEffect(() => {
    setValues(
      Array(6)
        .fill('')
        .map((_, i) => code[i] || ''),
    );
  }, [code]);

  const [MultiClaimUpdateMutation] = useActiveMultiClaimUpdateMutation();

  const handleSubmit = useCallback(async () => {
    try {
      const { data } = await MultiClaimUpdateMutation({
        variables: {
          input: {
            id: id,
            claimCodeName: claimCodeName,
            prefix: aliasPrefix,
            expiry: Number(expiryDays),
            siteId: siteName,
            groupIds: selectedGroups,
          },
        },
      });
      if (!data) {
        throw new Error('Create claim code failed');
      }
      await onSuccess?.({ status: true });
    } catch (err) {
      return;
    }
  }, [
    MultiClaimUpdateMutation,
    aliasPrefix,
    claimCodeName,
    expiryDays,
    id,
    onSuccess,
    selectedGroups,
    siteName,
  ]);

  const [createGroup, createGroupMeta] = useDisplayInlineGrouppsSelect_GroupCreateMutation();

  const isLoading = useMemo(() => createGroupMeta.loading, [createGroupMeta]);

  const [newGroup, setNewGroup] = useState<BulkClaimDisplayGroupsSelect_GroupFragment | null>(null);
  const handleGroupCreatedReset = () => {
    setNewGroup(null);
  };
  const handleCreate = useCallback(
    async (customer: BulkClaimDisplayGroupsSelect_CustomerFragment, name: string) => {
      const { data } = await createGroup({
        variables: {
          input: {
            customerId: customer.id,
            name,
          },
        },
      });
      const newGroup = data?.groupCreate?.group;
      if (newGroup) {
        setNewGroup(newGroup);
      }
    },
    [createGroup],
  );

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return;
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    // Automatically move to the next input
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const formatOptionLabel = useCallback((data: SiteSelectOption) => {
    const label = data.label;
    const address = data.address;

    return (
      <chakra.span>
        {label}
        {address && <chakra.span color="gray.500"> - {address}</chakra.span>}
      </chakra.span>
    );
  }, []);

  const siteOptions = useMemo(
    () =>
      customerSite.sites.map<SiteSelectOption>((site) => ({
        label: site.name,
        address: site.address,
        value: site.id,
      })),
    [customerSite],
  );

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !values[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      onClose={onCancel}
      closeOnOverlayClick={false}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <IconButton
              aria-label="Go Back"
              icon={<ArrowLeftIcon />}
              onClick={onBack}
              variant="ghost"
            />
            <Text>{t('generateBulkClaimCode')}</Text>
          </HStack>
        </ModalHeader>

        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            <Flex align="center" justify="space-between" wrap="wrap" mb={5} gap={4}>
              <HStack spacing={1}>
                {values.map((value, index) => (
                  <Input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    value={value}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={1}
                    textAlign="center"
                    fontSize="5xl"
                    fontWeight="bold"
                    textColor={'#0F5291'}
                    p={1}
                    border="2px solid #9FB3C8"
                    borderRadius="md"
                    width="44px"
                    height="60px"
                    disabled
                    style={{
                      marginRight: index === 2 ? '10px' : '0',
                    }}
                  />
                ))}
              </HStack>

              <FormControl maxW="155px" isRequired>
                <FormLabel fontSize="sm" mb={1}>
                  {t('setExpiry')}
                </FormLabel>
                <Input
                  type="number"
                  size="sm"
                  value={expiryDays}
                  onChange={(e) => handleDaysChange(e.target.value)}
                />
              </FormControl>

              <FormControl maxW="160px">
                <FormLabel fontSize="sm" mb={1}>
                  {t('codeExpiresOn')}
                </FormLabel>
                <Text fontSize="md" color="gray.700" fontWeight="bold">
                  {expiryDate || '- - - '}
                </Text>
              </FormControl>
            </Flex>
            <Text fontSize="sm" color="gray.600">
              {t('claimCodeText1')}
            </Text>
            <Text fontSize="sm" color="orange.400">
              {t('claimCodeText2')}
            </Text>
            <FormControl isRequired>
              <FormLabel>{t('claimCodeName')}</FormLabel>
              <Input value={claimCodeName} onChange={(e) => setClaimCodeName(e.target.value)} />
            </FormControl>
            <FormControl mb="10">
              <FormLabel>{t('site')}</FormLabel>
              <Select
                options={siteOptions}
                components={{
                  ...components,
                }}
                formatOptionLabel={formatOptionLabel}
                placeholder={''}
                isMulti={false}
                escapeClearsValue={true}
                isClearable={true}
                isSearchable={true}
                onChange={(selectedOption) =>
                  setSiteName(selectedOption ? selectedOption.value : '')
                }
                menuPlacement="auto"
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                {t('prefixForAlias')}{' '}
                <Text as="span" fontSize="smaller" color="gray.500" fontWeight="normal" pl={3}>
                  ({t('prefixForAliasPlaceholder')} )
                </Text>
              </FormLabel>
              <Input value={aliasPrefix} onChange={(e) => setAliasPrefix(e.target.value)} />
            </FormControl>

            <FormControl>
              <FormLabel>{t('groups')}</FormLabel>
              <BulkClaimDisplayGroupsSelect
                customer={customer}
                value={customer.groups}
                isLoading={isLoading}
                onCreate={handleCreate}
                onChange={handleGroupChange}
                onGroupCreated={newGroup ? [newGroup] : []}
                resetOnGroupCreated={handleGroupCreatedReset}
              />
            </FormControl>

            <Box justifyContent="flex-end" flex="1" display="flex">
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isDisabled={!claimCodeName || !expiryDays}
                mb={0}
                mt={4}
                variant="solid"
              >
                {t('activateCode')}
              </Button>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

BulkClaimModal.graphql = {
  mutations: {
    BulkClaimModal: gql`
      mutation activeMultiClaimUpdate($input: ActivateMultiClaimCodeInput!) {
        activateMultiClaimCode(input: $input) {
          id
          code
          createdAt
        }
      }
    `,
    CreateGroup: gql`
      mutation DisplayInlineGrouppsSelect_GroupCreate($input: GroupCreateInput!) {
        groupCreate(input: $input) {
          group {
            id
            name
          }
          customer {
            id
            groups {
              id
              name
            }
          }
        }
      }
    `,
  },

  fragments: {
    BulkActionModal_customer: gql`
      fragment BulkClaimDisplayModal_customer on Customer {
        id
        groups {
          id
          name
        }
        sites {
          name
          id
        }
      }
    `,
  },
};
