import { gql } from '@apollo/client';
import { FormLabel, Stack, useToast } from '@chakra-ui/react';
import { cloneDeep, isNil, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toAvailableControlToggleOptions } from '~components/displays/utils';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { ControlLockState } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { DisplayControlsToggle, ToggleOptionsConfig } from '../DisplayControlsToggle';
import {
  DisplayKeyboardControlToggle_DisplayFragment,
  useUpdateKeyboardControlMutation,
} from './__generated__/DisplayKeyboardControlToggle.graphql';

interface Props {
  display: DisplayKeyboardControlToggle_DisplayFragment;
  isDisabled?: boolean;
}
export function DisplayKeyboardControlToggle({ display, isDisabled = false }: Props) {
  const toast = useToast();
  const [updateKeyboardState, { loading }] = useUpdateKeyboardControlMutation();
  const { t } = useTranslation();

  const keyboardControlOptions: Array<ToggleOptionsConfig<ControlLockState>> = [
    { value: ControlLockState.Locked, label: t('lockAllInputs') },
    { value: ControlLockState.VolumeOnly, label: t('allowVolumeChange') },
    { value: ControlLockState.PowerOnly, label: t('allowPowerChange') },
    { value: ControlLockState.Unlocked, label: t('unlockAllInputs') },
  ];
  const handleChange = async (controlState: ControlLockState) => {
    const { id } = display;

    try {
      updateKeyboardState({
        variables: {
          input: {
            displayIds: [id],
            controlState,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayBulkUpdateKeyboardControl: {
            __typename: 'DisplayBulkUpdateKeyboardControlPayload',
            displays: [
              {
                __typename: 'Display',
                id,
                keyboardControl: merge(cloneDeep(display.keyboardControl), {
                  desired: controlState,
                }),
              },
            ],
          },
        },
      });
    } catch (error) {
      toast({
        status: 'error',
        title: t('controlUpdateErr'),
        description: fromError(error, 'UpdateKeyboardControl'),
      });
    }
  };

  const currentControlState = display.keyboardControl?.desired ?? display.keyboardControl?.reported;

  const controlOptions = toAvailableControlToggleOptions(
    keyboardControlOptions,
    display.keyboardControl?.supportedValues,
  );

  const isPending = isPropertyPending(display.keyboardControl);

  if (isNil(currentControlState)) {
    return null;
  }

  return (
    <Stack direction="column" spacing="3">
      <FormLabel>
        {t('lockDisplayButtons')}
        <FormLabelPendingIndicator isPending={isPending} />
      </FormLabel>

      <DisplayControlsToggle
        name="keyboardControlState"
        value={currentControlState}
        handleChange={handleChange}
        isLoading={loading}
        options={controlOptions}
        isDisabled={isDisabled}
      />
    </Stack>
  );
}

DisplayKeyboardControlToggle.graphql = {
  fragments: {
    DisplayKeyboardControlToggle_display: gql`
      fragment DisplayKeyboardControlToggle_display on Display {
        id
        keyboardControl {
          desired
          reported
          supportedValues
        }
      }
    `,
  },
  mutations: {
    UpdateKeyboardControl: gql`
      mutation UpdateKeyboardControl($input: DisplayBulkUpdateKeyboardControlInput!) {
        displayBulkUpdateKeyboardControl(input: $input) {
          displays {
            id
            keyboardControl {
              desired
              reported
              supportedValues
            }
          }
        }
      }
    `,
  },
};
