/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPasswordResetEmailMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestPasswordResetEmailMutation = { __typename: 'Mutation', selfChangePwd: { __typename: 'ChangePasswordStatus', status: boolean } };


export const RequestPasswordResetEmailDocument = gql`
    mutation requestPasswordResetEmail {
  selfChangePwd {
    status
  }
}
    `;
export type RequestPasswordResetEmailMutationFn = ApolloReactCommon.MutationFunction<RequestPasswordResetEmailMutation, RequestPasswordResetEmailMutationVariables>;

/**
 * __useRequestPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetEmailMutation, { data, loading, error }] = useRequestPasswordResetEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestPasswordResetEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetEmailMutation, RequestPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RequestPasswordResetEmailMutation, RequestPasswordResetEmailMutationVariables>(RequestPasswordResetEmailDocument, options);
      }
export type RequestPasswordResetEmailMutationHookResult = ReturnType<typeof useRequestPasswordResetEmailMutation>;
export type RequestPasswordResetEmailMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetEmailMutation>;
export type RequestPasswordResetEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetEmailMutation, RequestPasswordResetEmailMutationVariables>;