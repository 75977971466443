import { gql } from '@apollo/client';
import {
  Button,
  chakra,
  HStack,
  IconProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useRadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty, isNil } from 'lodash';
import {
  Children,
  ComponentType,
  FormEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { BoxedRadio } from '~components/ui/BoxedRadio';
import { PlaylistIcon, WebPageIcon } from '~components/ui/icons';
import { AppsIcon } from '~components/ui/icons/AppsIcon';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';
import {
  ChangeContentSourceModal_BookmarksFragment,
  ChangeContentSourceModal_ContentSource_AppContentSource_Fragment,
  ChangeContentSourceModal_ContentSourceFragment,
  ChangeContentSourceModal_DisplayFragment,
} from './__generated__/ChangeContentSourceModal.graphql';
import { useContentSource } from './useContentSource';
import {
  contentSourceToStringValue,
  isAppContentSource,
  isBookmarkContentSource,
  isBrowserSource,
  isInputContentSource,
  isMediaPlayerSource,
  isPlaylistContentSource,
} from './utils';

interface Props {
  display: ChangeContentSourceModal_DisplayFragment;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}

interface DisplaySourceSelectOptions {
  displayContent: SelectOption[];
  otherSources: SelectOption[];
}

export function ChangeContentSourceModal({ display, isOpen, onCancel, onSuccess }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ChangeContentSourceModalContent
          display={display}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

const ContentSourceLabel = ({
  children,
  isNowPlaying,
}: {
  children: ReactNode;
  isNowPlaying?: boolean;
}) => {
  const childrenArray = Children.toArray(children);
  return (
    <HStack justifyContent="space-between">
      {childrenArray.length > 0 && (
        <chakra.span fontWeight="bold" mr={2}>
          {childrenArray[0]}
        </chakra.span>
      )}
      <chakra.span color="gray.500" fontSize="xs" whiteSpace="nowrap" flex="1">
        {childrenArray.slice(1)}
      </chakra.span>
      {isNowPlaying && (
        <chakra.span color="gray.500" fontSize="sm" whiteSpace="nowrap">
          {t('nowPlaying')}
        </chakra.span>
      )}
    </HStack>
  );
};

type SelectOption = {
  value: string;
  source: string;
  bookmarkIndex?: number;
  label: string;
  selectedActivity?: string;
  icon?: ComponentType<IconProps>;
  isNowPlaying?: boolean;
  contentSourceType?: string;
};

type SelectOptionBookmark = SelectOption & {
  bookmarkIndex: number;
};

const isFirstTab = (source?: string) =>
  source ? isBrowserSource(source) || isMediaPlayerSource(source) : true;

function ChangeContentSourceModalContent({
  display,
  onCancel,
  onSuccess,
}: {
  display: ChangeContentSourceModal_DisplayFragment;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
}) {
  const analytics = useAnalyticsReporter();
  const toast = useToast();
  const { isLoadingContentSource: isLoading, bulkUpdateContentSource } = useContentSource();

  const {
    currentContentSource,
    currentBookmarkIndex,
    isBookmarksSupported,
    isPlaylistsSupports,
    isAppsSupported,
  } = useMemo((): {
    currentContentSource: ChangeContentSourceModal_ContentSourceFragment | undefined;
    currentBookmarkIndex: number | null | undefined;
    isBookmarksSupported: boolean;
    isPlaylistsSupports: boolean;
    isAppsSupported: boolean;
  } => {
    function isContentSourceSupported(
      display: ChangeContentSourceModal_DisplayFragment,
      contentSourceEval: (contentSource: ChangeContentSourceModal_ContentSourceFragment) => boolean,
    ) {
      if (
        !isNil(display.contentSource) &&
        !isNil(display.contentSource?.available) &&
        display.contentSource?.available.length > 0
      ) {
        return display.contentSource.available.some(
          (contentSource: ChangeContentSourceModal_ContentSourceFragment) =>
            contentSourceEval(contentSource),
        );
      }

      return false;
    }

    let currentBookmarkIndex = null;

    if (
      !isNil(display.contentSource) &&
      !isNil(display.contentSource?.current) &&
      !isNil(display.contentSource?.current?.desired) &&
      isBookmarkContentSource(display.contentSource?.current?.desired)
    ) {
      currentBookmarkIndex = display.contentSource.current.desired.index;
    } else if (
      !isNil(display.contentSource?.current?.reported) &&
      isBookmarkContentSource(display.contentSource?.current?.reported)
    ) {
      currentBookmarkIndex = display.contentSource?.current?.reported.index;
    }

    return {
      currentBookmarkIndex: currentBookmarkIndex,
      currentContentSource:
        display.contentSource?.current?.desired ?? display.contentSource?.current?.reported,
      isBookmarksSupported: isContentSourceSupported(display, isBookmarkContentSource),
      isPlaylistsSupports: isContentSourceSupported(display, isPlaylistContentSource),
      isAppsSupported: isContentSourceSupported(display, isAppContentSource),
    };
  }, [display]);

  const selectOptions = useMemo<{
    displayContent: SelectOption[];
    otherSources: SelectOption[];
  }>(() => {
    function buildContentSourceSelectOptions(
      display: ChangeContentSourceModal_DisplayFragment,
      currentContentSource: ChangeContentSourceModal_ContentSourceFragment | undefined,
    ): DisplaySourceSelectOptions {
      const selectOptions: DisplaySourceSelectOptions = {
        displayContent: [],
        otherSources: [],
      };

      const availableContentSources = display?.contentSource?.available;

      if (isNil(display?.contentSource) || isNil(availableContentSources)) {
        return selectOptions;
      }

      const playlistSources: SelectOption[] = [];
      const bookmarkSources: SelectOptionBookmark[] = [];
      const appSources: SelectOption[] = [];
      const bookmarks = getBookmarksFromDisplay(display.bookmarks);

      for (const availableContentSource of availableContentSources) {
        if (isPlaylistContentSource(availableContentSource)) {
          if (
            !isNil(display.playlist) &&
            !isNil(display.playlist?.current) &&
            !isNil(display.playlist?.current?.title)
          ) {
            playlistSources.push({
              value: contentSourceToStringValue(availableContentSource),
              source: availableContentSource.__typename,
              label: display.playlist?.current.title,
              icon: PlaylistIcon,
              isNowPlaying: isPlaylistContentSource(currentContentSource),
            });
          }
        }

        if (isBookmarkContentSource(availableContentSource)) {
          const bookmark = bookmarks.at(availableContentSource.index);

          if (isDefined(bookmark) && !isEmpty(bookmark)) {
            bookmarkSources.push({
              value: contentSourceToStringValue(availableContentSource),
              source: availableContentSource.__typename,
              bookmarkIndex: availableContentSource.index,
              label: bookmark,
              icon: WebPageIcon,
              isNowPlaying:
                isBookmarkContentSource(currentContentSource) &&
                currentContentSource.index === availableContentSource.index,
            });
          }
        }

        if (isAppContentSource(availableContentSource)) {
          if (
            Array.isArray(availableContentSource?.activityList) &&
            availableContentSource?.activityList.length > 0
          ) {
            for (const activity of availableContentSource.activityList) {
              appSources.push({
                value: contentSourceToStringValue(availableContentSource) + '|' + activity,
                source: availableContentSource.__typename,
                label: availableContentSource.label ?? availableContentSource.applicationId,
                selectedActivity: activity,
                icon: AppsIcon,
                isNowPlaying:
                  isAppContentSource(currentContentSource) &&
                  currentContentSource.applicationId === availableContentSource.applicationId &&
                  activity == currentContentSource.selectedActivity,
              });
            }
          } else {
            appSources.push({
              value: contentSourceToStringValue(availableContentSource),
              source: availableContentSource.__typename,
              label: availableContentSource.label ?? availableContentSource.applicationId,
              icon: AppsIcon,
              isNowPlaying:
                isAppContentSource(currentContentSource) &&
                currentContentSource.applicationId === availableContentSource.applicationId,
            });
          }
        }

        if (isInputContentSource(availableContentSource)) {
          if (!isFirstTab(availableContentSource.source)) {
            selectOptions.otherSources.push({
              value: contentSourceToStringValue(availableContentSource),
              source: availableContentSource.__typename,
              label: availableContentSource.source,
              isNowPlaying:
                isInputContentSource(currentContentSource) &&
                currentContentSource.source === availableContentSource.source,
            });
          }
        }
      }

      selectOptions.displayContent = [
        ...playlistSources,
        ...bookmarkSources.sort((a, b) => a.bookmarkIndex - b.bookmarkIndex),
        ...appSources.sort((a, b) => (a.label < b.label ? -1 : 1)),
      ];

      return selectOptions;
    }

    return buildContentSourceSelectOptions(display, currentContentSource);
  }, [currentContentSource, display]);

  function getBookmarksFromDisplay(
    bookmarks: ChangeContentSourceModal_BookmarksFragment,
  ): string[] {
    const desired = isDefined(bookmarks.all.desired)
      ? bookmarks.all.desired.filter((bookmark): bookmark is string => typeof bookmark === 'string')
      : [];

    if (desired.length > 0) {
      return desired;
    }

    const reported = bookmarks.all.reported ?? [];

    if (reported.length > 0) {
      return reported;
    }

    return [];
  }

  type InputSourceContentSourceValue = {
    contentSource: { type: string; value: string; activity?: string } | undefined;
    bookmarkIndex: number | null | undefined;
  };

  const defaultContentSource = useMemo(() => {
    if (!isNil(currentContentSource)) {
      const defaultValue = {
        type: currentContentSource.__typename,
        value: '',
      };

      if (isAppContentSource(currentContentSource)) {
        defaultValue.value = currentContentSource.selectedActivity
          ? currentContentSource.applicationId + '|' + currentContentSource.selectedActivity
          : currentContentSource.applicationId;
      }

      if (isBookmarkContentSource(currentContentSource)) {
        defaultValue.value = currentContentSource.index.toString();
      }

      if (isPlaylistContentSource(currentContentSource)) {
        defaultValue.value = currentContentSource.playlistId;
      }

      if (isInputContentSource(currentContentSource)) {
        defaultValue.value = currentContentSource.source;
      }

      return defaultValue;
    }

    return undefined;
  }, [currentContentSource]);

  const [value, setValue] = useState<InputSourceContentSourceValue>({
    contentSource: defaultContentSource,
    bookmarkIndex: currentBookmarkIndex,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'contentSource',
    defaultValue:
      isDefined(currentContentSource) && !isEmpty(currentContentSource)
        ? contentSourceToStringValue(currentContentSource)
        : undefined,
    onChange: (value) => {
      const [, bookmarkIndexOrSource, contentSource, activity] = value.split('|');
      setValue({
        contentSource: {
          type: bookmarkIndexOrSource,
          value: contentSource,
          activity: activity,
        },
        bookmarkIndex: undefined,
      });
    },
  });

  const group = getRootProps();

  const updateContentSource = useCallback(
    async (
      display: ChangeContentSourceModal_DisplayFragment,
      contentSource: { type: string; value: string; activity?: string },
    ) => {
      if (contentSource.type === 'AppContentSource') {
        let appLabel = contentSource.value;
        const activity = contentSource.activity;
        if (!isNil(display.contentSource?.available)) {
          const availableApp:
            | ChangeContentSourceModal_ContentSource_AppContentSource_Fragment
            | undefined = display.contentSource?.available.find(
            (
              availableContentSource: ChangeContentSourceModal_ContentSourceFragment,
            ): availableContentSource is ChangeContentSourceModal_ContentSource_AppContentSource_Fragment =>
              isAppContentSource(availableContentSource) &&
              availableContentSource.applicationId === contentSource.value,
          );

          if (!isNil(availableApp) && !isNil(availableApp?.label)) {
            appLabel = availableApp.label;
          }
        }

        await bulkUpdateContentSource(
          [display],
          {
            __typename: 'AppContentSource',
            applicationId: contentSource.value,
            label: appLabel,
          },
          activity,
        );
        return;
      }

      if (contentSource.type === 'BookmarkContentSource') {
        await bulkUpdateContentSource([display], {
          __typename: 'BookmarkContentSource',
          index: parseInt(contentSource.value, 10),
        });
        return;
      }

      if (contentSource.type === 'PlaylistContentSource') {
        await bulkUpdateContentSource([display], {
          __typename: 'PlaylistContentSource',
          playlistId: contentSource.value,
        });
        return;
      }

      if (contentSource.type === 'InputContentSource') {
        await bulkUpdateContentSource([display], {
          __typename: 'InputContentSource',
          source: contentSource.value,
        });

        return;
      }

      throw new Error('Unsupported input source');
    },
    [bulkUpdateContentSource],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isNil(value.contentSource)) {
        return;
      }

      try {
        await updateContentSource(display, value.contentSource);

        analytics.track('displaySettingUpdate', { group: 'playback', changeItem: 'contentSource' });

        await onSuccess();
        toast({
          status: 'success',
          title: t('inputSourceChanged'),
          description: t('inputSourceChangedDesc'),
        });
      } catch (err) {
        toast({
          status: 'error',
          title: t('inputSourceChangeErr'),
          description: fromError(err, 'UpdateContentSource'),
        });
      }
    },
    [analytics, display, onSuccess, toast, updateContentSource, value],
  );

  return (
    <>
      <ModalHeader>{t('inputSourceChange')}</ModalHeader>
      <ModalCloseButton tabIndex={5} />
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>{t('displayContent')}</Tab>
              <Tab>{t('otherSources')}</Tab>
            </TabList>
            <TabPanels>
              {Object.entries(selectOptions).map(([key, options]) => (
                <TabPanel key={key} paddingX="0">
                  {key === 'displayContent' && !options.length && (
                    <>
                      {!isBookmarksSupported && !isPlaylistsSupports && !isAppsSupported ? (
                        <>{t('displayNotSupportMedia')}.</>
                      ) : (
                        <>{t('addContentToDisplay')}</>
                      )}
                    </>
                  )}
                  <VStack {...group} spacing="2" alignItems="stretch">
                    {options.map((option) => {
                      const radio = getRadioProps({ value: option.value });
                      return (
                        <BoxedRadio key={option.value} icon={option.icon} {...radio}>
                          <ContentSourceLabel isNowPlaying={option.isNowPlaying}>
                            {option.label.length > 34
                              ? `${option.label.slice(0, 34)}...`
                              : option.label}
                            {option.selectedActivity}
                          </ContentSourceLabel>
                        </BoxedRadio>
                      );
                    })}
                  </VStack>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} variant="ghost" colorScheme="blue" isLoading={isLoading}>
            {t('cancel')}
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            marginLeft="3"
            type="submit"
            isLoading={isLoading}
          >
            {t('apply')}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}

ChangeContentSourceModal.graphql = {
  fragments: {
    ChangeContentSourceModal_contentSource: gql`
      fragment ChangeContentSourceModal_contentSource on ContentSource {
        ... on AppContentSource {
          label
          applicationId
          selectedActivity
        }
        ... on BookmarkContentSource {
          index
        }
        ... on InputContentSource {
          source
        }
        ... on PlaylistContentSource {
          playlistId
        }
      }
    `,
    ChangeContentSourceModal_bookmarks: gql`
      fragment ChangeContentSourceModal_bookmarks on Bookmarks {
        all {
          reported
          desired
        }
      }
    `,
    ChangeContentSourceModal_display: gql`
      fragment ChangeContentSourceModal_display on Display {
        id
        contentSource {
          available {
            ... on AppContentSource {
              label
              applicationId
              selectedActivity
            }
            ... on BookmarkContentSource {
              index
            }
            ... on InputContentSource {
              source
            }
            ... on PlaylistContentSource {
              playlistId
            }
          }
          current {
            reported {
              ... on AppContentSource {
                label
                applicationId
                selectedActivity
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
            desired {
              ... on AppContentSource {
                label
                applicationId
                selectedActivity
              }
              ... on BookmarkContentSource {
                index
              }
              ... on InputContentSource {
                source
              }
              ... on PlaylistContentSource {
                playlistId
              }
            }
          }
        }
        bookmarks {
          ...ChangeContentSourceModal_bookmarks
        }
        ...UsePlaylists_display
        playlist {
          current {
            id
            title
          }
        }
      }
    `,
  },
};
