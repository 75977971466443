import { gql } from '@apollo/client';
import {
  Button,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  BulkManageApps_OrganizationAppSubscriptionFragment,
  useBulkInstallAppsMutation,
} from '~components/displays/BulkAction/__generated__/BulkManageApps.graphql';
import BackToAllActionsButton from '~components/displays/BulkAction/BackToAllActionsButton';
import { ContentModalAppEntry } from '~components/displays/ContentModalAppEntry';
import { ContentModalSubHeading } from '~components/displays/ContentModalSubHeading';
import { useAppSubscriptions } from '~components/displays/useAppSubscriptions';
import { InfoAlert } from '~components/ui/Alert';
import { ModalCloseButton } from '~components/ui/ModalCloseButton';
import { fromError } from '~utils/errors';
import { isDefined } from '~utils/types';
import { BulkActionComponent } from './BulkActionModal';

export type OrganizationAppSubscription = BulkManageApps_OrganizationAppSubscriptionFragment;

const schema = z.object({
  displayIds: z.array(z.string()),
  appSubscriptions: z.record(
    z.string(),
    z.object({ subscriptionId: z.string(), version: z.string() }),
  ),
});

type FormValues = z.TypeOf<typeof schema>;

export const BulkManageApps: BulkActionComponent = ({
  displays,
  organization,
  onCancel,
  onBack,
  onSuccess,
}) => {
  const toast = useToast();
  const { getSortedOrganizationAppSubscriptions } = useAppSubscriptions();
  const [bulkInstallApps] = useBulkInstallAppsMutation();
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      displayIds: displays.map((display) => display.id),
      appSubscriptions: {},
    },
    resolver: zodResolver(schema),
  });

  const appSubscriptionsMap = watch('appSubscriptions');

  const { availableAppSubscriptions, unavailableAppSubscriptions } =
    getSortedOrganizationAppSubscriptions(organization, displays);

  const availableAppSubscriptionIds = availableAppSubscriptions
    .map(({ id }) => id)
    .filter((id) => isNil(appSubscriptionsMap[id]));
  const unavailableAppSubscriptionIds = unavailableAppSubscriptions.map(({ id }) => id);

  const addAppSubscription = (subscriptionId: string, version: string) => {
    appSubscriptionsMap[subscriptionId] = { subscriptionId, version };
    setValue('appSubscriptions', appSubscriptionsMap);
  };

  const removeAppSubscription = (subscriptionId: string) => {
    delete appSubscriptionsMap[subscriptionId];
    setValue('appSubscriptions', appSubscriptionsMap);
  };

  const handleAppInstallations = async ({ displayIds, appSubscriptions }: FormValues) => {
    for (const { subscriptionId, version } of Object.values(appSubscriptions)) {
      const appSubscription = organization.appSubscriptions.find(({ id }) => id === subscriptionId);
      const formattedName = appSubscription !== undefined ? `"${appSubscription.name}"` : 'the app';

      try {
        const { data } = await bulkInstallApps({
          variables: {
            input: {
              appVersion: version,
              subscriptionId,
              displayIds,
            },
          },
        });

        if (isDefined(data)) {
          if (data.displayBulkRequestAppInstallation.displays.length === 0) {
            toast({
              status: 'warning',
              title: t('bulkInstallSkipAllWarn', { name: formattedName }),
              description: t('bulkInstallIncapableWarn'),
            });
          } else if (data.displayBulkRequestAppInstallation.displays.length < displayIds.length) {
            toast({
              status: 'warning',
              title: t('bulkInstallSkipWarn', { name: formattedName }),
              description: t('bulkInstallError'),
            });
          }
        }
      } catch (error) {
        toast({
          status: 'error',
          title: t('installFailedErr', { name: formattedName }),
          description: fromError(error, 'bulkInstallAppMutation', {
            displayIds: displayIds.join(', '),
            subscriptionId,
          }),
        });
      }
    }

    await onSuccess();
  };

  const hasAppSubscriptionsToInstall = Object.keys(appSubscriptionsMap).length > 0;
  const hasAvailableAppSubscriptions = availableAppSubscriptionIds.length > 0;
  const hasUnavailableAppSubscriptions = unavailableAppSubscriptionIds.length > 0;

  const canApplyChanges = hasAppSubscriptionsToInstall;

  // Keep the display ids in sync with the props
  useEffect(() => {
    setValue(
      'displayIds',
      displays.map((display) => display.id),
    );
  }, [displays, setValue]);

  return (
    <>
      <ModalContent>
        <ModalHeader>{t('manageApplications')}</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <form onSubmit={handleSubmit(handleAppInstallations)}>
          <ModalBody>
            <Stack spacing="4">
              <InfoAlert>{t('displayMaySkipAlert')}</InfoAlert>
              <VStack spacing="3" alignItems="flex-start">
                {hasAppSubscriptionsToInstall && (
                  <>
                    <ContentModalSubHeading>{t('toInstall')}</ContentModalSubHeading>
                    {organization.appSubscriptions.map((appSubscription) =>
                      isDefined(appSubscriptionsMap[appSubscription.id]) ? (
                        <ContentModalAppEntry
                          key={appSubscription.id}
                          subscription={appSubscription}
                          version={appSubscriptionsMap[appSubscription.id]?.version ?? ''}
                          onDelete={() => removeAppSubscription(appSubscription.id)}
                        />
                      ) : null,
                    )}
                  </>
                )}
                {hasAvailableAppSubscriptions && (
                  <>
                    <ContentModalSubHeading>{t('available')}</ContentModalSubHeading>
                    {availableAppSubscriptions.map((appSubscription) =>
                      isNil(appSubscriptionsMap[appSubscription.id]) ? (
                        <ContentModalAppEntry
                          key={appSubscription.id}
                          subscription={appSubscription}
                          onInstall={(version: string) =>
                            addAppSubscription(appSubscription.id, version)
                          }
                        />
                      ) : null,
                    )}
                  </>
                )}
                {hasUnavailableAppSubscriptions && (
                  <>
                    <ContentModalSubHeading>{t('unavailable')}</ContentModalSubHeading>
                    {unavailableAppSubscriptions.map((appSubscription) => (
                      <ContentModalAppEntry
                        key={appSubscription.id}
                        subscription={appSubscription}
                        disabled={true}
                        unavailabilityReason={appSubscription.reason}
                      />
                    ))}
                  </>
                )}
              </VStack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack flex="1" justifyContent="space-between">
              <BackToAllActionsButton onBack={onBack} />
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={onCancel}
                isDisabled={isSubmitting}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="solid"
                colorScheme="blue"
                marginLeft="3"
                type="submit"
                isDisabled={isSubmitting || !canApplyChanges}
                isLoading={isSubmitting}
              >
                {t('apply')}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </>
  );
};

BulkManageApps.graphql = {
  fragments: {
    BulkManageApps_organizationAppSubscription: gql`
      fragment BulkManageApps_organizationAppSubscription on OrganizationAppSubscription {
        id
        iconUrl
        name
        usage {
          current
          max
        }
        appVersions
      }
    `,
    BulkManageApps_organization: gql`
      fragment BulkManageApps_organization on Organization {
        appSubscriptions {
          ...BulkManageApps_organizationAppSubscription
        }
      }
    `,
    BulkManageApps_display: gql`
      fragment BulkManageApps_display on Display {
        appSubscriptions {
          id
          appInstallation {
            id
          }
        }
      }
    `,
  },
  mutation: {
    BulkInstallApps: gql`
      mutation BulkInstallApps($input: DisplayBulkRequestAppInstallationInput!) {
        displayBulkRequestAppInstallation(input: $input) {
          displays {
            appSubscriptions {
              ...DisplaySubscriptions
            }
          }
        }
      }
    `,
  },
};
