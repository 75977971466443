import { useTranslation } from 'react-i18next';
import { PolicyPage } from '~components/ui/PolicyPage';

// See PolicyPage.tsx for content generation instructions.
export function PrivacyPolicyPage() {
  const { t } = useTranslation();
  return (
    <PolicyPage title={t('privacy_policy')} heading={t('wave_privacy')}>
      <div dangerouslySetInnerHTML={{ __html: t('privacyPolicy') }} />
    </PolicyPage>
  );
}
