import { gql } from '@apollo/client';
import { Box, Tag, Tooltip } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { DisplayTable_DisplayFragment } from '../__generated__/DisplayTable.graphql';
import { GroupsCell_ValueFragment } from './__generated__/GroupsCell.graphql';

export function GroupsCell({
  value,
}: PropsWithChildren<CellProps<DisplayTable_DisplayFragment, GroupsCell_ValueFragment[]>>) {
  const tooltip = value.map((group) => group.name).join(', ');

  return (
    <Tooltip label={tooltip}>
      <Box
        position="relative"
        display="flex"
        flexWrap="nowrap"
        overflow="hidden"
        style={{
          WebkitMaskImage: 'linear-gradient(to right, black calc(100% - 8px), transparent)',
          WebkitMaskSize: '100%',
          WebkitMaskPosition: 'top left',
          WebkitMaskRepeat: 'no-repeat',
        }}
      >
        {value.length === 0 && (
          <Tag size="xs" variant="solid" colorScheme="gray" visibility="hidden">
            {t('noGroups')}
          </Tag>
        )}
        {value.map((group) => (
          <Box key={group.id} marginRight="2">
            <Tag size="xs" variant="outline" colorScheme="blue" background="white">
              {group.name}
            </Tag>
          </Box>
        ))}
      </Box>
    </Tooltip>
  );
}

GroupsCell.graphql = {
  fragments: {
    GroupsCell_value: gql`
      fragment GroupsCell_value on Group {
        id
        name
      }
    `,
    GroupsCell_display: gql`
      fragment GroupsCell_display on Display {
        id
        groups {
          id
          ...GroupsCell_value
        }
      }
    `,
  },
};
