import { gql } from '@apollo/client';
import { FormControl, FormLabel, GridItem, useToast } from '@chakra-ui/react';
import { Permission } from '@tp-vision/roles-permissions';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntervalWhen } from 'rooks';
import { useAuth } from '~auth/useAuth';
import CopyButton from '~components/ui/CopyButton';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { DisplayTimeZoneSelect, TimeZone } from '~components/ui/TimeZoneSelect';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import {
  DisplayTimeSection_DisplayFragment,
  useDisplayBulkUpdateTimeZoneMutation,
} from './__generated__/DisplayTimeSection.graphql';

interface Props {
  display: DisplayTimeSection_DisplayFragment;
}

export function DisplayTimeSection({ display }: Props) {
  const timeZone: TimeZone | undefined = display.timeZone?.desired ?? display.timeZone?.reported;
  const timeZoneValues = display.timeZone?.supportedValues;
  const toast = useToast();
  const [updateTimeZone] = useDisplayBulkUpdateTimeZoneMutation();
  const [date, setDate] = useState<string>('');
  const analytics = useAnalyticsReporter();
  const { verifyUserPermissions } = useAuth();
  const { t } = useTranslation();

  const isTimezoneUpdateDisabled = !verifyUserPermissions([Permission.DisplayTimeZoneUpdate]);

  const handleChange = useCallback(
    async (timeZone: string) => {
      try {
        await updateTimeZone({
          variables: {
            input: {
              displayIds: [display.id],
              timeZone,
            },
          },
        });

        analytics.track('displaySettingUpdate', { group: 'management', changeItem: 'timeZone' });
      } catch (err) {
        toast({
          status: 'error',
          title: t('displayTimeZoneupdateErr'),
          description: fromError(err, 'UpdateDisplayTimeZone'),
        });
      }
    },
    [analytics, display.id, toast, updateTimeZone, t],
  );

  const tick = useCallback(() => {
    if (!timeZone || timeZone === 'DISABLED') {
      setDate('');
    }

    const now = DateTime.now().setZone(timeZone);
    setDate(now.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS));
  }, [timeZone]);
  useEffect(tick, [tick, display]);
  useIntervalWhen(tick, 1000, true, true);

  return (
    <GridItem
      colSpan={{
        base: 8,
        sm: 6,
        lg: 8,
        xl: 5,
      }}
    >
      {!timeZone ? (
        t('timeZoneInfoUnavailable')
      ) : (
        <FormControl mb="10">
          <FormLabel>
            {t('timeZone')}
            <FormLabelPendingIndicator isPending={isPropertyPending(display.timeZone)} />
          </FormLabel>
          <DisplayTimeZoneSelect
            onChange={handleChange}
            value={timeZone}
            supportedValues={timeZoneValues}
            isDisabled={isTimezoneUpdateDisabled}
          />
        </FormControl>
      )}
      {!timeZone || timeZone === 'DISABLED' ? null : (
        <FormControl>
          <FormLabel>{t('localTime')}</FormLabel>
          <span
            style={{
              // Monospace
              fontVariantNumeric: 'tabular-nums',
              letterSpacing: '-.5px',
            }}
          >
            {date}
          </span>
          <CopyButton value={date} label={t('localTime')} ml="4" />
        </FormControl>
      )}
    </GridItem>
  );
}

DisplayTimeSection.graphql = {
  fragments: {
    DisplayTimeSection_display: gql`
      fragment DisplayTimeSection_display on Display {
        id
        firmware {
          android {
            version
          }
        }
        timeZone {
          reported
          desired
          supportedValues
        }
      }
    `,
  },
  mutations: {
    UpdateTimeZone: gql`
      mutation displayBulkUpdateTimeZone($input: DisplayBulkUpdateTimeZoneInput!) {
        displayBulkUpdateTimeZone(input: $input) {
          displays {
            id
            timeZone {
              reported
              desired
            }
          }
        }
      }
    `,
  },
};
