import { useApolloClient } from '@apollo/client';
import { Button, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Page } from './Page';
import { PageContent } from './PageContent';

interface Props {
  resetError: () => void;
}

export function AppError({ resetError }: Props) {
  const apollo = useApolloClient();

  useEffect(() => {
    apollo.clearStore();
  }, [apollo]);

  return (
    <Page title="Oops…" pageName="error" analyticsDisableTracking={true}>
      <PageContent maxWidth="300px" textAlign="center">
        <Text fontWeight="semibold" color="gray.600">
          {t('defaultErrMsg1')}
        </Text>
        <Text marginTop="8" fontSize="sm">
          <Button variant="link" size="sm" colorScheme="blue" onClick={() => resetError()}>
            {t('tryAgain')}
          </Button>
        </Text>
      </PageContent>
    </Page>
  );
}
