import { gql } from '@apollo/client';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Permission, Role } from '@tp-vision/roles-permissions';
import { t } from 'i18next';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { z } from 'zod';
import { Shield } from '~auth/Shield';
import { useAuth } from '~auth/useAuth';
import { UserCustomersTable } from '~components/organization/UserCustomersTable';
import { UserCustomersTable_CustomerFragment as CustomerFragment } from '~components/organization/UserCustomersTable/__generated__/UserCustomersTable.graphql';
import {
  useUserCustomersTable,
  useUserLiteCustomersTable,
} from '~components/organization/UserCustomersTable/useUserCustomersTable';
import { userEditValidationSchema } from '~components/organization/utils';
import { components } from '~components/ui/Select';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import useGoBack from '~components/useGoBack';
import { useAnalyticsReporter } from '~utils/analytics';
import { fromError } from '~utils/errors';
import { isWaveSubscription } from '~utils/subscriptions';
import { isDefined } from '~utils/types';
import {
  useEditUserMutation,
  UserEditPage_UserFragment as UserFragment,
  useUserEditPageOrganizationQuery,
} from './__generated__/[id].graphql';
import { getRoleOptions, getRoleOptionsForRoles } from './constants';

const schema = z.object({
  id: z.string().uuid(),
  roles: z.array(z.object({ label: z.string(), value: z.nativeEnum(Role) })),
  customerIds: z.array(z.string()),
  ...userEditValidationSchema,
});

type FormValues = z.infer<typeof schema>;

export function OrganizationUserEditPage() {
  const { userId } = useParams();
  const toast = useToast();
  const analytics = useAnalyticsReporter();
  const handleGoBack = useGoBack();
  const { verifyUserPermissions } = useAuth();
  const [updateUser] = useEditUserMutation();

  const { data, loading, error, refetch } = useUserEditPageOrganizationQuery();

  const user = data?.organization.users.find((user) => user.id === userId);
  const customers = data?.customersByOrganization.customers;

  const updateForm = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      customerIds: user?.customerIds ?? [],
    },
  });

  const { isSubmitting, isDirty, isValid } = updateForm.formState;
  const canSubmit = !isSubmitting && isValid && isDirty;

  const handleUpdateUser = async ({
    id,
    roles: roleOptions,
    givenName,
    familyName,
    customerIds,
  }: FormValues) => {
    try {
      const roles = roleOptions.map(({ value }) => value as Role);

      const { data: updateData } = await updateUser({
        variables: {
          input: {
            userId: id,
            roles,
            givenName,
            familyName,
            ...(verifyUserPermissions([Permission.FeatureCustomerScopedAccess]) && { customerIds }),
          },
        },
      });

      if (!isDefined(updateData) || !isDefined(updateData.userUpdate.user.id)) {
        throw new Error('Update user failed');
      }

      if (
        user?.roles.length === roles.length &&
        user.roles.every((role) => roles.includes(role)) === false
      ) {
        analytics.track('userRoleChanged');
      }

      analytics.track('userUpdateComplete');

      toast({
        status: 'success',
        title: t('userSettingsUpdated'),
      });

      await refetch();
    } catch (error) {
      toast({
        status: 'error',
        title: t('updateUserErr'),
        description: fromError(error, 'UpdateUser', {
          NOT_ALLOWED_TO_ASSIGN_ROLE: t('updateUserErrDesc'),
          MUST_HAVE_ADMIN: t('updateUserErrDesc1'),
          ROLES_EMPTY: t('updateUserErrDesc2'),
          UNAUTHORIZED: t('updateUserErrDesc3'),
          NOT_ALLOWED_ROLE_REMOVAL: t('updateUserErrDesc4'),
        }),
      });
      updateForm.reset();
    }
  };

  // This page loads data asynchronously, so we need to "reset" the form when the data is available
  // to fill out the default values
  useEffect(() => {
    if (loading === false) {
      updateForm.reset({
        id: user?.id,
        givenName: user?.givenName,
        familyName: user?.familyName,
        roles: getRoleOptionsForRoles(user?.roles.filter((role) => role !== Role.Mfa) ?? []),
        customerIds: user?.customerIds ?? [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once, when the page is loaded
  }, [loading]);

  useEffect(() => {
    analytics.track('userUpdateStart');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run on initial mount
  }, []);

  return (
    <VerticalTabContent
      title={t('editUser')}
      error={error}
      isLoading={loading}
      data={user}
      onGoBack={handleGoBack}
      hasStickyHeader={true}
      action={{
        label: t('applyChanges'),
        disabled: !canSubmit,
        isLoading: isSubmitting,
        onClick: updateForm.handleSubmit(handleUpdateUser),
      }}
    >
      {(user) => <EditUserForm user={user} customers={customers ?? []} form={updateForm} />}
    </VerticalTabContent>
  );
}

type EditUserFormProps = {
  user: UserFragment;
  customers: CustomerFragment[];
  form: UseFormReturn<FormValues>;
};

export function EditUserForm({ user, form, customers }: EditUserFormProps) {
  const { user: authUser, organization } = useAuth();

  const userCustomerIds = useMemo(() => user.customerIds ?? [], [user.customerIds]);
  const essentialCustomers = useMemo(
    () => customers.filter((customer) => isWaveSubscription(customer.waveSubscription)),
    [customers],
  );
  const liteCustomers = useMemo(
    () => customers.filter((customer) => !isWaveSubscription(customer.waveSubscription)),
    [customers],
  );
  const essentialCustomersId = useMemo(
    () =>
      userCustomerIds.filter((customer) =>
        essentialCustomers.find((essential) => essential.id == customer),
      ),
    [essentialCustomers, userCustomerIds],
  );
  const liteCustomersId = useMemo(
    () => userCustomerIds.filter((customer) => liteCustomers.find((lite) => lite.id == customer)),
    [userCustomerIds, liteCustomers],
  );

  const table1 = useUserCustomersTable(essentialCustomers, essentialCustomersId);
  const table2 = useUserLiteCustomersTable(liteCustomers, liteCustomersId);
  const roleOptions = getRoleOptions(organization, authUser);

  const {
    register,
    control,
    formState: { errors, isSubmitSuccessful },
    setValue,
    reset,
  } = form;

  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  // When user selection changes, update the form data
  useEffect(() => {
    const checkedCustomerIds = [
      ...new Set(Object.keys(table1.state.selectedRowIds)),
      ...new Set(Object.keys(table2.state.selectedRowIds)),
    ].sort();
    const sortedUserCustomerIds = [...userCustomerIds].sort();

    const hasNumberOfCustomersChanged = checkedCustomerIds.length !== sortedUserCustomerIds.length;
    const isEveryUserCustomersStillChecked = sortedUserCustomerIds.every((id) =>
      checkedCustomerIds.includes(id),
    );

    const selectionChangeUpdatedData =
      hasNumberOfCustomersChanged || !isEveryUserCustomersStillChecked;

    setValue('customerIds', [...new Set(checkedCustomerIds)], {
      shouldTouch: true,
      shouldDirty: selectionChangeUpdatedData,
    });
  }, [setValue, userCustomerIds, table1.state.selectedRowIds, table2.state.selectedRowIds]);

  // Reset the form after as successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      // Un-dirtying needed to disable the submit button again
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- `table` changes are of no interest
  }, [isSubmitSuccessful, reset]);

  return (
    <form>
      <VStack alignItems="flex-start" spacing={10}>
        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('userDetails')}
          </Heading>
          <FormControl isInvalid={Boolean(errors.givenName)} maxWidth="420" mb="8">
            <FormLabel>{t('firstName')}</FormLabel>
            <Input {...register('givenName', { required: true, minLength: 2 })} />
            <FormErrorMessage>{errors.givenName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.familyName)} maxWidth="420" mb="8">
            <FormLabel>{t('lastName')}</FormLabel>
            <Input {...register('familyName', { required: true, minLength: 2 })} />
            <FormErrorMessage>{errors.familyName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl maxWidth="420">
            <FormLabel>{t('Email')}</FormLabel>
            <Input
              readOnly={true}
              ref={(r) => {
                initialFocusRef.current = r;
              }}
              placeholder={t('emailPlaceholder')}
              value={user?.email}
            />
          </FormControl>
        </FormControl>

        <FormControl>
          <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
            {t('permissions')}
          </Heading>
          <FormControl isInvalid={Boolean(errors.roles)}>
            <FormLabel>{t('roles')}</FormLabel>
            <Controller
              control={control}
              name="roles"
              render={({ field }) => {
                return (
                  <Select
                    components={components}
                    options={roleOptions}
                    isMulti={true}
                    required={true}
                    isClearable={false}
                    menuPlacement="auto"
                    backspaceRemovesValue={false}
                    {...field}
                  />
                );
              }}
            />
            <FormErrorMessage>{errors.roles?.message}</FormErrorMessage>
          </FormControl>
        </FormControl>

        <Shield requiredPermissions={[Permission.FeatureCustomerScopedAccess]}>
          <FormControl>
            <Heading borderBottom="1px solid" borderColor="gray.50" fontSize="1.5rem" mb="8" pb="2">
              {t('customers')}
            </Heading>
            {isNil(customers) || isEmpty(customers) ? (
              <>{t('noCustomers')}</>
            ) : (
              <UserCustomersTable table1={table1} table2={table2} />
            )}
          </FormControl>
        </Shield>
      </VStack>
    </form>
  );
}

OrganizationUserEditPage.graphql = {
  fragments: {
    UserEditPage_user: gql`
      fragment UserEditPage_user on User {
        id
        email
        roles
        givenName
        familyName
        customerIds
      }
    `,
  },

  queries: {
    UserEditPageOrganization: gql`
      query UserEditPageOrganization {
        customersByOrganization {
          customers {
            ...UserCustomersTable_customer
          }
        }
        organization {
          id
          users {
            ...UserEditPage_user
          }
        }
      }
    `,
  },
  mutations: {
    EditUser: gql`
      mutation EditUser($input: UserUpdateInput!) {
        userUpdate(input: $input) {
          user {
            id
            ...UserEditPage_user
          }
        }
      }
    `,
  },
};
