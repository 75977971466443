import { gql } from '@apollo/client';
import { Box, Button, Stack, Text, useToast } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { VerticalTabContent } from '~components/ui/VerticalTabs';
import { useRequestPasswordResetEmailMutation } from './__generated__/PrivacyAndSecuritySettings.graphql';

export function PrivacyAndSecuritySettings() {
  const toast = useToast();

  const [requestPasswordResetEmail, { loading }] = useRequestPasswordResetEmailMutation();

  const handleRequestPasswordChange = async () => {
    try {
      const { data } = await requestPasswordResetEmail();
      if (isNil(data)) throw new Error('no data received');
      if (data.selfChangePwd.status == false) throw new Error('could not send reset email');
      toast({
        status: 'success',
        title: 'Password reset',
        description: 'Password reset email sent successfully.',
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Password reset',
        description: 'Could not send password reset email. Please try again later.',
      });
    }
  };

  return (
    <VerticalTabContent title="Privacy & Security">
      <Stack direction="row" spacing="20" mb="6">
        <Box flex="1">
          <Text fontSize="md" color="gray.500" maxWidth="700">
            To change your PPDS Wave password, click <b>{`"Change Password".`}</b> A reset link with
            instructions will be emailed to you. Please check both your inbox and Junk/Spam folder
            of your email. Your old password remains active if unchanged. The reset link is valid
            for 5 days.
          </Text>
        </Box>
        <Box>
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={handleRequestPasswordChange}
            isDisabled={loading}
          >
            Change Password
          </Button>
        </Box>
      </Stack>
    </VerticalTabContent>
  );
}

PrivacyAndSecuritySettings.graphql = {
  mutations: {
    requestChangePassword: gql`
      mutation requestPasswordResetEmail {
        selfChangePwd {
          status
        }
      }
    `,
  },
};
