import { gql } from '@apollo/client';
import { FormLabel, Stack, useToast } from '@chakra-ui/react';
import { cloneDeep, isNil, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toAvailableControlToggleOptions } from '~components/displays/utils';
import {
  FormLabelPendingIndicator,
  isPropertyPending,
} from '~components/ui/FormLabelPendingIndicator';
import { ControlLockState } from '~graphql/__generated__/types';
import { fromError } from '~utils/errors';
import { DisplayControlsToggle, ToggleOptionsConfig } from '../DisplayControlsToggle';
import {
  DisplayInfraRedControlToggle_DisplayFragment,
  useUpdateInfraRedControlMutation,
} from './__generated__/DisplayInfraRedControlToggle.graphql';

interface Props {
  display: DisplayInfraRedControlToggle_DisplayFragment;
  isDisabled?: boolean;
}

export function DisplayInfraRedControlToggle({ display, isDisabled = false }: Props) {
  const toast = useToast();
  const [updateInfraRedState, { loading }] = useUpdateInfraRedControlMutation();
  const { t } = useTranslation();
  const handleChange = async (controlState: ControlLockState) => {
    const { id, infraRedControl } = display;

    try {
      updateInfraRedState({
        variables: {
          input: {
            displayIds: [id],
            controlState,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          displayBulkUpdateInfraRedControl: {
            __typename: 'DisplayBulkUpdateInfraRedControlPayload',
            displays: [
              {
                __typename: 'Display',
                id,
                infraRedControl: merge(cloneDeep(infraRedControl), {
                  desired: controlState,
                }),
              },
            ],
          },
        },
      });
    } catch (error) {
      toast({
        status: 'error',
        title: t('controlUpdateErr'),
        description: fromError(error, 'UpdateInfraRedControl'),
      });
    }
  };

  const infraRedControlToggleOptions: Array<ToggleOptionsConfig<ControlLockState>> = [
    { value: ControlLockState.Locked, label: t('lockAllInputs') },
    { value: ControlLockState.VolumeOnly, label: t('allowVolumeChange') },
    { value: ControlLockState.PowerOnly, label: t('allowPowerChange') },
    { value: ControlLockState.Unlocked, label: t('unlockAllInputs') },
  ];
  const currentControlState = display.infraRedControl?.desired ?? display.infraRedControl?.reported;

  const controlOptions = toAvailableControlToggleOptions(
    infraRedControlToggleOptions,
    display.infraRedControl?.supportedValues,
  );

  const isPending = isPropertyPending(display.infraRedControl);

  if (isNil(currentControlState)) {
    return null;
  }

  return (
    <Stack direction="column" spacing="3">
      <FormLabel>
        {t('lockRemoteControl')}
        <FormLabelPendingIndicator isPending={isPending} />
      </FormLabel>

      <DisplayControlsToggle
        name="infraRedControlState"
        value={currentControlState}
        handleChange={handleChange}
        isLoading={loading}
        options={controlOptions}
        isDisabled={isDisabled}
      />
    </Stack>
  );
}

DisplayInfraRedControlToggle.graphql = {
  fragments: {
    DisplayInfraRedControlToggle_display: gql`
      fragment DisplayInfraRedControlToggle_display on Display {
        id
        infraRedControl {
          desired
          reported
          supportedValues
        }
      }
    `,
  },
  mutations: {
    UpdateInfraRedControl: gql`
      mutation UpdateInfraRedControl($input: DisplayBulkUpdateInfraRedControlInput!) {
        displayBulkUpdateInfraRedControl(input: $input) {
          displays {
            id
            infraRedControl {
              desired
              reported
              supportedValues
            }
          }
        }
      }
    `,
  },
};
